import ModalButton from "components/Modals/ModalButton";
import { MdOutlineCameraAlt } from "react-icons/md";
import { useModal } from "Hooks/useModal";
import { useOpenModal } from "Hooks/useOpenModal";
import { useRef, useState } from "react";
import { useVerifyUser } from "Hooks/profile/useVerifyUser";
import NewEditProfileHeader from "components/PollsComp/ProfilleComp/NewEditProfileHeader";
import Spinner from "components/Spinner";
import useProfileModal from "Hooks/profile/useProfileModal";
import VerifyConfirmationButton from "components/Modals/VerifyConfirmationButton";

const NewRequestVerification = () => {
  const [legalName, setLegalName] = useState("");
  const [work, setWork] = useState("");
  const [verifyImage, setVerifyImage] = useState("");
  const [linkOne, setLinkOne] = useState("");
  const [linkTwo, setLinkTwo] = useState("");
  const [linkThree, setLinkThree] = useState("");
  const [tempFile, setTempFile] = useState("");

  const { verifyStatus, verifying, isVerifying } = useVerifyUser();

  const { isSuccessModal} =
    useProfileModal();

  const fileRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setVerifyImage(file);
    setTempFile(file.name);
  };

  const handleManageVerification = (e) => {
    e.preventDefault();

    const formData = {
      legal_name: legalName,
      work: work,
      link1: linkOne,
      link2: linkTwo,
      link3: linkThree,
      id_image: verifyImage,
    };

    verifying(formData);
  };

  return (
    <>
      <NewEditProfileHeader header="Request verification" />
      <form
        className="request_verification"
        onSubmit={handleManageVerification}
      >
        <div className="request_verification_top">
          <h2 className="mt-4">Account Verification</h2>
          <p>
            To verify your account, certain procedures are required and that
            will determine if we’ll verify your account or not
          </p>
        </div>

        <div className="request_verification_middle">
          <input
            type="text"
            placeholder="Legal name"
            onChange={(e) => setLegalName(e.target.value)}
            className="outline-none"
            required
          />
          <input
            type="text"
            placeholder="Work or Profession"
            required
            onChange={(e) => setWork(e.target.value)}
            className="outline-none"
          />
        </div>

        <div className="request_verification_bottom">
          <p>
            Please paste Three(3) Links where your name has been mentioned and
            your picture(s) shown clearly. These contexts must be of a good
            reputation and contribution to the Community.
          </p>

          <div className="">
            <input
              type="url"
              placeholder="Link 1"
              required
              onChange={(e) => setLinkOne(e.target.value)}
              className="outline-none text-[14px]"
            />
          </div>

          <div className="">
            <input
              type="url"
              placeholder="Link 2"
              onChange={(e) => setLinkTwo(e.target.value)}
              className="outline-none text-[14px]"
              required
            />
          </div>

          <div className="">
            <input
              type="url"
              placeholder="Link 3"
              onChange={(e) => setLinkThree(e.target.value)}
              className="outline-none text-[14px]"
              required
            />
          </div>

          <div className="select_file">
            <p>
              Upload a clear front picture of any Government issued ID : Show
              details clearly.
            </p>

            <input
              type="file"
              id="verifyImage"
              accept="image/*"
              className="hidden"
              ref={fileRef}
              onChange={handleImageChange}
              required
            />

            <label htmlFor="verifyImage">
              {!tempFile && <MdOutlineCameraAlt className="camera" />}
              {tempFile.slice(0, 8) || "Select file"}
            </label>
          </div>

          <div className="request_verification_bottom_button">
            <ModalButton className="clickModalOpen">
              {verifyStatus === "pending" ? <Spinner /> : "Send request"}
            </ModalButton>
          </div>
        </div>

      </form>
        {isSuccessModal && <VerifyConfirmationButton
          title="Request sent"
          sub="Profile Verification takes up to week or more at times."
          btnText="Done"
        />}
    </>
  );
};

export default NewRequestVerification;
