import CenterFeed from './CenterFeed'
import RightMenu from './RightMenu'

const Education = () => {
  return (
    <div>
      <div className='grid grid-cols-1  lg:gap-6 lg:grid-cols-[8fr_4fr] mx-auto '>
        <div className='h-full'>
          <div className='w-full h-full grid'>
            <CenterFeed />
          </div>
        </div>

        <div className='w-full'>
          <RightMenu />
        </div>
      </div>
    </div>
  )
}

export default Education
