import React, { useEffect, useRef, useState } from "react";
import TvRightSide from "./TvRightSide";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FaArrowLeftLong } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";
import MobileTvOptions from "./MobileTvOptions";
import DesktopTvOptions from "./DesktopTvOptions";
import { useTv } from "Hooks/tv/useTv";
import { useTvChannels } from "Hooks/tv/useTvChannels";
import Spin from "components/Spin/Spin";
import ContainerError from "components/ContainerError";
import { useGetUserMovies } from "Hooks/tv/useGetUserMovies";
import TvCardFull from "./TvCardFull";
import UserMoviesCardFull from "./UserMoviesCardFull";
import useSubscribe from "Hooks/tv/useSubscribe";
import useUnsubscribe from "Hooks/tv/useUnsubscribe";
import { useGetSubscribers } from "Hooks/tv/useGetSubscribers";
import Spinner from "components/Spinner";
import { useProfile } from "Hooks/profile/useProfile";

const isIdPresent = (resultsArray, id) => {
  if (!Array.isArray(resultsArray)) {
    return false;
  }

  return resultsArray.some((result) => result.username === id);
};

const MovieUserAccount = () => {
  const [desktopTvOptions, setDesktopTvOptions] = useState(false);
  const [mobileTvOptions, setMobileTvOptions] = useState(false);
  const { user } = useParams();
  const navigate = useNavigate();

  const { userTvData, userTvDataError, userTvDataStatus, isUserTvDataLoading } =
    useTv();

  const { profileData, isProfileDataLoading } = useProfile();

  const { channelData, isChannelDataLoading, channelDataError } =
    useTvChannels(user);

  const { userMovies, isUserMoviesLoading, userMoviesError } =
    useGetUserMovies(user);

  const { subscribe, subscribeStatus, subscribeError } = useSubscribe(user);
  const { unsubscribe, unsubscribeError, unsubscribeStatus } =
    useUnsubscribe(user);
  const { subscribers, isSubscribersLoading, subscribersError } =
    useGetSubscribers(user);

  console.log("subscribers", subscribers?.data?.results);

  const subscribersTotal = subscribers?.data?.results?.length;
  const allSubscribers = subscribers?.data?.results;

  // eslint-disable-next-line eqeqeq
  const isUser = userTvData?.data?.data[0]?.id == user;

  const tvData = channelData?.data?.data;
  const myID = profileData?.data?.data?.user?.username;

  console.log("my id", myID);

  const isSubscribed = isIdPresent(allSubscribers, myID);

  console.log("isSubscribed", isSubscribed);

  const handleDesktopTvOptions = () => {
    setDesktopTvOptions(true);
  };

  const handleMobileTvOptions = () => {
    setMobileTvOptions(true);
  };

  const handleSubscribe = () => {
    subscribe({ channel: user });
  };

  const handleUnsubscribe = () => {
    unsubscribe({ channel: user });
  };

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  if (channelDataError) {
    return <ContainerError />;
  }

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 h-full py-2 px-4 relative">
        {isChannelDataLoading || isUserTvDataLoading ? (
          <Spin />
        ) : (
          <>
            {desktopTvOptions && (
              <DesktopTvOptions setDesktopTvOptions={setDesktopTvOptions} />
            )}
            {mobileTvOptions && (
              <MobileTvOptions
                mobileTvOptions={mobileTvOptions}
                setMobileTvOptions={setMobileTvOptions}
              />
            )}
            <div className="p-2 mt-4">
              {/*  Header*/}

              <div className="flex items-center gap-4 justify-between">
                <div className="flex gap-4 items-center">
                  <FaArrowLeftLong
                    className="text-[18px] cursor-pointer"
                    onClick={() => navigate(-1)}
                  />
                  <span className="text-[17px] font-normal">
                    {isUser ? "Your Channel" : "Public channel"}
                  </span>
                </div>
                {isUser && (
                  <div className="">
                    <TbDotsVertical
                      className="text-[20px] hidden lg:block"
                      onClick={() => handleDesktopTvOptions()}
                    />
                    <TbDotsVertical
                      className="text-[20px] lg:hidden"
                      onClick={() => handleMobileTvOptions()}
                    />
                  </div>
                )}
              </div>
              {/* circle */}
              {tvData?.profile_photo ? (
                <div className="flex justify-center flex-col items-center my-12 gap-4">
                  <div className="h-[100px] w-[100px] rounded-full flex justify-center items-center">
                    <img
                      src={tvData?.profile_photo}
                      alt=""
                      className="h-full w-full rounded-full object-cover"
                    />
                  </div>
                  <div className="text-[18px]">{tvData?.name}</div>
                </div>
              ) : (
                <div className="flex justify-center flex-col items-center my-12 gap-4">
                  <div className="h-[100px] w-[100px] rounded-full bg-[#4f0da3] flex justify-center items-center">
                    <div className="text-white text-[28px]">
                      {tvData?.name[0]?.toUpperCase()}
                    </div>
                  </div>
                  <div className="text-[18px]">{tvData?.name}</div>
                </div>
              )}
              {/* card */}
              <div className="mt-4 p-12 bg-neutral-200">
                <div className="flex justify-around text-[16px] text-neutral-800 items-center">
                  <div className="flex flex-col gap-2 items-center">
                    <div className="text-[26px]">{subscribersTotal}</div>
                    <div className=" text-[16px]">Subscribers</div>
                  </div>

                  <div
                    className="flex flex-col gap-2 items-center cursor-pointer"
                    onClick={() => navigate(`/tv/${tvData?.id}/movies`)}
                  >
                    <div className="text-[26px] ">{tvData?.videos}</div>
                    <div className=" text-[16px]">Videos</div>
                  </div>
                </div>
              </div>
              {/* upload */}
              {isUser ? (
                <div className="flex flex-col items-center gap-2 mt-12">
                  <div className="text-[17px]">Upload Movie</div>
                  <div className="text-[14px] text-center">
                    Enhance your content by uploading movies now. Engage,
                    diversify, and attract a wider audience for greater success
                  </div>
                  <div className="w-[50%]">
                    <button
                      className="text-white bg-[#4f0da3] text-[15px] p-4 mt-4 w-full rounded"
                      onClick={() => navigate("/tv/upload")}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {!isSubscribed && (
                    <div className="w-[50%] mx-auto mt-4">
                      <button
                        className="text-white bg-[#4f0da3] text-[15px] p-4 mt-4 w-full rounded"
                        onClick={handleSubscribe}
                      >
                        {subscribeStatus === "pending" ? (
                          <Spinner />
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </div>
                  )}
                  {isSubscribed && (
                    <div className="w-[50%] mx-auto mt-4">
                      <button
                        className="text-white bg-[#4f0da3] text-[15px] p-4 mt-4 w-full rounded"
                        onClick={handleUnsubscribe}
                      >
                        {unsubscribeStatus === "pending" ? (
                          <Spinner />
                        ) : (
                          "Unsubscribe"
                        )}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

        <div className="ticket-full-box mt-4">
          {userMovies?.data?.results?.map((item, index) => (
            <UserMoviesCardFull data={item} key={index} />
          ))}
        </div>

        {isChannelDataLoading || isUserTvDataLoading ? null : (
          <Link
            to={`/tv/${tvData?.id}/movies`}
            className="text-[16px] ml-2 mt-4 no-underline"
          >
            View all channel videos
          </Link>
        )}
      </div>

      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2 ">
        <TvRightSide user />
      </div>
    </div>
  );
};

export default MovieUserAccount;
