import React, { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FaArrowLeftLong } from "react-icons/fa6";
import toast from "react-hot-toast";
import { CreateHotelApi } from "api/services/hotels";

const animatedComponents = makeAnimated();

const AddListing = ({ onClose, fetchHotels }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState([]);
  const [listing, setListing] = useState({
    name: "",
    location: "",
    type: "",
    rooms: "",
    description: "",
    image: null,
    features: []
  });

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleInputChange = (name, value) => {
    if (name === 'image') {
      setListing((prevState) => ({ ...prevState, [name]: value.target.files[0] }));
    } else {
      setListing((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleTagChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map(option => ({ name: option.value }));
    setTags(selectedOptions);
    setListing((prevState) => ({
      ...prevState,
      features: formattedOptions
    }));
  };

  const handleAddListing = async (e) => {
    e.preventDefault();
    const missingFields = [];
    if (!listing.name) missingFields.push("name");
    if (!listing.location) missingFields.push("location");
    if (!listing.type) missingFields.push("type");
    if (!listing.rooms) missingFields.push("rooms");
    if (!listing.description) missingFields.push("description");

    if (missingFields.length > 0) {
      toast.error(`Missing required fields: ${missingFields.join(", ")}`);
      return;
    }

    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('name', listing.name);
      formData.append('location', listing.location);
      formData.append('type', listing.type);
      formData.append('rooms', listing.rooms);
      formData.append('description', listing.description);
      formData.append('image', listing.image);
      listing.features.forEach((feature, index) => {
        formData.append(`features[${index}][name]`, feature.name);
      });

      const resp = await CreateHotelApi(formData);
      if (resp.data.status) {
        toast.success("Listing created successfully");
        fetchHotels();
        onClose();
      }
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newOption = { value: inputValue, label: inputValue };
        setTags([...tags, newOption]);
        setListing((prevState) => ({
          ...prevState,
          features: [...prevState.features, { name: inputValue }]
        }));
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <form className="form-wrapper" onSubmit={handleAddListing}>
      <div className="createTop" style={{ display: "flex", alignItems: "center", gap: "40px", paddingBottom: "20px" }}>
        <FaArrowLeftLong style={{ fontSize: "20px" }} onClick={() => onClose(false)} className="cursor-pointer" />
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>List Apartment</span>
      </div>
      <div className="form-field">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          className="outline-none p-[9px]"
          value={listing.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </div>
      <div className="form-field">
        <input
          type="text"
          id="location"
          name="location"
          placeholder="Location"
          className="outline-none p-[9px]"
          value={listing.location}
          onChange={(e) => handleInputChange("location", e.target.value)}
        />
      </div>
      <div className="form-field">
        <select name="type" id="type" value={listing.type} onChange={(e) => handleInputChange("type", e.target.value)}>
          <option value="">Apartment type</option>
          <option value="Apartment1">Apartment 1</option>
          <option value="Apartment2">Apartment 2</option>
        </select>
      </div>
      <div className="form-field">
        <input
          type="text"
          id="rooms"
          name="rooms"
          placeholder="Number of rooms"
          className="outline-none p-[9px]"
          value={listing.rooms}
          onChange={(e) => handleInputChange("rooms", e.target.value)}
        />
      </div>
      <div className="form-field">
        <input
          type="text"
          id="description"
          name="description"
          placeholder="Description"
          className="outline-none p-[9px]"
          value={listing.description}
          onChange={(e) => handleInputChange("description", e.target.value)}
        />
      </div>
      <div className="form-field">
        <input
          type="file"
          id="image"
          name="image"
          placeholder="image"
          className="outline-none p-[9px]"
          onChange={(e) => handleInputChange("image", e)}
        />
      </div>
      <p><b>Facilities</b></p>
      <Select
        components={animatedComponents}
        isMulti
        value={tags}
        onChange={handleTagChange}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        placeholder="Type and press enter to add a feature"
        isClearable={true}
        closeMenuOnSelect={false}
      />

      <button className="create-poll-btn outline-none" type="submit" disabled={isLoading}>
        {isLoading ? "Loading..." : "Publish this item"}
      </button>
    </form>
  );
};

export default AddListing;
