import React, { useState, useContext, useEffect } from "react";
import "../Vote/CreatePoll/CreatePoll.css";
import toast from "react-hot-toast";
import { CreateHotelApi, getHotelPromotionPlans, PromoteHotelApi } from "api/services/hotels";
import { FaTimes } from "react-icons/fa";
import image from "../../../assets/connect illustration.svg"

const PromoteHotel = ({ onClose, fetchHotels, id}) => {

    const [isLoading, setIsLoading] = useState(false);

    const hotelPromotionPlans = [
        { id: 1, name: "Basic", users: 1000, price: 1000 },
        { id: 2, name: "Standard", users: 5000, price: 5000 },
        { id: 3, name: "Premium", users: 10000, price: 10000 },
        { id: 4, name: "Pro", users: 50000, price: 50000 }
    ]

    const [selectedPlan, setSelectedPlan] = useState(null);

    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);

        console.log(plan)
    };


    useEffect(() => {
        const data = getHotelPromotionPlans()
        console.log(data)
    }, [])

    const handlePromoteHotel = async (e) => {
        e.preventDefault();

        if (!selectedPlan) {
            toast.error(`Select a plan`);
            return;
        }


        const data = {
            callback_url: `http://localhost:3000/hotels/${id}?setPromote=true`,
            plan_id: selectedPlan.id
        }

        try {
            setIsLoading(true);

            const resp = await PromoteHotelApi(data);
            if (resp.data.status) {
                toast.success("Hotel promoted successfully");
                fetchHotels();
                onClose();
            }
        } catch (error) {
            toast.error(error.response.data.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="form-wrapper">
            <div
                className="createTop"
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "40px",
                    justifyContent: "flex-end"
                }}
            >
                <div className="hotel-close-btn"><FaTimes onClick={onClose} /></div>
            </div>

            <center>
                <img src={image} alt="Image" /><br /><br />

                <h2>Promote your hotels!</h2>

                <p className="max-w-[400px]">Promote your poll, attract a wider audience, and boost hotel visibility effortlessly.</p>
            </center><br />


            <div className="promote-hotel">

                <p><b>Select a plan</b></p>

                {hotelPromotionPlans.map((plan, index) => (
                    <div key={index} onClick={() => handlePlanClick(plan)} style={{ cursor: 'pointer' }}>
                        <div>
                            <div>
                                {plan.name}
                                {plan.users}
                            </div>

                            <div>
                                {plan.price}
                                <input
                                    type="radio"
                                    name="plan"
                                    checked={true}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>



            <button
                className="create-poll-btn outline-none w-full"
                disabled={isLoading} onClick={handlePromoteHotel}
            >
                {isLoading ? "Loading..." : "Proceed to checkout"}
            </button>

        </div>
    );
};

export default PromoteHotel;
