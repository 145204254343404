import BackNav from "../../Layout/NonAuthNav/BackNav";

const PersonalDetails = () => {
  return (
    <div className="personal-detail-container">
      <BackNav />
    </div>
  );
};

export default PersonalDetails;
