import React, { useState } from "react";
import CardImage from "../Assets/Image5.jpeg";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";
export default function TrendingCard({ category, img, artist, id, no, onClick, setModalOpen2, modalOpen2, setActiveModal }) {
  const authToken = localStorage.getItem("authToken")
  const [plays, setPlays] = useState([])

  const GetPlays = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlays(res?.data?.data);
        console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e));
  };
  return (
    <div
    onClick={()=>{onClick(); setModalOpen2(!modalOpen2); GetPlays(); setActiveModal('player1')}}
      //   style={{ minWidth: 263.38, maxWidth: 263.38 }}
      className="min-w-[223.38px] max-w-[223.38px]">
      <div className="w-full relative max-h-[211.16px] min-h-[211.16px]">
        <img
          src={img ? img : CardImage}
          width={"100%"}
          style={{ objectFit: "cover", 
            // width: 235.16 
            }}
          className="rounded-md max-h-[211.16px] min-h-[211.16px] content-center"
        />
        <img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <div className="flex flex-col justify-start items-start sm:justify-center sm:items-center sm:align-middle">
        <span
          className="font-normal text-lg sm:text-2xl"
          //   style={{ fontSize: "25.08px", lineHeight: "28.82px" }}
        >
          {category ? category : "UNKNOWN SONG"}
        </span><br/>
        <span
          className="font-normal text-sm sm:text-lg text-[#403F3F]"
          //   style={{ fontSize: "18.81px", lineHeight: "21.62px" }}
        >
          {artist ? artist : "UNKNOWN ARTIST"}
        </span>
      </div>
    </div>
  );
}
