import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BiLike } from "react-icons/bi";
import CircularProgress from "@mui/material/CircularProgress";
import { useCreateReaction, useRemoveReaction } from "api/hooks/feeds";
import {
  EmojiLaughing,
  EmojiSad,
  EmojiAngry,
  EmojiSurprised,
  ThumbsUp,
  ThumbsDown,
} from "assets/custom-icons";
import { EmojiHug } from "assets/custom-icons/Emojihug";

const Likepost = ({ postId, userReactions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [reactionIcon, setReactionIcon] = useState(null);
  const { reaction, isLoading } = useCreateReaction({ postId });
  const { removeReaction, isRemoving } = useRemoveReaction({ postId });

  
  useEffect(() => {
    if (userReactions?.length > 0) {
      setReactionIcon(userReactions[0].reaction_type); 
    }
  }, [userReactions]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReact = (reactionId) => {
    if (reactionIcon === reactionId) {
      if (removeReaction && removeReaction.delete) {
        removeReaction.delete({ reaction_type: reactionId });
        setReactionIcon(null); 
      }
    } else {
      reaction({ reaction_type: reactionId });
      setReactionIcon(reactionId);
    }
    handleClose();
  };

  const getReactionIcon = (reactionId) => {
    switch (reactionId) {
      case 1:
        return <ThumbsUp />;
      case 2:
        return <ThumbsDown />;
      case 3:
        return <EmojiHug />;
      case 4:
        return <EmojiSad />;
      case 5:
        return <EmojiAngry />;
      case 6:
        return <EmojiLaughing />;
      case 7:
        return <EmojiSurprised />;
      default:
        return <BiLike size={24} />;
    }
  };

  const loadingStat = isLoading || isRemoving;

  return (
    <div className="share-post-container">
      <Button
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          background: "transparent",
          minWidth: "fit-content",
          padding: 0,
          color: "#000000b9",
        }}
        disabled={loadingStat}
      >
        {loadingStat ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          getReactionIcon(reactionIcon) 
        )}
      </Button>
      <Menu
        id="share-menu"
        aria-labelledby="share-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: { marginTop: -4 },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="like-post-dropdown"
      >
        <MenuItem onClick={() => handleReact(1)}>
          <ThumbsUp size={20} />
        </MenuItem>
        <MenuItem onClick={() => handleReact(2)}>
          <ThumbsDown />
        </MenuItem>
        <MenuItem onClick={() => handleReact(3)}>
          <EmojiHug />
        </MenuItem>
        <MenuItem onClick={() => handleReact(4)}>
          <EmojiSad />
        </MenuItem>
        <MenuItem onClick={() => handleReact(5)}>
          <EmojiAngry />
        </MenuItem>
        <MenuItem onClick={() => handleReact(6)}>
          <EmojiLaughing />
        </MenuItem>
        <MenuItem onClick={() => handleReact(7)}>
          <EmojiSurprised />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Likepost;
