import { useState } from "react";

const useLocalstorage = () => {
  const [parsedfiled, setParsedField] = useState({});
  const [formval, setFormval] = useState([]);

  // Set an object form details to localStorage
  const saveForm = () => {
    const formsaves = localStorage.getItem("buyeraddress");
    let newFormVal = [parsedfiled];

    if (formsaves) {
      const prev = JSON.parse(formsaves);
      if (Array.isArray(prev) && prev.length > 0) {
        if (Object.keys(parsedfiled).length > 0) {
          newFormVal = [parsedfiled, ...prev];
          localStorage.setItem("buyeraddress", JSON.stringify(newFormVal));
          setParsedField({});
        }
      }
    } else {
      if (Object.keys(parsedfiled).length > 0) {
        localStorage.setItem("buyeraddress", JSON.stringify(newFormVal));
        setFormval(newFormVal);
        console.log("passed");
        setParsedField({});
      } else {
        console.log("Notpassed");
      }
    }
  };

  // updateform

  const updateForm = () => {
    const formsaves = localStorage.getItem("buyeraddress");
    const prev = JSON.parse(formsaves);
    let newFormVal = [];

    if (
      Array.isArray(prev) &&
      prev.length > 0 &&
      Object.keys(parsedfiled).length > 0
    ) {
      const newupdated = () => {
        const newarr = prev.map((dist) =>
          dist.id === parsedfiled.id ? { ...dist, ...parsedfiled } : dist
        );

        return newarr;
      };
      newFormVal = newupdated();
      localStorage.setItem("buyeraddress", JSON.stringify(newFormVal));
      setParsedField({});
    }
  };

  // merge-as-selected-to-first-list
  const fixedtoFirst = () => {
    const formsaves = localStorage.getItem("buyeraddress");
    const prev = JSON.parse(formsaves);
    let newFormVal = [];

    if (
      Array.isArray(prev) &&
      prev.length > 0 &&
      Object.keys(parsedfiled).length > 0
    ) {
      const newupdated = () => {
        const updatedItem = prev.find((dist) => dist.id === parsedfiled.id);
        if (updatedItem) {
          const filteredArr = prev.filter((dist) => dist.id !== parsedfiled.id);

          return [{ ...updatedItem, ...parsedfiled }, ...filteredArr];
        }
        return prev;
      };

      newFormVal = newupdated();
      localStorage.setItem("buyeraddress", JSON.stringify(newFormVal));
      setParsedField({});
    }
  };

  // get-all-contents-in-local-storage
  const getFormlist = localStorage?.getItem("buyeraddress");
  return {
    formval,
    setFormval,
    saveForm,
    setParsedField,
    getFormlist,
    updateForm,
    fixedtoFirst,
  };
};

export default useLocalstorage;
