import React, { useContext, useEffect, useState } from "react";
import Search from "../Assets/Vector.svg";
import LibraryCard from "../Components/LibraryCard";
import Close from "../Assets/Vector (8).png";
import { MdCancel, MdOutlineCancel } from "react-icons/md";
import { HiOutlineXMark } from "react-icons/hi2";
import AlbumCard from "../Components/AlbumCard";
import ArtistTabCard from "../Components/ArtistTabCard";
// import Modal from "../Components/Modals/ModalWrapper1"
import ArtistProfile from "../Pages/ArtistProfile";
import Modal from "../Components/Modals/ModalWrapper1";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import MusicPlayer2 from "../Components/MusicPlayer2";
import {Modal as Modal2} from "react-responsive-modal"
import toast from "react-hot-toast";
import preloader from "../../../pages/Home/Animation - 1703321875032 (1).json"
import SongListModalCard from "../Components/SongListModalCard";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import AudioPlayer2 from "../AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "../context/MusicPlayerContext";
// import Search from "../Assets/Vector.svg";

export default function RightSider() {
  const [activeTab, setActiveTab] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [albums, setAlbums] = useState([])
  const [artists, setArtists] = useState([])
  const [downloaded, setDownloaded] = useState([])
  const [recentlyPlayed, setRecentlyPlayed] = useState([])
  const [selectedArtistId, setSelectedArtistId] = useState(null)
  const [createPlaylist, setCreatePlaylist] = useState(false)
  const [loading,setLoading] = useState(false)
  const [playlistName, setPlaylistName] = useState()
  const [playlistdescription, setPlaylistdescription] = useState()
  const [playlistImage, setPlaylistImage] = useState()
  const [playlistId, setPlaylistId] = useState()
  const [playlistSongModal,setPlaylistSongModal] = useState(false)
  const [songs, setSongs] = useState([])
  const [songId, setSongId] = useState()
  const [open, setOpen] = useState(false);
  const [playlist, setPlaylist] = useState([])
  // const [songId, setSongId] = useState()
  // const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen1, setModalOpen1] = useState(false)
  const [minimized,setMinimized]=useState(false)
  const [clickedIndex, setClickedIndex] = useState(null);
  const [playlistSongs, setPlaylistSongs] = useState([])
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const handleItemClick = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const closeAllUploadModals = () => {
    setCreatePlaylist(false)
    setPlaylistSongModal(false)
    // setOpen(false)
    // setUpload4(false)
    // setUpload5(false)
    // setUpload6(false)
    // setUpload7(false)
    // setUpload8(false)
    setOpen(false)
  }

  const continueAlbumAudioUpload = () =>{
    setOpen(false)
    // setAlbumSongTitle("")
    // setAlbumSongAudio()
  }


  const onCloseCreatePlaylist = () => {setCreatePlaylist(!createPlaylist)}
  const onClosePlaylistSongModal = () => {setPlaylistSongModal(!playlistSongModal)}

  const handleOpenModal = (artistId) => {setIsOpen(true); setSelectedArtistId(artistId)}
  const handleCloseModal = () => setIsOpen(false);

  const authToken = localStorage.getItem("authToken")

  const GetAlbums = async() => {
    await axios
      .get(`https://api.2geda.net/api/stereo/albums/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setAlbums(res?.data.data);
        console.log(albums + "recent upload state===");
        console.log(JSON.stringify(res.data) + "recentUpload====");
      }).catch(e=>console.log(e));
  };

  const GetArtists = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtists(res.data.data);
        console.log(JSON.stringify(artists) + "artist state===");
        console.log(JSON.stringify(res.data.data) + "artists====");
      }).catch(e=>console.log(e));
  };

  const GetDownloaded = () => {
    axios
      .get(`https://api.2geda.net/api/account/profile/songs/downloads/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setDownloaded(res.data.data);
        console.log(JSON.stringify(downloaded) + "downloaded state===");
        console.log(JSON.stringify(res.data.data) + "downloads====");
      }).catch(e=>console.log(e));
  };

  const GetRecentlyPlayed = () => {
    axios
      .get(`https://api.2geda.net/api/account/profile/songs/recently-played/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setRecentlyPlayed(res.data.data);
        console.log(JSON.stringify(recentlyPlayed) + "recently played state===");
        console.log(JSON.stringify(res.data.data) + "recently played====");
      }).catch(e=>console.log(e));
  };

  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        // Filter the items where plays < 3
        setSongs(res.data.data)
        // const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
        // Assuming setBigHit is a function to update state
        // setBigHit(filteredHits);
        // console.log(filteredHits,"bighits==="); // This will log the filtered items
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const GetPlaylists = async() => {
    await axios
      .get(`https://api.2geda.net/api/stereo/playlist/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlaylist(res?.data.data);
        console.log(playlist + "recent upload state===");
        console.log(JSON.stringify(res.data) + "recentUpload====");
      }).catch(e=>console.log(e));
  };



  const CreatePlaylist = () => {
    setLoading(true)
    console.log(playlistImage + playlistName + playlistdescription)
    const payload = {
      name: playlistName,
      description: playlistdescription,
      cover_image: playlistImage,
      // title:song,
      // artist: artist
    }
    axios.post(`https://api.2geda.net/api/stereo/playlist/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(true)
      if (res.status === 201) {
        toast.success(res.data.message)
        setLoading(false)
        setPlaylistId(res.data?.data?.id)
        setPlaylistSongModal(!playlistSongModal)
        setCreatePlaylist(!createPlaylist)
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
        
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
      console.log(res)
    })
  }

  const handleCheckboxChange = (id) => {
    setSongId(id)
  }
  
  const AddSongToPlaylist = async() => {
    const payload = {
      song_id: songId
    }
    await axios.post(`https://api.2geda.net/api/stereo/playlist/${playlistId}/add-song/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
        // setAlbumId(res.data?.data?.id)
        console.log(res)
        if (res.status === 200) {
          setLoading(false)
          toast.success(res.data.message)
          onOpenModal()
          // setUpload1(false)
          // setUpload2(false)
          // setUpload3(false)
          // setUpload4(false)
          // setUpload5(false)
        } else {
          toast.error("Something went wrong")
          setLoading(false)
        }
      }).catch(err=>{
        console.log(err)
        setLoading(false)
      })
  }

  const ListPlaylistSongs = async(id) => {
    await axios.get(`https://api.2geda.net/api/stereo/playlist/${id}/songs/`,  {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then((res)=>{
      setPlaylistSongs(res?.data?.data)
      setTracks(res?.data?.data)
      setModalOpen(!modalOpen)
      // setModalOpen3(!modalOpen3)
      console.log(res?.data?.data, "playlist songs====")
    }).catch(e => console.log(e))
  }
  



  useEffect(()=>{
    GetAlbums()
    GetArtists()
    GetDownloaded()
    GetRecentlyPlayed()
    GetSongs()
    GetPlaylists()
  },[])
  return (
    <div className="bg-white lg:px-10 xl:px-10 md:px-5 pt-10 w-auto h-full mx-10">
      <div className="flex justify-between items-center lg:mt-10 xl:mt-10">
        <span className="text-2xl font-medium">Your library</span>

        <button onClick={()=>setCreatePlaylist(!createPlaylist)}>
          <span className="text-2xl font-medium">+</span>
        </button>
      </div>
      <div
        className="flex gap-1 justify-between my-5 flex-shrink-0 overflow-x-scroll mx-2"
        style={{ scrollbarWidth: "none" }}>
        {activeTab ? (
          <button
            onClick={() => setActiveTab(null)}
            className="bg-[#4F0DA3] px-3 py-1 rounded-full">
            {/* <p className="text-lg text-white">x</p> */}
            <HiOutlineXMark color="white" size={11.67} />
          </button>
        ) : null}
        <button
          onClick={() => setActiveTab(1)}
          className={`${
            activeTab === 1 ? "bg-[#4F0DA3]" : "bg-[#F5F5F5]"
          } rounded-full px-4 py-2`}>
          <span
            className={`font-normal text-sm ${
              activeTab === 1 ? "text-white" : "text-black"
            }`}>
            Playlists
          </span>
        </button>
        <button
          onClick={() => setActiveTab(2)}
          className={`${
            activeTab === 2 ? "bg-[#4F0DA3]" : "bg-[#F5F5F5]"
          } rounded-full px-4 py-2`}>
          <span
            className={`font-normal text-sm ${
              activeTab === 2 ? "text-white" : "text-black"
            }`}>
            Album
          </span>
        </button>
        <button
          onClick={() => setActiveTab(3)}
          className={`${
            activeTab === 3 ? "bg-[#4F0DA3]" : "bg-[#F5F5F5]"
          } rounded-full px-4 py-2`}>
          <span
            className={`font-normal text-sm ${
              activeTab === 3 ? "text-white" : "text-black"
            }`}>
            Artists
          </span>
        </button>
        <button
          onClick={() => setActiveTab(4)}
          className={`${
            activeTab === 4 ? "bg-[#4F0DA3]" : "bg-[#F5F5F5]"
          } rounded-full px-4 py-2`}>
          <span
            className={`font-normal text-sm ${
              activeTab === 4 ? "text-white" : "text-black"
            }`}>
            Downloaded
          </span>
        </button>
      </div>
      <div className="relative">
        <input
          type="text"
          className="w-full bg-[#F5F5F5] py-2 rounded-3xl pl-10"
        />
        <img src={Search} className="absolute top-2 left-2" />
      </div>

      {activeTab === null && (
        <div className={recentlyPlayed.length>0?"grid grid-cols-1 gap-2 mt-4 justify-center align-middle items-center md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2":null}>
          {/* <LibraryCard
            img={require("../Assets/Frame 1243.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Image2.jpeg")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Image2.jpeg")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Image2.jpeg")}
            title={"Liked songs"}
            no={"85"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          /> */}
           {recentlyPlayed.length>0?recentlyPlayed.map((res, index)=>{
            return <LibraryCard
            onClick={()=>handleItemClick(index, recentlyPlayed)} 
            id = {res.id}
            setModalOpen1={setModalOpen}
            modalOpen1={modalOpen}
            img={res.cover_image}
            title={res.title}
            artist={res.artist.artist_name?res.artist.artist_name:"2GEDA Artist"}
          />
          }):<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        </div>
      )}

      {activeTab === 1 && (
        <div className={playlist.length>0?"grid grid-cols-1 gap-2 mt-4 justify-center align-middle items-center md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2":null}>
          {/* <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          /> */}
          {playlist.length>0?playlist.map((res, index)=>{
            return <LibraryCard
            // id={res.id}
            album={true}
            onClick={()=>ListPlaylistSongs(res.id)}
            modalOpen1={modalOpen1}
            setModalOpen1={setModalOpen1}
            // img={res.cover_image?`https://development.2geda.net${res.cover_image}`:null}
            img={res.cover_image}
            title={res.name}
            artist={res.description? res.description:"2GEDA Description"}
          />
          }).reverse():<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        </div>
      )}

      {activeTab === 2 && (
        <div className={albums.length>0?`grid grid-cols-1 gap-2 mt-4 justify-center align-middle items-center md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2`:null}>
          {/* <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          /> */}
          {albums.length>0?albums.map(album=>{
            return <AlbumCard
            img={album.cover_image}
            title={album.name}
            artist={album.artist.artist_name?album.artist.artist_name:"2GEDA Artist"}
          />
          }):<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        </div>
      )}

      {activeTab === 3 && (
        <div className={artists>0?"grid grid-cols-1 gap-2 mt-4 justify-center align-middle items-center md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3":null}>
          {/* <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} />
          <ArtistTabCard onClick={handleOpenModal} /> */}
          {artists.length>0?artists.map(artist =>{
          return(<ArtistTabCard name={artist.artist_name} image={artist.brand_image} onClick={()=>handleOpenModal(artist.id)} />)
        }):<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        </div>
      )}

      {activeTab === 4 && (
        <div className={downloaded.length>0?"grid grid-cols-1 gap-2 mt-4 justify-center align-middle items-center md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2":null}>
          {/* <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          />
          <LibraryCard
            img={require("../Assets/Component 14.png")}
            title={"Liked songs"}
            no={"85"}
          />
          <AlbumCard
            img={require("../Assets/Image2.jpeg")}
            title={"Are we annoyed?"}
            artist={"Billie Eilish"}
          /> */}
          {downloaded.length>0?downloaded.map(res=>{
            return <LibraryCard
            img={res.cover_image}
            title={res.name}
            artist={res.artist.artist_name?res.artist.artist_name:"2GEDA Artist"}
          />
          }):<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        </div>
      )}
      <div className="mt-5">
      <MusicPlayer2/>
      </div>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ArtistProfile Albums={albums} songs={songs} onClick={handleCloseModal} id={selectedArtistId} />
        </Modal>
      )}

      {/* Playlist Modal Forms */}

      {/* Create Playlist */}
      <Modal2 open={createPlaylist} onClose={onCloseCreatePlaylist} center styles={{zIndex: 3000}}>
      <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content">
    <label>Playlist Cover Image</label>
    <div class="form-group custom-drop-file text-center mb-3">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" onChange={(e)=>setPlaylistImage(e.target.files[0])}/>
      <main className="flex flex-col justify-center items-center">
        {playlistImage?<img src={URL.createObjectURL(playlistImage)}/>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
        <input
        onChange={(e)=>setPlaylistName(e.target.value)}
                  type="text"
                  placeholder="Playlist Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <input
                onChange={(e)=>setPlaylistdescription(e.target.value)}
                  type="text"
                  placeholder="Playlist Description"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                {/* <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="max-w-[351px] px-3 text-black mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select> */}
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ? <Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>CreatePlaylist()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Create Playlist</button>}
        </div>
      </Modal2>

      {/* Add Songs To Playlist */}
      <Modal2 open={playlistSongModal} onClose={onClosePlaylistSongModal} center styles={{zIndex: 3000}}>
        <div className="mx-3">
          <span className="text-sm font-normal text-[#403F3F]">Select songs to add to this playlist</span>
        </div>
        {/* {JSON.stringify(selectedSongs)}
        {selectedSongs.map(res=>{
          return <p>{res?.title}</p>
        })} */}

        <div className="relative mx-3 mt-3 mb-3">
        <input
          type="text"
          className="w-full bg-[#F5F5F5] py-2 rounded-3xl pl-10"
        />
        <img src={Search} className="absolute top-2 left-2" />
      </div>
      <div className="mx-3">
      {songs?.length>0?songs?.map(res=>{
                  return (
                  <SongListModalCard onCheckboxChange={()=>handleCheckboxChange(res.id)} title={res.title} img={res.cover_image} artist={res?.artist.artist_name} audio={res.audio_file?res.audio_file:null} value={res.title}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={preloader}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SongListModalCard/> */}
      </div>
      <div className="flex justify-center items-center">
        <button onClick={()=>{AddSongToPlaylist()}} className="text-sm font-normal bg-[#4F0DA3] text-[#F5F5F5] px-9 rounded-md py-3">Add Song</button>
      </div>
      </Modal2>

      {/* continue upload modal */}
      <Modal2 open={open} onClose={onCloseModal} center styles={{zIndex: 3000}}>
        <main className="flex flex-col justify-center items-center text-center mt-10 gap-3">
        <span className="text-2xl font-medium">Song added successfully</span>
        <span className="text-lg font-normal text-gray-600">Would you like to add another song to your playlist?</span>
        </main>
        <main className="flex flex-row justify-center items-center gap-4 mt-4">
        <button className="bg-[#4F0DA3] py-3 px-10 text-gray-300 rounded-md" onClick={()=>continueAlbumAudioUpload()}>
        Yes
        </button>
        <button className="bg-red-500 py-3 px-10 text-gray-300 rounded-md" onClick={()=>closeAllUploadModals()}>No</button>
        </main>
      </Modal2>

      {/* {modalOpen && <AudioPlayer2 setModalOpen={setModalOpen} modalOpen={modalOpen} tracks={recentlyPlayed} index={clickedIndex} minimized1={minimized} setMinimized1={setMinimized}/>} */}


    </div>
  );
}
