import React from "react";
import { BiPause, BiPlay, BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { GrNext, GrPrevious } from "react-icons/gr";
import "./styles.css";
import "../Components/player.css"
import "../Components/style.css"
import { IoPauseOutline, IoPlayBackOutline, IoPlayForwardOutline, IoPlayOutline } from "react-icons/io5";
import { HiFastForward, HiOutlineFastForward, HiOutlineRewind, HiRewind } from "react-icons/hi";
import { FaForward } from "react-icons/fa";
import { HiOutlineForward, HiOutlinePause, HiOutlinePlay } from "react-icons/hi2";
import { ImLoop, ImShuffle } from "react-icons/im";
import { MdOutlineRepeat, MdRepeat, MdShuffle, MdShuffleOn } from "react-icons/md";
import { IoMdReorder } from "react-icons/io";
import { RiRepeatOneFill } from "react-icons/ri";
// import { ReactComponent as Play } from "./assets/play.svg";
// import { ReactComponent as Pause } from "./assets/pause.svg";
// import { ReactComponent as Next } from "./assets/next.svg";
// import { ReactComponent as Prev } from "./assets/prev.svg";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  minimized1,
  loopfunc,
  shuffleFunc,
  shuffle,
  loop
}) => (
  <div className="audio-controls">
    {/* <p className="text-black">{minimized1 + "nnjj"}</p> */}
    <button
      type="button"
      className="loop"
      aria-label="loop"
      onClick={loopfunc}
      style={{ backgroundColor: "transparent" }}
    >
      {!loop?<ImLoop size={15} color={minimized1 ? "black" : "white"} />: <RiRepeatOneFill size={20} color={minimized1 ? "black" : "white"} />}
    </button>
    <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      {/* <GrPrevious /> */}
      {/* <IoPlayBackOutline /> */}
      {/* <HiOutlineRewind color={minimized1?"black": "white"} /> */}
      <BiSkipPrevious color={minimized1?"black": "white"}/>
    </button>
    <div className={!minimized1?`bg-white rounded-full h-[60px] w-[60px] flex justify-center items-center`:`bg-[#4F0DA3] rounded-full h-[60px] w-[60px] flex justify-center items-center`}>
    {isPlaying ? (
      <button
        type="button"
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        {/* <BiPause color="black" size={40} /> */}
        <HiOutlinePause color={!minimized1?"#4F0DA3": "white"} size={40} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="w-6 h-6">
  <path fill-rule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clip-rule="evenodd" />
</svg> */}
      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        {/* <BiPlay color="black" size={40} /> */}
        <HiOutlinePlay color={!minimized1?"#4F0DA3": "white"} size={40} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" clip-rule="evenodd" />
</svg> */}

      </button>
    )}
    </div>
    <button
      type="button"
      className="next"
      // style={{color:"black"}}
      aria-label="Next"
      onClick={onNextClick}
    >
      {/* <GrNext /> */}
      {/* <IoPlayForwardOutline/> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="w-6 h-6">
  <path d="M5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256l-7.108-4.061C13.555 6.346 12 7.249 12 8.689v2.34L5.055 7.061Z" />
</svg> */}
{/* <FaForward color="black" /> */}
{/* <HiOutlineFastForward color={!minimized1?"white": "black"} /> */}
<BiSkipNext color={minimized1 ? "black" : "white"} />

    </button>
    <button type="button"
      className="shuf"
      onClick = {shuffleFunc}
      style={{ backgroundColor: minimized1 ? 'white' : 'none' }}
      aria-label="shuf">
      
      {shuffle?<ImShuffle size={15} 
      // fontWeight={shuffle?'bold':null} 
      style={shuffle?{fontWeight:"700"}: {fontWeight:"400"}}
      color={minimized1 ?"black": "white"} />:<IoMdReorder size={20} style={shuffle?{fontWeight:"700"}: {fontWeight:"400"}}
      color={minimized1 ?"black": "white"}/>} 
      {/* shuffle?<MdShuffleOn size={15} color={minimized1?"white": "black"} /> :<MdShuffle size={15} color={minimized1?"white": "black"} /> */}
    </button>
  </div>
);

export default AudioControls;
