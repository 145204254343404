import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { ProtectedRoutes } from './routes/ProtectedRoutes'
import Home from './pages/Home'
import Landing from './pages/LandingPage'
/**
 * ---------------------------
 * AUTH
 * ---------------------------
 */
import Signup from './pages/Signup'
import Signin from './pages/Signin'
import Verify from './pages/Verify'
import ForgotPassword from './pages/ForgotPassword'
/**
 * ---------------------------
 * POLLS
 * ---------------------------
 */
import CantVote from './components/Modals/Vote/Cant/CantVote'
import Voted from './components/Modals/Vote/Cant/Voted'
import CreatePoll from './components/Modals/Vote/CreatePoll/CreatePoll'
import PollResult from './components/Modals/Vote/PollResult'
import SuccessPoll from './components/Modals/Vote/SuccessPoll'
import PollsSearch from './components/PollsComp/PollsSearch'
import MyPolls from './pages/Polls/MyPolls'
import Voting from './pages/Polls/Voting'
import BusinessDirectory from './pages/BussinessDirectory/BusinessDirectory'
import Chat from './pages/Chat/Chat'
// import Commerce from "pages/Commerce/commerce";
// import Commerce from "pages/Commerce/Commerce";
// Commerce
import ProductCategory from 'pages/Commerce/category/index'
// import CategoryPage from "./pages/Commerce/Category";
// creating-new-commerce-page
import Productinformation from 'pages/Commerce/products/productInfo'
import Cart from 'pages/Commerce/cart'
import PersonalDetails from './pages/PersonalDetails/PersonalDetails'
import SearchPage from './pages/SearchPage/SearchPage'
import Ticket from './pages/Ticket/Ticket'
// import BusinessProfile from './pages/BusinessProfile';
import Connect from './pages/Connect/Connect'
import Stereo from './pages/Stereo'
import NonAuthStero from './pages/Stereo/NonAuthStero'
// import AddProfile from './pages/Profile/AddProfile';
import FeedDetail from 'pages/FeedDetail/FeedDetail'
import { Toaster } from 'react-hot-toast'
import PrivacyPolicy from './components/LandinComp/Privacy'
import TicketDashCard from './components/TicketComp/TicketDashCard'
import ComingSoonPage from './pages/ComingSoon'
import Stickings from 'pages/Profile/Stickings'

/**
 * ---------------------------
 * PROFILE PAGE DEV
 * ---------------------------
 */
import { ModalContext } from 'Context/ModalContext'
import ManageBusiness from 'pages/ManageBusiness/ManageBusiness'
import RewardLayout from './Layout/RewardLayout'
import BusinessProfile from './pages/BusinessProfile/BusinessProfile'
import Profile from './pages/Profile/Profile'
import Histories from './pages/Rewards/Histories'
import Payment from './pages/Rewards/Payment'
import Payouts from './pages/Rewards/Payouts'
import Rewards from './pages/Rewards/Rewards'
import Welcome from 'pages/Stereo/Pages/Welcome'
import StereoHome from 'pages/Stereo/Pages/Home'
import BigHit from 'pages/Stereo/Pages/bigHit'
import More from 'pages/Stereo/Pages/More'
import TopAlbums from 'pages/Stereo/Pages/TopAlbums'
import StereoSearchResult from 'pages/Stereo/StereoSearchResult'
import UploadMusic from 'pages/Stereo/Pages/UploadMusic/UploadMusic'
import RecentUploads from 'pages/Stereo/Pages/UploadMusic/recentUploads'
import { AppLayout } from 'Layout/AppLayout'
import PollPayment from 'components/PollsComp/PollPayment'
import PromotionPayment from 'components/PollsComp/PromotionPayment'
import Library from 'pages/Stereo/Pages/Library'
import StereoSearchPage from 'pages/Stereo/Pages/Search'
import Stickers from 'pages/Profile/Stickers'
import MusicPlayer2 from 'pages/Stereo/Components/MusicPlayer2'
import Education from 'pages/Education/Education'
import Commerce from 'pages/Commerce/commerce'
// import SearchPage from "pages/Stereo/Pages/Search"
import OrderHistroy from 'pages/Commerce/orderhistory'
import ManageStore from 'pages/Commerce/Managestore'
import Storeproduct from 'pages/Commerce/Managestore/storeproduct'
import Myorder from 'pages/Commerce/Managestore/myorders'
import SearchProduct from 'pages/Commerce/search'
import TicketLayout from 'pages/Ticket/TicketLayout'
import SellTicketLayout from 'pages/Ticket/SellTicketLayout'
import SellTicketDash from 'pages/Ticket/SellTicketDash'
import CreateEventTictetFromOne from 'components/TicketComp/CreateEventTictetFromOne'
import CreateEventTictetFormTwo from 'components/TicketComp/CreateEventTictetFormTwo'
import CreateEventTictetFormThree from 'components/TicketComp/CreateEventTictetFormThree'
import SuccessTicketCreate from 'pages/Ticket/SuccessTicketCreate'
import MyEvent from 'pages/Ticket/MyEvent'
import TicketReport from 'pages/Ticket/TicketReport'
import Payout from 'pages/Ticket/Payout'
import PayoutHistory from 'pages/Ticket/PayoutHistory'
import PopularTicketsAll from 'pages/Ticket/PopularTicketsAll'
import PromotedTicketsAll from 'pages/Ticket/PromotedTicketsAll'
import EventThisWeekAll from 'pages/Ticket/EventThisWeekAll'
import EventDetail from 'pages/Ticket/EventDetail'
import PurchaseTicketDetail from 'pages/Ticket/PurchaseTicketDetail'
import FeedsStereoComp from 'pages/Stereo/Components/FeedsStereoComp'
import AllTicketsAll from 'pages/Ticket/AllTicketsAll'
import NewEditProfile from 'pages/Profile/NewEditProfile'
import ProfileLayout from 'pages/Profile/ProfileLayout'
import NewChangePassword from 'pages/Profile/NewChangePassword'
import NewRequestVerification from 'pages/Profile/NewRequestVerification'
import NewGadget from 'pages/Profile/NewGadget'
import ProfileGadgetLayout from 'pages/Profile/ProfileGadgetLayout'
import NewPhoneImei from 'pages/Profile/NewPhoneImei'
import NewIMEISerialModal from 'components/Modals/NewIMEISerialModal'
import EditIMEISerialModal from 'pages/Profile/EditIMEISerialModal'
import NewSerialNumber from 'pages/Profile/NewSerialNumber'
import HotelsLayout from 'Layout/HotelsLayout'
import Hotels from 'pages/Hotels/Hotels'
import MyHotelListings from 'pages/Hotels/MyHotelListings'
import SingleHotel from 'pages/Hotels/SingleHotel'
import HotelsProvider from 'Context/HotelsContext'
import LiveLayout from 'pages/Live/LiveLayout'
import Live from 'pages/Live/Live'
import LiveVideo from 'pages/Live/LiveVideo'
import NewLive from 'pages/Live/NewLive'
import TvLayout from 'pages/Tv/TvLayout'
import Tv from 'pages/Tv/Tv'
import AllMoviesAll from 'pages/Tv/AllMoviesAll'
import SingleMovie from 'pages/Tv/SingleMovie'
import MovieUserAccount from 'pages/Tv/MovieUserAccount'
import ChannelContent from 'pages/Tv/ChannelContent'
import TvPassword from 'pages/Tv/TvPassword'
import TvMonetization from 'pages/Tv/TvMonetization'
import DownloadedMovies from 'pages/Tv/DownloadedMovies'
import PlayedMovies from 'pages/Tv/PlayedMovies'
import LikedMovies from 'pages/Tv/LikedMovies'
import HistoryMovies from 'pages/Tv/HistoryMovies'
import Concerts from 'pages/Ticket/Concerts'
import Others from 'pages/Ticket/Others'
import Fashion from 'pages/Ticket/Fashion'
import Festival from 'pages/Ticket/Festival'
import Upload from 'pages/Tv/Upload'
import CreateChannel from 'pages/Tv/CreateChannel'
import EditChannel from 'pages/Tv/EditChannel'
import AllUserMovies from 'pages/Tv/AllUserMovies'
import RecentMovies from 'pages/Tv/RecentMovies'
import TaggedMovies from 'pages/Tv/TaggedMovies'
import ChatLayout from 'pages/Chat/ChatLayout'
import NewBroadcast from 'pages/Chat/NewBroadcast'
import {
  MusicPlayerContext,
  MusicPlayerProvider,
} from 'pages/Stereo/context/MusicPlayerContext'
import AudioPlayer2 from 'pages/Stereo/AudioPlayer2/AudioPlayer'
import ContextModal from 'pages/Stereo/Components/ContextModal'
import DirectChat from 'pages/Chat/DirectChat'
import DirectPublicChat from 'pages/Chat/DirectPublicChat'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const { isAuthenticated } = useContext(ModalContext)
  // const { modalOpen }=useContext(MusicPlayerContext)

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated)
  }, [isAuthenticated])
  return (
    <>
      <MusicPlayerProvider>
        <ContextModal>
          <AudioPlayer2 />
        </ContextModal>
        <div className='App'>
          <ScrollToTop />

          <Toaster
            toastOptions={{
              style: {
                maxWidth: '700px',
                padding: '12px 16px',
                fontSize: '17px',
                fontWeight: '400',
              },
              error: {
                style: {
                  color: 'red',
                },
              },
              success: {
                style: {
                  color: 'green',
                },
              },
            }}
            position='top-center'
            reverseOrder={false}
          />

          <Routes>
            {/* AUTH */}
            <Route index path='/Signup' element={<Signup />} />
            <Route index path='/Signin' element={<Signin />} />
            <Route index path='/reset-password' element={<ForgotPassword />} />
            <Route index path='/verify' element={<Verify />} />

            <Route
                  path='/PrivacyPolicy'
                  element={<PrivacyPolicy />}
                />
            {/* temporary-Stand-alone-commerce-layout-(aside-and-header) */}

            {/* Below are the pages with aside containers */}

  
              <Route element={<Navigate replace to="Home" />} />
              <Route
                path='/'
                element={
                  isAuthenticated ? <Navigate replace to='Home' /> : <Landing />
                }
              />

              <Route
                path='/poll-payment-confimed'
                element={<ProtectedRoutes element={<PollPayment />} />}
              />
              <Route
                path='/promotion-payment-confimed'
                element={<ProtectedRoutes element={<PromotionPayment />} />}
              />

              {/* temporary-Stand-alone-commerce-layout-(aside-and-header) */}

              {/* Below are the pages with aside containers */}

              <Route
                element={
                  <HotelsProvider>
                    {' '}
                    <AppLayout />
                  </HotelsProvider>
                }
              >
                <Route element={<Navigate replace to='Home' />} />
                <Route
                  path='/Home'
                  element={<ProtectedRoutes element={<Home />} />}
                />

                <Route path='/Home/:feedId' element={<FeedDetail />} />

                {/* Commerce-routes */}
                <Route
                  // removed-protected-route-for-testing-purpose
                  path='/commerce'
                  element={<ProtectedRoutes element={<Commerce />} />}
                />

                {/* route-to-specific-category in commerce page */}
                <Route
                  path='/commerce/:category'
                  element={<ProtectedRoutes element={<ProductCategory />} />}
                />
                <Route
                  // removed-protected-route-for-testing-purpose
                  path='/commerce/product/:productName'
                  element={<ProtectedRoutes element={<Productinformation />} />}
                />

                <Route
                  path='/commerce/cart'
                  element={<ProtectedRoutes element={<Cart />} />}
                />

                {/* Search_commerce-page */}
                <Route
                  path='/commerce/search/:query'
                  element={<ProtectedRoutes element={<SearchProduct />} />}
                />
                {/* OrderHistorycommerce-page */}
                <Route
                  path='/commerce/orderhistory'
                  element={<ProtectedRoutes element={<OrderHistroy />} />}
                />

                {/* manage_store-commerce-page */}

                <Route
                  path='/commerce/managestore'
                  element={<ProtectedRoutes element={<ManageStore />} />}
                />
                {/* manage_store-commerce-page */}

                <Route
                  path='/commerce/managestore/:product'
                  element={<ProtectedRoutes element={<Storeproduct />} />}
                />
                {/* manage-store-my-orders */}

                <Route
                  path='/commerce/managestore/myorders'
                  element={<ProtectedRoutes element={<Myorder />} />}
                />
                {/* Below are poll routes */}
                <Route
                  path='/Voted'
                  element={<ProtectedRoutes element={<Voted />} />}
                />
                <Route
                  path='/MyPolls'
                  element={<ProtectedRoutes element={<MyPolls />} />}
                />
                <Route
                  path='/PollResult'
                  element={<ProtectedRoutes element={<PollResult />} />}
                />
                {/* <Route index path="/Voting" element={<Voting />} /> */}
                <Route
                  path='/Voting'
                  element={<ProtectedRoutes element={<Voting />} />}
                />
            
                <Route
                  path='/SuccessPoll'
                  element={<ProtectedRoutes element={<SuccessPoll />} />}
                />
                <Route
                  path='/PollsSearch'
                  element={<ProtectedRoutes element={<PollsSearch />} />}
                />
                <Route
                  path='/CantVote'
                  element={<ProtectedRoutes element={<CantVote />} />}
                />

                {/* Below are chat routes */}
                <Route
                  path='/chat'
                  element={<ProtectedRoutes element={<ChatLayout />} />}
                >
                  <Route index element={<Chat />} />
                  <Route path='broadcast' element={<NewBroadcast />} />
                  <Route path='message/:id' element={<DirectChat />} />
                  <Route path='public-message/:id' element={<DirectPublicChat />} />
                </Route>

                <Route
                  path='/personaldetail'
                  element={<ProtectedRoutes element={<PersonalDetails />} />}
                />
                <Route path='/search' element={<SearchPage />} />
                <Route path='/ComingSoonPage' element={<ComingSoonPage />} />

                <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />

                <Route path='/TicketDashCard' element={<TicketDashCard />} />
                <Route
                  path='/business'
                  element={<ProtectedRoutes element={<BusinessDirectory />} />}
                />

                <Route
                  path='/ticket'
                  element={<ProtectedRoutes element={<TicketLayout />} />}
                >
                  <Route index element={<Ticket />} />
                  <Route path='sell-ticket' element={<SellTicketLayout />}>
                    <Route index element={<SellTicketDash />} />
                    <Route
                      path='create-event-one'
                      element={<CreateEventTictetFromOne />}
                    />
                    <Route
                      path='create-event-two'
                      element={<CreateEventTictetFormTwo />}
                    />
                    <Route
                      path='create-event-three'
                      element={<CreateEventTictetFormThree />}
                    />
                    <Route
                      path='ticket-success'
                      element={<SuccessTicketCreate />}
                    />
                    <Route path='my-events' element={<MyEvent />} />
                    <Route path='ticket-report/:id' element={<TicketReport />} />
                    <Route path='payout' element={<Payout />} />
                    <Route path='payout-history' element={<PayoutHistory />} />
                  </Route>
                  <Route
                    path='all-popular-tickets'
                    element={<PopularTicketsAll />}
                  />
                  <Route path='all-tickets' element={<AllTicketsAll />} />
                  <Route
                    path='all-promoted-tickets'
                    element={<PromotedTicketsAll />}
                  />
                  <Route
                    path='all-events-this-week'
                    element={<EventThisWeekAll />}
                  />
                  <Route path='concerts' element={<Concerts />} />
                  <Route path='festival' element={<Festival />} />
                  <Route path='fashion' element={<Fashion />} />
                  <Route path='others' element={<Others />} />
                  <Route path='event/:id' element={<EventDetail />} />
                  <Route
                    path='purchase-ticket-detail/:id'
                    element={<PurchaseTicketDetail />}
                  />
                </Route>

                {/*  */}
                <Route
                  path='/live'
                  element={<ProtectedRoutes element={<LiveLayout />} />}
                >
                  <Route index element={<Live />} />
                  <Route path='/live/new-live' element={<NewLive />} />
                  <Route path='/live-video' element={<LiveVideo />} />
                </Route>

                <Route
                  path="/tv"
                  element={<ProtectedRoutes element={<TvLayout />} />}
                >
                  <Route index element={<Tv />} />
                  <Route path="/tv/movies/top" element={<AllMoviesAll />} />
                  <Route path="/tv/movies/recent" element={<RecentMovies />} />
                  <Route path="/tv/movies/:category" element={<TaggedMovies />} />
                  <Route path="/tv/create" element={<CreateChannel />} />
                  <Route path="/tv/edit-channel" element={<EditChannel />} />
                  <Route path="/tv/channel-content/:id" element={<ChannelContent />} />
                  <Route path="/tv/monetization" element={<TvMonetization />} />
                  <Route path="/tv/password" element={<TvPassword />} />
                  <Route path="/tv/movie/:id" element={<SingleMovie />} />
                  <Route path="/tv/:user" element={<MovieUserAccount />} />
                  <Route
                    path="/tv/downloads"
                    element={<DownloadedMovies />}
                  />
                  <Route path="/tv/plays" element={<PlayedMovies />} />
                  <Route path="/tv/:user/movies" element={<AllUserMovies />} />
                  <Route path="/tv/liked" element={<LikedMovies />} />
                  <Route
                    path="/tv/history-movies"
                    element={<HistoryMovies />}
                  />
                  <Route path="/tv/upload" element={<Upload />} />
                </Route>
                {/*  */}

                <Route
                  path='/connect'
                  element={<ProtectedRoutes element={<Connect />} />}
                />
                <Route
                  path='/education'
                  element={<ProtectedRoutes element={<Education />} />}
                />

                {/* Below are stereo routes */}

                <Route
                  path='/stereo'
                  element={<ProtectedRoutes element={<Welcome />} />}
                />

                <Route
                  path='/stereo_home'
                  element={<ProtectedRoutes element={<StereoHome />} />}
                />

                <Route
                  path='/stereo_bighit'
                  element={<ProtectedRoutes element={<BigHit />} />}
                />

                <Route
                  path='/stereo_quickpicks'
                  element={<ProtectedRoutes element={<More />} />}
                />

                <Route
                  path='/stereo_feeds'
                  element={<ProtectedRoutes element={<FeedsStereoComp />} />}
                />

                <Route
                  path='/stereo_topAlbums'
                  element={<ProtectedRoutes element={<TopAlbums />} />}
                />

                {/* <Route
                  path="/stereo/search"
                  element={<ProtectedRoutes element={<StereoSearchResult />} />}
                /> */}

                <Route
                  path='/stereo_artistIndex'
                  element={<ProtectedRoutes element={<UploadMusic />} />}
                />

                <Route
                  path='/stereo_artist_recentUploads'
                  element={<ProtectedRoutes element={<RecentUploads />} />}
                />

                <Route
                  path='/stereo_nonauth'
                  element={<ProtectedRoutes element={<NonAuthStero />} />}
                />

                <Route
                  path='/stereo_search'
                  element={<ProtectedRoutes element={<StereoSearchPage />} />}
                />

                <Route
                  path='/stereo_library'
                  element={<ProtectedRoutes element={<Library />} />}
                />

                {/* Profile */}

                <Route
                  path='/:id'
                  element={<ProtectedRoutes element={<ProfileLayout />} />}
                >
                  <Route index element={<Profile />} />
                  <Route
                    path='/:id/edit-profile'
                    element={<ProtectedRoutes element={<NewEditProfile />} />}
                  />

                  <Route
                    path='/:id/change-password'
                    element={<ProtectedRoutes element={<NewChangePassword />} />}
                  />
                  <Route
                    path='/:id/verify'
                    element={
                      <ProtectedRoutes element={<NewRequestVerification />} />
                    }
                  />
                  <Route
                    path='/:id/gadgets'
                    element={
                      <ProtectedRoutes element={<ProfileGadgetLayout />} />
                    }
                  >
                    <Route index element={<NewGadget />} />
                    <Route
                      path='/:id/gadgets/phone-imei'
                      element={<NewPhoneImei />}
                    />
                    <Route
                      path='/:id/gadgets/serial'
                      element={<NewSerialNumber />}
                    />
                    <Route
                      path='/:id/gadgets/phone-imei/edit/:gadgetId'
                      element={<EditIMEISerialModal />}
                    />
                    <Route
                      path='/:id/gadgets/phone-imei/new'
                      element={<NewIMEISerialModal />}
                    />
                  </Route>

                  <Route
                    path='/:id/stickers'
                    element={<ProtectedRoutes element={<Stickers />} />}
                  />
                  <Route
                    path='/:id/stickings'
                    element={<ProtectedRoutes element={<Stickings />} />}
                  />
                </Route>

                <Route
                  path='/business-profile'
                  element={<ProtectedRoutes element={<BusinessProfile />} />}
                />

                <Route
                  path='/manage-business'
                  element={<ProtectedRoutes element={<ManageBusiness />} />}
                />

                <Route element={<ProtectedRoutes element={<RewardLayout />} />}>
                  <Route element={<Navigate replace to='rewards' />} />
                  <Route path='rewards' element={<Rewards />} />

                  <Route path='rewards/histories' element={<Histories />} />
                  <Route path='rewards/payouts' element={<Payouts />} />
                  <Route path='rewards/payment' element={<Payment />} />
                </Route>

                <Route
                  element={
                    <ProtectedRoutes
                      element={
                        <HotelsProvider>
                          <HotelsLayout />
                        </HotelsProvider>
                      }
                    />
                  }
                >
                  <Route path='/hotels' element={<Hotels />} />

                  <Route
                    path='/hotels/my-listings'
                    element={<MyHotelListings />}
                  />
                  <Route path='/hotels/:id' element={<SingleHotel />} />
                </Route>
                </Route>
              <Route path='/player' element={<MusicPlayer2 />} />
            
          </Routes>
        </div>

        <ReactQueryDevtools initialIsOpen={false} />
      </MusicPlayerProvider>
    </>
  )
}

export default App
