import { useQuery } from "@tanstack/react-query";
import {getDownloadedMoviesFn} from "api/services/tv";

export const useGetDownloadedMovies = () => {
  const {
    isLoading: isDownloadedMoviesLoading,
    data: downloadedMovies,
    status: downloadedMoviesStatus,
    error: downloadedMoviesError,
  } = useQuery({
    queryKey: ["downloads"],
    queryFn: getDownloadedMoviesFn,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    keepPreviousData: false,
  });

  return {
    isDownloadedMoviesLoading,
    downloadedMovies,
    downloadedMoviesStatus,
    downloadedMoviesError,
  };
};
