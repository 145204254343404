import { useMutation, useQueryClient } from "@tanstack/react-query";
import { readChatFn} from "api/services/chat";

const useReadChat = () => {
  const queryClient = useQueryClient();

  const {
    status: readChatStatus,
    mutate: readChat,
    error: readChatError,
  } = useMutation({
    mutationFn: (formData) => readChatFn(formData),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation"],
      });
    },
  });

  return {
    readChat,
    readChatStatus,
    readChatError,
  };
};

export default useReadChat;
