import React, { useState } from "react";
import { FaCamera, FaMicrophone } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CiFaceSmile } from "react-icons/ci";
import { BsSend } from "react-icons/bs";
import { LuMessagesSquare } from "react-icons/lu";
import cook from "../../assets/live_images/cooking-woman.jpg";
import gift from "../../assets/live_images/gift.svg";
import { BsCoin } from "react-icons/bs";
import GiftPack from "./GiftPack";

const items = [
  {
    id: 1,
    title: "Tv",
    img: gift,
  },
  {
    id: 2,
    title: "Tv",
    img: gift,
  },
  {
    id: 3,
    title: "Tv",
    img: gift,
  },
  {
    id: 4,
    title: "Tv",
    img: gift,
  },
  {
    id: 5,
    title: "Tv",
    img: gift,
  },
  {
    id: 6,
    title: "Tv",
    img: gift,
  },
  {
    id: 7,
    title: "Tv",
    img: gift,
  },
  {
    id: 8,
    title: "Tv",
    img: gift,
  },
];

const NewLive = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");

  const navigate = useNavigate();

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen">
      <div className="lg:col-span-8 lg:mx-0 mx-2 bg-white">
        <div className="h-[400px] relative">
          <img src={cook} alt="" className="w-full h-full object-cover" />
          <div className="absolute top-6 left-6 w-full">
            <div className="h-12 w-12 bg-zinc-700 rounded-full cursor-pointer flex items-center justify-center">
              <IoArrowBackOutline
                className="text-[20px] text-white"
                onClick={() => navigate("/live")}
              />
            </div>
          </div>
          <div className="flex justify-center gap-8 absolute bottom-6 w-full">
            <div className="h-20 w-20 bg-neutral-300 rounded-full cursor-pointer flex items-center justify-center">
              <FaMicrophone className="text-[24px]" />
            </div>
            <div className="h-20 w-20 bg-black rounded-full flex items-center justify-center cursor-pointer">
              <div className="h-16 w-16 bg-red-500 rounded-full"></div>
            </div>
            <div className="h-20 w-20 bg-neutral-300 rounded-full cursor-pointer flex items-center justify-center">
              <FaCamera className="text-[24px]" />
            </div>
          </div>
        </div>

        {/* Downside */}
        <div className=" p-2 px-4 mt-8 w-full">
          <div className="grid lg:grid-cols-8 grid-cols-4 lg:gap-6 gap-4 w-full">
            {items?.map((item) => (
              <GiftPack item={item} />
            ))}
          </div>

          <div className="mt-8 flex items-center gap-3">
            <h4 className="text-[17px] font-normal">Coin Balance: </h4>
            <div className="flex items-center gap-1">
              <BsCoin className="text-yellow-500 text-[18px] mb-2" />
              <h4 className="text-[18px]">56</h4>
            </div>

            <button className="p-3 w-full lg:w-[100px] bg-[#4f0da3] rounded-full text-[14px] text-[#fff]">
              Recharge
            </button>
          </div>
        </div>
      </div>

      {/* right side */}
      <div className="lg:col-span-4 lg:mt-2 mt-6 px-4 pt-4 gap-4 bg-white">
        {/* First */}
        <div style={{ borderColor: "#c8c8ce" }} className=" p-1 row-span-4 ">
          <div className="w-full border-none bg-neutral-100 rounded flex items-center px-1 ">
            <input
              type="text"
              className="text-[14px] border-none outline-none"
              placeholder="Add a title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="w-full border-none bg-neutral-100 rounded flex items-center px-1 mt-2 ">
            <input
              type="text"
              className="text-[14px] border-none outline-none"
              placeholder="Add a description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div>
            <p className="mt-2">
              This will be seen by anyone that joins your live
            </p>
          </div>
          <div>
            <button className="p-2 w-full lg:w-[100px] bg-[#4f0da3] rounded text-[14px] text-[#fff]">
              Save
            </button>
          </div>
        </div>

        {/* Second */}
        <div className="mt-8 h-[300px] flex flex-col justify-between">
          <div className="row-span-6 p-2">
            <button className="border text-black text-[16px] w-full flex items-center justify-center h-[40px]">
              Live comments
            </button>
            <div className="h-[220px] py-2 mt-4">
              <div className="flex justify-center">
                <LuMessagesSquare className="text-[40px] text-[#4f0da3]" />
              </div>
              <p className="text-center">Comments will appear hear</p>
            </div>
          </div>
          {/* Third */}
          <div className="w-full  row-span-1">
            <div className="w-full border-none bg-neutral-100 rounded flex items-center py-2 px-4">
              <CiFaceSmile className="text-black text-[24px]" />
              <input
                type="text"
                className="text-[14px] border-none outline-none"
                placeholder="Write a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="bg-rose-500 h-12 w-12 p-2 rounded-full flex justify-center items-center">
                <BsSend className="text-[18px] text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLive;
