import React from 'react'
import { useNavigate } from 'react-router-dom'
import { noUser } from 'utils/noUser'

const SearchDropdown = ({ searchResults }) => {
  const navigate = useNavigate()

  return (
    <div className='absolute left-2 h-[25rem] overflow-y-scroll mt-10 w-[300px] md:w-[410px] text-black bg-white rounded-md shadow-lg z-50 overflow-hidden border border-gray-300'>
      <div className='px-4 py-2 border-b flex justify-between items-center border-gray-200 font-bold bg-gray-50'>
        <div className='font-bold text-gray-800'>Recent Searches</div>
        <button className='cursor-pointer text-center text-[#FF0000] hover:underline'>
          Clear all
        </button>
      </div>
      {searchResults ? (
        <div className='flex flex-col'>
          {searchResults?.posts?.map((post, index) => (
            <div
              key={index}
              className='px-4 py-3 flex justify-between items-center hover:bg-gray-100 cursor-pointer transition-colors duration-200'
            >
              <span className='text-gray-700'>
                {post?.title || post.content}
              </span>
              <button className='absolute mt-2 right-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  className='w-4 h-4 stroke-black'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
          ))}
          {searchResults?.users?.map((user, index) => (
            <div
              key={index}
              className='px-4 py-3 flex justify-between items-center hover:bg-gray-100 cursor-pointer transition-colors duration-200'
            >
              <button
                onClick={() => {
                  navigate(`/${user?.username}`)
                }}
                className='flex flex- py-4 gap-2 items-center'
              >
                <img
                  src={user?.profile_picture || noUser}
                  alt='Profile'
                  className='w-10 h-10 rounded-full object-cover'
                />
                <div className='text-[12px] w-40 text-gray-700'>
                  {user?.name || user?.username}
                </div>
              </button>
              <button className='absolute mt-2 right-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  className='w-4 h-4 stroke-black'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
          ))}
          {searchResults?.businesses?.map((business, index) => (
            <div
              key={index}
              className='px-4 py-3 flex justify-between items-center hover:bg-gray-100 cursor-pointer transition-colors duration-200'
            >
              <span className='text-gray-700'>{business.name}</span>
              <button className='absolute mt-2 right-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  className='w-4 h-4 stroke-black'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
          ))}

          {searchResults.jobs?.map((job, index) => (
            <div
              key={index}
              className='px-4 py-3 flex justify-between items-center hover:bg-gray-100 cursor-pointer transition-colors duration-200'
            >
              <span className='text-gray-700'>{job.name}</span>
              <button className='absolute mt-2 right-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  className='w-4 h-4 stroke-black'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className='px-4 py-2 text-gray-500 text-center'>
          No recent searches
        </div>
      )}
    </div>
  )
}

export default SearchDropdown
