import React, { useEffect, useRef, useState } from "react";
import Header1 from "../Components/Header1";

import RightArrow from "../Assets/icon-park_right.svg";
// import PicksCard from "../Components/PicksCard";
import Ad from "../Assets/AD.jpeg";
// import HitsCard from "../Components/HitsCard";
// import SpecialPicksCard from "../Components/SpecialPicksCard";
import Plus from "../Assets/plus.svg";
import MainLayout from "../Layout/MainLayout";
import Arrow from "../Assets/arrowback.svg";
import Search from "../Assets/majesticons_search-line.svg";
import LayoutMain from "../Layout/LayoutMain";
import Explore from "./StereoHomeTabs/Explore";
import New from "./StereoHomeTabs/New";
import TopTracks from "./StereoHomeTabs/TopTracks";
import Charts from "./StereoHomeTabs/Charts";
import Artists from "./StereoHomeTabs/Artists";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
// import MusicPlayer from "../Components/MusicPlayer";
import TopAlbumCard from "../Components/TopAlbumCard";
// import Player from "../Components/MusicPlayer3";
// import AudioPlayer from "../AudioPlayer/AudioPlayer";
import AudioPlayer2 from "../AudioPlayer2/AudioPlayer";
import QuickPicksSection from "../StereoSections/QuickPicksSection";
import NewQuickPicks from "../StereoSections/NewQuickPicks";
import BighitsSection from "../StereoSections/BighitsSection";
import TopAlbumsSection from "../StereoSections/TopAlbumsSection";
import SpecialPicksSection from "../StereoSections/SpecialPicksSection";

export default function StereoHome() {
  const [activeTab, setActiveTab] = useState(0);
  const [topAlbums, setTopAlbums] = useState([])
  const [quickpicks, setQuickPicks] = useState([])
  const [bighit, setBigHit] = useState([])
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [activePlayer, setActivePlayer] = useState(null);
  const [songs, setSongs] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen1, setModalOpen1] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [index, setIndex] = useState()
  const authToken = localStorage.getItem("authToken")
  const [minimized,setMinimized]=useState(false)
  const navigation = useNavigate()
  const [isplaying, setisplaying] = useState(false)

//   const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
//  const [currentSong, setCurrentSong] = useState(quickpicks[clickedIndex])
//  const [clickedIndex2, setClickedIndex2] = useState(null); // Initialize with null
//  const [currentSong2, setCurrentSong2] = useState(bighit[clickedIndex2])
//  const [clickedIndex3, setClickedIndex3] = useState(null); // Initialize with null
//  const [currentSong3, setCurrentSong3] = useState(songs[clickedIndex3])

 const [AlbumSongs, setAlbumSongs] = useState([]);
 const [Albums, setAlbums] = useState([])

  // const handleItemClick = (index) => {
  //   console.log('Clicked index:', index);
  //   // setCurrentSong(quickpicks[clickedIndex]);
  //   setClickedIndex(index); // Set the clicked index to state
  // };

  // const handleItemClick2 = (index) => {
  //   console.log('Clicked index:', index);
  //   // setCurrentSong(quickpicks[clickedIndex]);
  //   setClickedIndex2(index); // Set the clicked index to state
  // };

  // const handleItemClick3 = (index) => {
  //   console.log('Clicked index:', index);
  //   // setCurrentSong(quickpicks[clickedIndex]);
  //   setClickedIndex3(index); // Set the clicked index to state
  // };

  // console.log(clickedIndex,"Priyanka Chopra")

  // const audioElem = useRef();

  // useEffect(() => {
  //   console.log(audioElem,"fish===")
  //   if (isplaying) {
  //     audioElem?.current?.play();
  //   }
  //   else {
  //     audioElem?.current?.pause();
  //   }
  // }, [isplaying, currentSong])

  // const onPlaying = () => {
  //   const duration = audioElem?.current?.duration;
  //   const ct = audioElem?.current?.currentTime;

  //   setCurrentSong({ ...currentSong, "progress": ct / duration * 100, "length": duration })

  // }
  

  // const handlePlayPause = (id) => {
  //   if (id === currentlyPlaying) {
  //     setCurrentlyPlaying(null);
  //   } else {
  //     setCurrentlyPlaying(id);
  //   }
  // };

  // const GetTopAlbums = () => {
  //   axios
  //     .get(`https://development.2geda.net/api/stereo/albums/top-album/`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         "X-CSRFToken": process.env.REACT_TOKEN,
  //       },
  //     })
  //     .then((res) => {
  //       setTopAlbums(res?.data?.data);
  //       console.log(topAlbums + "topAlbums state===");
  //       console.log(JSON.stringify(res.data) + "topAlbums====");
  //     }).catch(e=>console.log(e));
  // };

  const GetAlbums = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/albums/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setAlbums(res?.data?.data);
        // const albums = Albums.filter(album => album.artist.artist_name === artists?.artist_name);
        // setArtistAlbums(albums)
        console.log(Albums + "Albums state===");
        console.log(JSON.stringify(res.data) + "Albums====");
      }).catch(e=>console.log(e));
  };

  // const GetBigHits = () => {
  //   axios
  //     .get(`https://development.2geda.net/api/stereo/songs/`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         "X-CSRFToken": process.env.REACT_TOKEN,
  //       },
  //     })
  //     .then((res) => {
  //       // Filter the items where plays < 3
  //       setSongs(res.data.data)
  //       const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
  //       // Assuming setBigHit is a function to update state
  //       setBigHit(filteredHits);
  //       console.log(filteredHits,"bighits==="); // This will log the filtered items
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // const GetQuickPicks = () => {
  //   axios
  //     .get(`https://development.2geda.net/api/stereo/songs/trending/`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         "X-CSRFToken": process.env.REACT_TOKEN,
  //       },
  //     })
  //     .then((res) => {
  //       setQuickPicks(res?.data?.data);
  //       console.log(quickpicks + "quickpicks state===");
  //       console.log(JSON.stringify(res.data) + "quickpicks====");
  //     }).catch(e=>console.log(e));
  // };

  // const handleAlbumSongsList = async(id) => {
  //   // setAlbumId(id)
  //   await axios.get(`https://development.2geda.net/api/stereo/artist/albums/${id}/songs/`,  {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //       "X-CSRFToken": process.env.REACT_TOKEN,
  //     },
  //   }).then((res)=>{
  //     setAlbumSongs(res?.data?.data)
  //     setModalOpen3(!modalOpen3)
  //     console.log(res?.data?.data, "album songs====")
  //   }).catch(e => console.log(e))
  // }

  // console.log(currentSong, "alone===")
  // console.log(`development.2geda.net${currentSong?.audio_file}`, "audio===")

  // useEffect(()=>{
    // GetTopAlbums()
    // GetQuickPicks()
    // GetBigHits()
    // GetAlbums()
  // },[])

//   useEffect(()=>{
//     setCurrentSong2(bighit[clickedIndex2]);
//   },[clickedIndex2, bighit])

//   useEffect(()=>{
//     setCurrentSong3(songs[clickedIndex3]);
//   },[clickedIndex3, songs])

//   useEffect(() => {
//   setCurrentSong(quickpicks[clickedIndex]);
// }, [clickedIndex, quickpicks]);


  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <LayoutMain>
      <div>
        <Header1 />

        <div className="bg-white w-full pb-10 sm:mx-5">
          {/* <div style={{}}> */}
          {/* <Header1 /> */}
          <div
            id="container"
            className="sm:flex hidden mx-3 pt-6 justify-between align-middle items-center">
            <section
              id="iconwrapper"
              className="flex items-center space-x-2">
              <button style={{ width: 24, height: 24 }}onClick={()=>navigation("/stereo")}>
                <img src={Arrow} />
              </button>
              <span style={{ fontSize: 20, fontWeight: "700" }} fontSize={"20px"}>
                Stereo
              </span>
            </section>
            <button style={{ width: 24, height: 24 }} onClick={()=>navigation("/stereo_search")}>
              <img src={Search} />
            </button>
          </div>

          {/* Tab Section */}
          <div
            className="pt-20 sm:mt-0 w-full sm:pt-5"
            // style={{ overflowX: "scroll" }}
            >
            <div
              className="flex mx-3 gap-3 overflow-x-scroll justify-between h-full w-full pb-5"
              style={{
                // marginLeft: 10,
                // marginRight: 10,
                // display: "flex",
                // flexDirection: "row",
                // gap: 5,
                // overflowX: "scroll",
                // paddingBottom: 20,
                // justifyContent: "space-between",
                scrollbarWidth: "none",
                width:"100%"
              }}>
              <button
                style={{
                  backgroundColor: activeTab === 0 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 0 ? 25 : 0,
                  paddingLeft: activeTab === 0 ? 10 : 2,
                  paddingRight: activeTab === 0 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(0)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 0 ? "white" : "black",
                  }}>
                  Home
                </span>
              </button>

              <button
                style={{
                  backgroundColor: activeTab === 1 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 1 ? 25 : 0,
                  paddingLeft: activeTab === 1 ? 10 : 2,
                  paddingRight: activeTab === 1 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(1)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 1 ? "white" : "black",
                  }}
                  className={`${
                    activeTab === 1 ? "text-white" : "text-black"
                  }`}>
                  Explore
                </span>
              </button>
              <button
                style={{
                  backgroundColor: activeTab === 2 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 2 ? 25 : 0,
                  paddingLeft: activeTab === 2 ? 10 : 2,
                  paddingRight: activeTab === 2 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(2)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 2 ? "white" : "black",
                  }}>
                  New
                </span>
              </button>
              <button
                style={{
                  backgroundColor: activeTab === 3 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 3 ? 25 : 0,
                  paddingLeft: activeTab === 3 ? 10 : 2,
                  paddingRight: activeTab === 3 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(3)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 3 ? "white" : "black",
                  }}>
                  Top tracks
                </span>
              </button>
              <button
                style={{
                  backgroundColor: activeTab === 4 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 4 ? 25 : 0,
                  paddingLeft: activeTab === 4 ? 10 : 2,
                  paddingRight: activeTab === 4 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(4)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 4 ? "white" : "black",
                  }}>
                  Charts
                </span>
              </button>
              <button
                style={{
                  backgroundColor: activeTab === 5 ? "#4F0DA3" : "white",
                  borderRadius: activeTab === 5 ? 25 : 0,
                  paddingLeft: activeTab === 5 ? 10 : 2,
                  paddingRight: activeTab === 5 ? 10 : 2,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={() => handleTabClick(5)}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "400",
                    color: activeTab === 5 ? "white" : "black",
                  }}>
                  Artists
                </span>
              </button>
            </div>
          </div>

          {/* Body */}
          {activeTab === 0 && (
            <main>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: "space-between",
                }}>
                <span style={{ fontSize: 16, fontWeight: "500", color: "black" }}>
                  Quick picks
                </span>

                <button onClick={()=>navigation("/stereo_quickpicks")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#4F0DA3",
                      }}
                      fontSize={"14px"}
                      fontWeight={"400"}
                      color={"primary.50"}>
                      More
                    </span>
                    <img src={RightArrow} />
                  </div>
                </button>
              </div>
              {/* cards1 quickpicks*/}
              <div>
                <NewQuickPicks/>
              </div>
              {/* </div> */}
              {/* ad */}
              <div style={{ margin: "10px 10px" }}>
                <img
                  src={Ad}
                  alt="Advertisement"
                  style={{
                    height: "80px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>

              {/* second body */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}>
                <span style={{ fontSize: 16, fontWeight: "500", color: "black" }}>
                  Today’s big hits
                </span>

                <button onClick={()=>navigation("/stereo_bighit")} backgroundColor={"none"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#4F0DA3",
                      }}>
                      More
                    </span>
                    <img src={RightArrow} />
                  </div>
                </button>
              </div>

              {/* second card big hits*/}

              <BighitsSection/>
              {/* 3rd Card top albums*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                  marginRight: 10,
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}>
                <span style={{ fontSize: 16, fontWeight: "500", color: "black" }}>
                  Top albums
                </span>

                <button backgroundColor={"none"} onClick={()=>navigation("/stereo_topAlbums")}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#4F0DA3",
                      }}>
                      More
                    </span>
                    <img src={RightArrow} />
                  </div>
                </button>
              </div>
              <TopAlbumsSection/>

              {/* special picks */}
              <div
                style={{
                  display: "flex",
                  marginLeft: 10,
                  marginRight: 10,
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: "space-between",
                }}>
                <span style={{ fontSize: 16, fontWeight: "500", color: "black" }}>
                  Special picks for you
                </span>
              </div>

              <SpecialPicksSection/>

              {/* ad */}
              <div style={{ margin: "10px 10px" }}>
                <img
                  src={Ad}
                  alt="Advertisement"
                  style={{
                    height: "80px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
            </main>
          )}

          {activeTab === 1 && <Explore />}
          {activeTab === 2 && <New />}
          {activeTab === 3 && <TopTracks />}
          {activeTab === 4 && <Charts />}
          {activeTab === 5 && <Artists Albums={Albums} songs={bighit} />}

          {/* flooating toogler */}
          {/* <Fab
        color="#4F0DA3"
        aria-label="add"
        style={{
          position: "sticky",
          bottom: 20,
          left: "80%",
          backgroundColor: "#4F0DA3",
          zIndex: 1050,
        }}>
        <img src={Plus} width={24} height={24} />
      </Fab> */}




        </div>
      </div>
    </LayoutMain>
  );
}
