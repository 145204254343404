import { useEffect, useRef } from "react";
import TicketSearchComp from "../../components/TicketComp/TicketSearchComp";
import "./second.css";
import { useState } from "react";
import UpComingEvent from "../../components/TicketComp/UpComingEvent";
import ActiveEvent from "../../components/TicketComp/ActiveEvent";
import PastEvent from "../../components/TicketComp/PastEvent";
import { useNavigate } from "react-router-dom";
import useGetTickets from "Hooks/useGetTickets";
import Spin from "components/Spin/Spin";

const MyEvent = () => {
  const [activeTab, setActiveTab] = useState("Upcoming");

  const navigate = useNavigate();

  const { myEvents, isMyEventsLoading, isMyEventFetching, myEventRefetch } =
    useGetTickets();

  const upcomingEvents = myEvents?.data?.["upcoming-events"];
  const activeEvents = myEvents?.data?.["active-events"];
  const pastEvents = myEvents?.data?.["past-event"];

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -10 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const goBack = () => {
    navigate("/ticket/sell-ticket");
  };

  useEffect(() => {
    myEventRefetch();
  }, [myEventRefetch]);

  if (isMyEventsLoading) {
    return <Spin />;
  }

  return (
    <div className="p-4">
      <div ref={pageRef}>
        <TicketSearchComp
          label={"My Events"}
          handleCloseContainerClick={goBack}
          add={"add"}
        />
      </div>
      <div className="my-event-container">
        <div className=" flex justify-between text-[14px] mt-4 text-neutral-500">
          <div
            onClick={() => handleTabClick("Upcoming")}
            className="cursor-pointer"
          >
            Upcoming
          </div>

          <div
            onClick={() => handleTabClick("Active")}
            className="cursor-pointer"
          >
            Active events
          </div>

          <div
            onClick={() => handleTabClick("Past")}
            className="cursor-pointer"
          >
            Past events
          </div>
        </div>
        <div className="flex justify-between h-[3px] mt-2 w-full rounded-full bg-neutral-300">
          <div
            className={
              activeTab === "Upcoming" ? "w-[20%] bg-[#4f0da3]" : "w-[20%]"
            }
          ></div>
          <div
            className={
              activeTab === "Active" ? "w-[20%] bg-[#4f0da3]" : "w-[20%]"
            }
          ></div>
          <div
            className={
              activeTab === "Past" ? "w-[20%] bg-[#4f0da3]" : "w-[20%]"
            }
          ></div>
        </div>
      </div>

      {isMyEventsLoading || isMyEventFetching ? (
        <Spin />
      ) : (
        <>
          {activeTab === "Upcoming" && (
            <UpComingEvent handleBackClick={goBack} events={upcomingEvents} />
          )}
          {activeTab === "Active" && (
            <ActiveEvent handleBackClick={goBack} events={activeEvents} />
          )}
          {activeTab === "Past" && (
            <PastEvent handleBackClick={goBack} events={pastEvents} />
          )}
        </>
      )}
    </div>
  );
};

export default MyEvent;
