/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Link } from '@nextui-org/react'
import JambLogo from './assets/JAMB.svg'
import NecoLogo from './assets/neco.svg'
import WeacLogo from './assets/waecdirect.org.svg'
import UILogo from './assets/UIlogo.svg'
import UnILogo from './assets/188px-University_of_Lagos_logo.svg'
import LauLogo from './assets/LAUTECH-Logo_0-removebg-preview.svg'

const RightMenu = () => {
  const resources = [
    {
      id: 1,
      about:
        'Nigerian Defence Academy entrance examination past questions 2012 to 2022',
      img: NecoLogo,
      link: 'https://nda.edu.ng/',
    },
    {
      id: 2,
      about: 'JAMB past questions 2012-2022',
      img: JambLogo,
      link: 'https://www.jamb.gov.ng/',
    },
    {
      id: 3,
      about: 'LAUTECH Post-UTME screening form is out!',
      img: LauLogo,
      link: 'https://www.lautech.edu.ng/',
    },
    {
      id: 4,
      about: 'UNILAG Post- UTME past questions 2012 to 2023',
      img: UnILogo,
      link: 'https://unilag.edu.ng/',
    },
    {
      id: 5,
      about: 'UI Post- UTME past questions 2012 to 2023',
      img: UILogo,
      link: 'https://ui.edu.ng/',
    },
  ]

  const latestNews = [
    {
      id: 1,
      about: 'UNILAG admission list first batch is out!',
      img: UnILogo,
      link: 'https://unilag.edu.ng/',
    },
    {
      id: 2,
      about: 'LAUTECH Post-UTME screening form is out',
      img: LauLogo,
      link: 'https://www.lautech.edu.ng/',
    },
    {
      id: 3,
      about: 'WAEC 2023 result is out!',
      img: WeacLogo,
      link: 'https://www.waecnigeria.org/',
    },
    {
      id: 4,
      about: 'JAMB result 2023 is out!',
      img: JambLogo,
      link: 'https://www.jamb.gov.ng/',
    },
  ]

  return (
    <div className=' bg-white w-full h-full min-h-screen   overflow-visible rounded-md py-2 space-y-3 pb-10  shadow px-6 top-24  stcky columns-1 md:block'>
      <div className='flex flex-col space-y-6 relative text-black'>
        <h2 className='font-[500]'>Resources</h2>
      </div>

      <div className='flex flex-col gap-y-6 xxlg:gap-y-12 relative text-gray-500  w-full text-sm'>
        {resources?.map?.((chatH) => (
          <Card key={chatH?.id} className='w-full rounded-xl bg-gray-100'>
            <Link
              isExternal
              href={chatH?.link}
              className=' py-2 px-3 flex items-center gap-3 mr-3'
            >
              <img
                alt=' logo'
                height={40}
                radius='sm'
                src={chatH?.img}
                width={40}
              />
              <p className='text-[12px] pt-3 text-black'>{chatH?.about}</p>
            </Link>
          </Card>
        ))}
      </div>
      <div className='flex flex-col space-y-6 relative text-black'>
        <h2 className='font-[500]'>Latest news</h2>
      </div>

      <div className='flex  flex-col gap-y-6  w-full'>
        {latestNews?.map?.((chatH) => (
          <Card key={chatH?.id} className='w-full rounded-xl bg-gray-100'>
            <Link
              isExternal
              href={chatH?.link}
              className=' py-2 px-3 flex items-center gap-3 mr-3'
            >
              <img
                alt=' logo'
                height={40}
                radius='sm'
                src={chatH?.img}
                width={40}
              />
              <p className='text-[12px] pt-3 text-black'>{chatH?.about}</p>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default RightMenu
