import React, { useContext, useEffect, useState } from "react";
import LayoutMain from "../Layout/LayoutMain";
import Header1 from "../Components/Header1";
import TrendingCard from "../Components/TrendingCard";
import PromoCard from "../Components/PromoCard";
import Player from "../Assets/Mini player.svg";
import Logo from "../Assets/2gedaLogo.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Navigate, useNavigate, useNavigation } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
// import Lottie from "lottie-react";
import preloader from "../../../pages/Home/Animation - 1703321875032 (1).json"
import AudioPlayer2 from "../AudioPlayer2/AudioPlayer";
import NewQuickPicks from "../StereoSections/NewQuickPicks";
import { useStore } from "zustand";
import { audioPlayerStore } from "../../../zustand/store";
import { MusicPlayerContext } from "../context/MusicPlayerContext";




export default function Welcome() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState()
  const [about, setAbout] = useState()
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [songs, setSongs] = useState([])
  const [trendLoading, setTrendLoading] = useState(false)
  const [songLoading, setSongLoading] = useState(false)
  const [modalOpen1, setModalOpen1] = useState(false)
  const [clickedIndex1, setClickedIndex1] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
//  const [currentSong1, setCurrentSong1] = useState(Afrobeats[clickedIndex])
 const [minimized, setMinimized] = useState(false)
 const [activeModal, setActiveModal] = useState(null);
//  const [tracks, setTracks] = useState()
  const navigation = useNavigate()
  const [currentPlaylist, setCurrentPlaylist] = useState([])
  const [currentTrackIndex,setCurrentTrackIndex] = useState(null)
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const [trendingSongs, setTrendingSongs] = useState([]);
  const authToken = localStorage.getItem("authToken")
  const account = localStorage.getItem("accounts")
  const currentInstance = useStore(audioPlayerStore, (state) => state.currentInstance)

  const closeOtherInstances = () => {
    if (currentInstance) {
      currentInstance.pause()
    }
  }

  // const artist = account?.find(acc=>acc === "artist")
  // alert(artist)

  const handleItemClick = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks) // Set the clicked index to state
  };

  const handleItemClick1 = (index) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setClickedIndex(index); // Set the clicked index to state
    setModalOpen1(!modalOpen1)
    setCurrentPlaylist(songs)
  };

  const handleFileChange = (e)=>{
    if (e.target.files){
      setImage(e.target.files[0])
    }
  }

  const GetTrendingSongs = () => {
    setTrendLoading(true)
    axios
      .get(`https://api.2geda.net/api/stereo/songs/trending/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setTrendLoading(false)
        setTrendingSongs(res?.data?.data);
        console.log(trendingSongs + "trending state===");
        console.log(JSON.stringify(res.data) + "trending====");
        if (res.status === 200 || res.status===201){
          // setLoading(false)
          console.log(res.data.message)
        } else if (res.status == null){
        console.log(res.data.message)
        } else {
          console.log("Something went wrong")
        }
      }).catch(err=>{
        setTrendLoading(false)
        console.log("Something went wrong")
      })
  };

  const GetSongs = () => {
    setSongLoading(true)
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setSongLoading(false)
        // Filter the items where plays < 3
        setSongs(res.data.data)
        // const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
        // Assuming setBigHit is a function to update state
        // setBigHit(filteredHits);
        // console.log(filteredHits,"bighits==="); // This will log the filtered items
      })
      .catch((error) => {
        setSongLoading(false)
        console.error('Error fetching data:', error);
      });
  };

  const RegisterArtist = () => {
    setLoading(true)
    console.log(image)
    const payload = {
      artist_name: userName,
      about: about,
      brand_image: image
    }
    axios.post("https://api.2geda.net/api/stereo/artists/register/",payload, {headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": 'multipart/form-data',
      "X-CSRFToken": process.env.REACT_TOKEN,
    }}).then(res=>{
      console.log(res)
      setLoading(false)
      if (res.status === 200 || res.status===201){
        // setLoading(false)
        toast.success(res.data.message)
        navigation("/stereo_artistIndex")
      } else if (res.status == null){
        toast.error(res.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }).catch(err=>{
      toast.error("Something went wrong")
    })
  }

  // useEffect(()=>{
  //   closeOtherInstances()
  // },[currentInstance])

  useEffect(() => {
    GetSongs()
    GetTrendingSongs();
    if (account ==="artist"){
      setRedirect(true)
    } else {
      setRedirect(false)
    }
    console.log(account, "took account=======")
  }, []);
  const closeModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <LayoutMain>
      <Header1 />
      <div className="bg-[#fff] mt-10 sm:mt-0 sm:mx-5 pt-10 pb-20 overflow-scroll">
        <div
          id="heading-desktop"
          className="hidden sm:flex sm:flex-col sm:px-4">
          <span className="font-medium text-2xl">Stereo</span>
        </div>
        <div className="flex flex-col mt-14 sm:flex-row sm:justify-between">
          <div className="px-4">
            <span className="font-medium text-2xl sm:text-3xl">
              Welcome to 2geda stereo
            </span><br/>
            <span className="text-lg font-normal sm:text-xl">
              Explore and discover new music features for you.
            </span>
          </div>
          <div className="px-4 flex gap-4 mt-4 sm:mt-0">
            <button onClick={()=>navigation("/stereo_home")} className="border h-min px-4 py-1 rounded-xl border-[#4F0DA3]">
              <span className="font-normal text-xs text-[#4F0DA3]">Listener</span>
            </button>
            <button
              onClick={() => {redirect? navigation("/stereo_artistIndex"):setIsOpen(!isOpen)}}
              className="border h-min px-4 py-1 rounded-xl border-[#4F0DA3]">
              <span className="font-normal text-xs text-[#4F0DA3]">Artist</span>
            </button>
          </div>
        </div>

        {/* body */}
        <main>
          <section className="px-4 mt-5">
            <span className="font-medium text-base">Listen to trending songs</span>
            {/* <NewQuickPicks/> */}
            <div
              className={trendingSongs?.length>0?`flex overflow-x-scroll gap-2 mt-3`:null}
              style={{ scrollbarWidth: "none" }}>
                {!trendLoading?trendingSongs?.length>0?trendingSongs?.map((res, index)=>{
                  return (
                  <TrendingCard setActiveModal={setActiveModal} modalOpen2={modalOpen} setModalOpen2={setModalOpen}
                  id={res.id}
                  onClick={()=>handleItemClick(index, trendingSongs)} category={res.title} img={res.cover_image} artist={res?.artist.artist_name}/>
                  )
                }):<div className="flex justify-center items-center">
                <span>Nothing here</span>
              </div>:<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
              {/* <TrendingCard />
              <TrendingCard />
              <TrendingCard />
              <TrendingCard />
              <TrendingCard />
              <TrendingCard /> */}
            </div>
          </section>
          <section className="px-4 mt-5">
            <span className="font-medium text-xl sm:text-2xl">
              Upload and promote your songs
            </span>
            <div
              className={songs.length>0?"flex overflow-x-scroll gap-3 mt-3 pb-1":null}
              style={{ scrollbarWidth: "none" }}>

{!songLoading?songs?.length>0?songs?.map((res, index)=>{
                  return (
                  <PromoCard setActiveModal={setActiveModal} onClick={()=>{handleItemClick(index, songs)}} title={res.title} img={res.cover_image} artist={res?.artist.artist_name} audio={res.audio_file}/>
                  )
                }):<div className="flex justify-center items-center">
                <span>Nothing here</span>
              </div>:<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
              
            </div>
          </section>
        </main>

        {/* register Modal */}
        {isOpen && (
          <div
            id="modelConfirm"
            className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4">
            <div className="relative top-40 mx-auto shadow-xl bg-white max-w-[375px] rounded-xl overflow-x-hidden">
              <div className="relative w-full bg-[#4F0DA3] rounded-t-xl">
                <img src={Player} className="w-full rounded-t-xl" />
                <img src={Logo} className="absolute top-6 left-5" />
              </div>
              <main className="mx-3 mt-2 mb-5">
                <span className="font-normal text-base">Hello,</span>
                <span className="font-normal text-base">
                  Let’s get you signed up.
                </span>
              </main>
              {/* Pass any children as content within the modal */}
              <main id="form" className="w-auto mx-3 overflow-x-hidden">
                <input
                  type="text"
                  placeholder="Username"
                  onChange={(e)=>setUserName(e.target.value)}
                  className="max-w-[351px] text-black px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                {/* <input
                  type="text"
                  placeholder="About"
                  className="max-w-[351px] px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                /> */}
                <textarea onChange={(e)=>setAbout(e.target.value)} placeholder="About"
                  className="max-w-[351px] text-black px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]" cols={6}></textarea>
                <div className="relative">
                  <input
                    type={"file"}
                    onChange={handleFileChange}
                    placeholder="Create password"
                    className="max-w-[351px] text-black px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                  />
                  
                </div>
                {/* <div className="flex items-center mb-5 gap-1">
                  <span className="font-light text-xs">
                    Already have an account?{" "}
                  </span>
                  <a
                    onClick={() => {
                      setIsLoginOpen(!isLoginOpen);
                      setIsOpen(!isOpen);
                    }}
                    href="#"
                    className="font-light text-xs text-[#4F0DA3]">
                    Sign in
                  </a>
                </div> */}
                <div className="flex justify-center mb-5">
                  {loading?<Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>RegisterArtist()} className="bg-[#4F0DA3] text-white py-2 px-20 rounded-md">
                    Sign Up
                  </button>}
                </div>
              </main>
            </div>
          </div>
        )}

        {/* register Modal */}
        {isLoginOpen && (
          <div
            id="modelConfirm"
            className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4">
            <div className="relative top-40 mx-auto shadow-xl bg-white max-w-[375px] rounded-xl overflow-x-hidden">
              <div className="relative w-full bg-[#4F0DA3] rounded-t-xl">
                <img src={Player} className="w-full rounded-t-xl" />
                <img src={Logo} className="absolute top-6 left-5" />
              </div>
              <main className="mx-3 mt-2 mb-5">
                <span className="font-normal text-base">Welcome back,</span>
                <span className="font-normal text-base">Login to your account</span>
              </main>
              {/* Pass any children as content within the modal */}
              <main id="form" className="w-auto mx-3 overflow-x-hidden">
                <input
                  type="text"
                  placeholder="Username"
                  className="max-w-[351px] px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <div className="mb-2">
                  <a href="#" className="font-light text-xs text-[#4F0DA3]">
                    Use artist name instead
                  </a>
                </div>
                {/* <input
                  type="text"
                  placeholder="Artist name"
                  className="max-w-[351px] px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                /> */}
                <div className="relative">
                  <input
                    type={show ? "text" : "password"}
                    placeholder="Enter Password"
                    className="max-w-[351px] px-3 mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                  />
                  <button
                    onClick={() => setShow(!show)}
                    className="absolute top-4 right-5 w-[18.34px] h-[9.64px]">
                    {show ? (
                      <BsEyeSlash width={18.34} height={9.64} />
                    ) : (
                      <BsEye width={18.34} height={9.64} />
                    )}
                  </button>
                </div>
                <div className="mb-2">
                  <a href="#" className="font-normal text-xs text-[#FF0000]">
                    Forgot password?
                  </a>
                </div>
                <div className="flex items-center mb-5 gap-1">
                  <span className="font-light text-xs">New here?</span>
                  <a
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setIsLoginOpen(!isLoginOpen);
                    }}
                    href="#"
                    className="font-light text-xs text-[#4F0DA3]">
                    Create account
                  </a>
                </div>
                <div className="flex justify-center mb-5">
                  <button className="bg-[#4F0DA3] text-white py-2 px-20 rounded-md">
                    Login
                  </button>
                </div>
              </main>
            </div>
          </div>
        )}
      </div>

      {/* {modalOpen && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}
            // className={`flex items-center justify-center fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full ${
            //   minimized ? "px-4" : ""
            // }`}
            >
            <div 
            className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}
            // className={`${
            //   minimized ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" : "relative top-40 mx-auto"
            // } shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[90%] xl:max-w-[30%] rounded-xl overflow-x-hidden`}
            >
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {activeModal && <AudioPlayer2
    setModalOpen1={activeModal === 'player1'?setModalOpen: setModalOpen1}
    modalOpen={activeModal === 'player1'?modalOpen:modalOpen1}
    minimized1={minimized}
    setMinimized1={setMinimized}
    tracks={activeModal === 'player1'?trendingSongs:songs}
    index={activeModal === 'player1'?clickedIndex: clickedIndex1}
  />} */}

                {/* {modalOpen && <AudioPlayer2 setModalOpen={setModalOpen} modalOpen={modalOpen} minimized1={minimized} setMinimized1={setMinimized} tracks={currentPlaylist} index={clickedIndex}/>} */}
                {/* {modalOpen1 && <AudioPlayer2 setModalOpen1={setModalOpen1} modalOpen={modalOpen1} minimized1={minimized} setMinimized1={setMinimized} tracks={songs} index={clickedIndex1}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
      {/* <AudioPlayer2 setModalOpen1={modalOpen?setModalOpen:setModalOpen1} modalOpen={modalOpen?modalOpen:modalOpen1} minimized1={minimized} setMinimized1={setMinimized} tracks={modalOpen?trendingSongs:songs} index={modalOpen?clickedIndex:clickedIndex1}/> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </LayoutMain>
  );
}
