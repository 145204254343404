import { BsUpload } from "react-icons/bs";
import { useRef, useState } from "react";
import StepFirst from "./StepFirst/StepFirst";
import { useForm } from "react-hook-form";
import useGetEventDetails from "Hooks/useGetEventDetails";
import TicketSearchComp from "./TicketSearchComp";
import { useNavigate } from "react-router-dom";

const CreateEventTictetFromOne = () => {
  const [eventImage, setEventImage] = useState("");
  const [image, setImage] = useState(null);
  const [isPlatforn] = useState();
  const [startDateTime, setStartDateTime] = useState("mm/dd/yyyy");
  const [endDateTime, setEndDateTime] = useState("mm/dd/yyyy");
  const [startDateTimeError, setStartDateTimeError] = useState("");
  const [endDateTimeError, setEndDateTimeError] = useState("");

  const fileRef = useRef(null);

  const {
    handleCreateTicketClose,
    handleEditTicketClose,
    handleSetSellTicketOne,
    isEditing,
    sellTicketOne,
    editDetail,
  } = useGetEventDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      eventTitle:
        !sellTicketOne && editDetail?.title
          ? editDetail?.title
          : sellTicketOne?.eventTitle
          ? sellTicketOne.eventTitle
          : "",
      eventDescription:
        !sellTicketOne && editDetail?.description
          ? editDetail.description
          : sellTicketOne?.eventDescription
          ? sellTicketOne?.eventDescription
          : "",
      venueName:
        !sellTicketOne && editDetail?.venue_name
          ? editDetail.venue_name
          : sellTicketOne?.venueName
          ? sellTicketOne?.venueName
          : "",
      venueAddress:
        !sellTicketOne && editDetail?.venue_address
          ? editDetail.venue_address
          : sellTicketOne?.venueAddress
          ? sellTicketOne?.venueAddress
          : "",
      platformName: sellTicketOne?.platformName
        ? sellTicketOne?.platformName
        : "",
      websiteUrl: sellTicketOne?.websiteUrl ? sellTicketOne?.websiteUrl : "",
    },
  });

  const navigate = useNavigate();

  const handleStartDateTimeChange = (e) => {
    setStartDateTime(e.target.value);
  };

  const handleEndDateTimeChange = (e) => {
    setEndDateTime(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEventImage(URL.createObjectURL(e.target.files[0]));

    

    setImage(file);
  };

  /* const handlePlatfornClick = () => {
    setIsPlatforn(!isPlatforn);
  };
 */
  const onSubmit = (data) => {
    if (startDateTime === "mm/dd/yyyy") {
      setStartDateTimeError("Date and time required");
      setTimeout(() => {
        setStartDateTimeError("");
      }, 3000);
    } else if(endDateTime === "mm/dd/yyyy") {
      setEndDateTimeError("Date and time required")
      setTimeout(() => {
        setEndDateTimeError("");
      }, 3000);
    } else {
      handleSetSellTicketOne({
        ...data,
        startDate: startDateTime?.substring(0, 10),
        endDate: endDateTime?.substring(0, 10),
        startTime: startDateTime?.substring(11),
        endTime: endDateTime?.substring(11),
        image: image,
      });

      navigate("/ticket/sell-ticket/create-event-two");
    }
  };

  const handleCloseTicket = () => {
    handleCreateTicketClose();
    navigate("/ticket/sell-ticket");
  };

  const handleCloseEditTicket = () => {
    handleEditTicketClose();
    navigate("/ticket/sell-ticket");
  };

  return (
    <form
      style={{ margin: "10px" }}
      onSubmit={handleSubmit(onSubmit)}
      className="p-2"
    >
      <TicketSearchComp
        label={isEditing ? "Edit event" : "Create event"}
        handleCloseContainerClick={
          isEditing ? handleCloseEditTicket : handleCloseTicket
        }
        add={"add"}
      />
      <StepFirst />
      <div className="my-4">
        <h4 className="font-semibold">Event info</h4>
        <p>Tell us a bit about your event</p>
      </div>
      <div className="border h-[60px] rounded relative px-3 flex">
        <label className="absolute -top-4 left-4 z-10  bg-white">
          Event title
        </label>
        <div className="">
          <input
            type="text"
            id="eventTitle"
            className="border-none outline-none text-[15px] absolute top-3 left-0 "
            placeholder="Enter your event title"
            {...register("eventTitle", {
              required: "Event title is required",
            })}
          />
        </div>
        <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
          {errors ? (
            <p className="text-red-500 text-[12px]">
              {errors?.eventTitle?.message}
            </p>
          ) : (
            <div> </div>
          )}
          <p className="text-[12px] text-neutral-400 lg:block hidden">
            0/50 words
          </p>
        </div>
      </div>

      <div className="border rounded relative p-3 flex flex-col mt-4">
        <label className="absolute -top-4 left-4 z-10 bg-white">
          Event Description
        </label>
        <div>
          <textarea
            cols="30"
            rows="10"
            id="eventDescription"
            {...register("eventDescription", {
              required: "Event Description is required",
            })}
            className="w-full text-[15px] text-black outline-none border-none mt-2 resize-none"
            placeholder="Enter your event details. It may contain FAQs and what attendees should expect from the event"
          />
        </div>
        <div className="flex justify-between items-center self-end w-full mt-4">
          {errors.eventDescription ? (
            <p className="text-red-500 text-[12px]">
              {errors?.eventDescription?.message}
            </p>
          ) : (
            <div> </div>
          )}
          <p className="text-[12px] text-neutral-400 lg:block hidden">
            0/500 words
          </p>
        </div>
      </div>

      <div className="event-inp-overall-cont dotted mt-6 relative">
        {!eventImage.length && (
          <label htmlFor="" className="absolute -top-4 left-4 z-10 bg-white">
            Cover image
          </label>
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="pic-input"
          ref={fileRef}
          onChange={handleImageChange}
        />
        {eventImage.length ? (
          <label
            htmlFor="pic-input"
            className="cursor-pointer w-[100%] h-[100%] "
          >
            <div className="w-[100%] h-[100%] ">
              <img
                src={eventImage}
                alt="uploaded"
                className="w-[100%] h-[100%] object-cover"
              />
            </div>
          </label>
        ) : (
          <>
            <label
              htmlFor="pic-input"
              className="mt-8 flex flex-col items-center justify-center cursor-pointer"
            >
              <BsUpload className="text-[18px] mb-2" />
              <h4 className="text-center">Upload event image</h4>
              <p className="text-center">
                Upload a compelling image. We recommend using at least a
                2160x1080px (2:1 ratio) image that's no larger than 10MB
              </p>
            </label>
          </>
        )}
        {/* {errors.image && (
          <div className="error-message" style={{ color: "red" }}>
            {errors.image}
          </div>
        )} */}
      </div>

      <label className=" bg-white">Start date and time</label>
      <div className="border h-[50px] rounded flex ">
        <input
          className="border-none outline-none text-[13px]"
          type="datetime-local"
          value={startDateTime}
          onChange={handleStartDateTimeChange}
        />
      </div>
      {startDateTimeError && (
          <p className="text-red-500 text-[12px]">{startDateTimeError}</p>
        )}

      <label className="bg-white mt-3">End date and time</label>
      <div className="border h-[50px] rounded flex">
        <input
          className="border-none outline-none text-[13px] "
          type="datetime-local"
          value={endDateTime}
          onChange={handleEndDateTimeChange}
        />
      </div>
      {endDateTimeError && (
          <p className="text-red-500 text-[12px]">{endDateTimeError}</p>
        )}

      {/* {isPlatforn ? (
        <div
          className="flex items-center gap-2 mt-2 mb-6 text-[14px] w-fit text-blue-500 cursor-pointer"
          onClick={handlePlatfornClick}
        >
          <IoLocation className="mb-1" />
          <div>Physical event?</div>
        </div>
      ) : (
        <div
          className="flex items-center gap-2 mt-2 mb-6 text-[14px] w-fit text-blue-500 cursor-pointer"
          onClick={handlePlatfornClick}
        >
          <BsLaptop className="mb-1" />
          <div className="">Online event?</div>
        </div>
      )} */}

      {isPlatforn ? (
        <>
          <div className="border h-[60px] rounded relative px-3 flex">
            <label className="absolute -top-4 left-4 z-10 bg-white">
              Platform name
            </label>
            <div className="">
              <input
                type="text"
                className="border-none outline-none text-[15px] absolute top-3 left-0"
                id="websiteUrl"
                {...register("websiteUrl", {
                  required: isPlatforn && "Website url is required",
                })}
                placeholder="https://www.example.com"
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors.websiteUrl ? (
                <p className="text-red-500 text-[12px]">
                  {errors.websiteUrl.message}
                </p>
              ) : (
                <div></div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden">
                0/50 words
              </p>
            </div>
          </div>
          <div className="border h-[60px] rounded relative px-3 flex mt-4">
            <label className="absolute -top-4 left-4 z-10 bg-white">
              Website Link or URL
            </label>
            <div className="">
              <input
                type="text"
                id="platformName"
                {...register("platformName", {
                  required: isPlatforn && "Platform name is required",
                })}
                className="border-none outline-none text-[15px] absolute top-3 left-0"
                placeholder="skype, google meet, webinar, etc."
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors.platformName ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.platformName?.message}
                </p>
              ) : (
                <div></div>
              )}
              <p className="text-[12px] text-neutral-400">0/50 words</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="border h-[60px] rounded relative px-3 flex mt-4">
            <label className="absolute -top-4 left-4 z-10 bg-white">
              Venue name
            </label>
            <div className="">
              <input
                type="text"
                id="venueName"
                {...register("venueName", {
                  required: !isPlatforn && "Venue name is required",
                })}
                className="border-none outline-none text-[15px] absolute top-3 left-0"
                placeholder="Name of hotel, building or event centre"
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors.venueName ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.venueName?.message}
                </p>
              ) : (
                <div></div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden">
                0/50 words
              </p>
            </div>
          </div>

          <div className="border h-[60px] rounded relative px-3 flex mt-4">
            <label className="absolute -top-4 left-4 z-10 bg-white">
              Venue address
            </label>
            <div className="">
              <input
                type="text"
                id="venueAddress"
                {...register("venueAddress", {
                  required: !isPlatforn && "Venue address is required",
                })}
                className="border-none outline-none text-[15px] absolute top-3 left-0"
                placeholder="Give a clear address"
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors.venueAddress ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.venueAddress?.message}
                </p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden">
                0/50 words
              </p>
            </div>
          </div>
        </>
      )}
      <button
        className="w-full mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded hover:opacity-90"
        type="submit"
      >
        Continue
      </button>
    </form>
  );
};

export default CreateEventTictetFromOne;
