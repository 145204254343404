import useBuyTicket from "Hooks/tickets/useBuyTicket";
import useGetEventDetails from "Hooks/useGetEventDetails";
import useGetTickets from "Hooks/useGetTickets";
import Spinner from "components/Spinner";
import PaymentModalOne from "components/TicketComp/PaymentModalOne";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const PurchaseTicketDetail = () => {
  const [vip, setVip] = useState(0);
  const [regular, setRegular] = useState(0);
  const [error, setError] = useState("");
  const [isModalOpen] = useState(false);

  const { id } = useParams();
  const { buyingMutate, buyingStatus } = useBuyTicket(id);
  const { buyTicketOne, isPurchaseTicketDetailsOpen } = useGetEventDetails();
  console.log("buyone",buyTicketOne)
  console.log("ispurchase", isPurchaseTicketDetailsOpen);
  const { eventDetails } = useGetTickets(id);
  console.log("eventdeta",eventDetails)
  const pageRef = useRef(null);
  const navigate = useNavigate();

  const handleGetRef = () => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, [isPurchaseTicketDetailsOpen]);

  const goBack = () => {
    navigate(-1);
  };

  const amount =
    vip * (eventDetails?.data?.event_details?.vip_ticket_price || 0) +
    regular * (eventDetails?.data?.event_details?.regular_ticket_price || 0);

  const vipTicketsAvailable =
    eventDetails?.data?.available_tickets?.vip_ticket_available;
  const regularTicketsAvailable =
    eventDetails?.data?.available_tickets?.regular_ticket_available;

  const remainingVipTickets =
    typeof vipTicketsAvailable === "string"
      ? 1
      : vipTicketsAvailable < 10
      ? vipTicketsAvailable + 1
      : 31;
  const remainingRegularTickets =
    typeof regularTicketsAvailable === "string"
      ? 1
      : regularTicketsAvailable < 10
      ? regularTicketsAvailable + 1
      : 31;

  const vipTickets = Array.from({ length: remainingVipTickets }, (_, i) => i);
  const regularTickets = Array.from(
    { length: remainingRegularTickets },
    (_, i) => i
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (vip < 1 && regular < 1) {
      setError("Must submit a valid ticket number");
      return;
    }

    if (regular > regularTicketsAvailable || vip > vipTicketsAvailable) {
      setError("Selected tickets exceed available tickets.");
      return;
    }

    const data = {
      event: {
        title: eventDetails?.data?.event_details?.title || "Event Title",
        description:
          eventDetails?.data?.event_details?.description || "Event Description",
      },
      first_name: buyTicketOne?.firstName || "",
      last_name: buyTicketOne?.lastName || "",
      email: buyTicketOne?.email || "",
      address: buyTicketOne?.address || "",
      regular_ticket_type: "regular",
      vip_ticket_type: "vip",
      regular_ticket: regular,
      vip_ticket: vip,
      amount: amount.toString(),
      callback_url: "http://localhost:3000/ticket",
      // callback_url: "https://2geda.net/ticket",
    };

    console.log("Data being sent:", data);

    buyingMutate(data);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (vip < 1 && regular < 1) {
  //     setError("Must submit a valid ticket number");
  //   } else {
  //     const data = {
  //       first_name: buyTicketOne?.firstName,
  //       last_name: buyTicketOne?.lastName,
  //       regular_ticket_type: "regular",
  //       vip_ticket_type: "vip",
  //       regular_ticket: regular,
  //       vip_ticket: vip,
  //       amount: amount,
  //       email: buyTicketOne?.email,
  //       address: buyTicketOne?.address,
  //       callback_url: "https://2geda.net/ticket",
  //     };

  //     buyingMutate(data);
  //   }
  // };

  useEffect(() => {
    if (!buyTicketOne?.firstName) {
      navigate("/ticket");
    }
  }, [buyTicketOne?.firstName, navigate, id]);

  return (
    <form onSubmit={handleSubmit} className="p-4">
      {isModalOpen && (
        <div className="modal-full-container">
          <PaymentModalOne />
        </div>
      )}
      <div className="flex w-full items-center gap-4 mt-4" ref={pageRef}>
        <FaArrowLeftLong onClick={goBack} className="text-[18px]" />
        <h2 className="font-semibold text-[18px] mt-2">Choose your ticket</h2>
      </div>

      <div className="py-8">
        <div className="border h-[200px] lg:w-[90%] mx-auto flex flex-col rounded px-4">
          <div className="border-b flex-1 items-center flex">
            <h4 className="text-[18px] text-[#4f0da3]">Meta App Unveil</h4>
          </div>
          {remainingRegularTickets > 0 && (
            <div className="border-b flex-1 flex items-center w-full justify-between py-1 text-[#4f0da3]">
              <div className="mt-2">
                <h4 className="text-[15px] text-black">Regular</h4>
                <p className="text-[#4f0da3]">
                  #{eventDetails?.data?.event_details?.regular_ticket_price}
                </p>
              </div>
              <div>
                <select
                  id="regular"
                  className="text-[15px] bg-neutral-100 border-none outline-none cursor-pointer px-4"
                  onChange={(e) => setRegular(Number(e.target.value))}
                  value={regular}
                >
                  {regularTickets.map((num, index) => (
                    <option key={index} className="text-[16px]" value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {remainingVipTickets > 0 && (
            <div className="flex-1 flex items-center w-full justify-between text-[#4f0da3]">
              <div className="mt-2">
                <h4 className="text-[15px] text-black">VIP</h4>
                <p className="text-[#4f0da3]">
                  #{eventDetails?.data?.event_details?.vip_ticket_price}
                </p>
              </div>
              <div>
                <select
                  id="vip"
                  className="text-[15px] bg-neutral-100 border-none outline-none cursor-pointer px-4"
                  onChange={(e) => setVip(Number(e.target.value))}
                  value={vip}
                >
                  {vipTickets.map((num, index) => (
                    <option key={index} className="text-[16px]" value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {error && <p className="text-red-500 lg:ml-16 text-[12px]">{error}</p>}
      </div>
      <div className="mx-auto w-[80%]">
        <button
          type="submit"
          className="w-full mx-auto mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded"
          disabled={
            typeof regularTicketsAvailable === "string" &&
            typeof vipTicketsAvailable === "string"
          }
        >
          {buyingStatus === "pending" ? <Spinner /> : "Continue to payment"}
        </button>
      </div>
    </form>
  );
};

export default PurchaseTicketDetail;
