import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import ModalHeader from "components/Modals/ModalHeader";
import PhoneImei from "components/Modals/PhoneImei";
import useModal from "antd/es/modal/useModal";
import ModalContainer from "components/Modals/ModalContainer";
import { useOpenModal } from "Hooks/useOpenModal";
import { useGetGadgets } from "Hooks/profile/useGetGadjets";
import NewEditProfileHeader from "components/PollsComp/ProfilleComp/NewEditProfileHeader";
import { useNavigate } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";

const NewGadget = () => {
  const { modal } = useModal();
  const { handleClick } = useOpenModal();
  const navigate = useNavigate()

  const { gadgets, gadgetStatus } = useGetGadgets();
  const {profileData} = useProfile()
  const username = profileData?.data?.data?.user?.username

  const serialGadgets = gadgets?.data.filter(
    (item) => item.category === "serial_number"
  );
  const imeiGadgets = gadgets?.data.filter((item) => item.category === "imei");

  return (
    <>
      <NewEditProfileHeader header="Phone IMEI/ Gadget serial no" />
      <div className="px-[15px] h-[90vh] overflow-y-auto mb-0 py-[10px] lg:px-[10px] lg:h-[60vh] overflow-auto lg:mb-[1.5rem]">
        <div className="flex flex-col gap-[20px] py-[1.5rem] px-0">
          {/* PHONE IMEI */}
          <div
            className="flex p-[10px] justify-between items-center rounded-[5px] border border-[#d0d5dd] cursor-pointer clickModalOpen"
            id="btn"
            onClick={() => navigate(`/${username}/gadgets/phone-imei`)}
            data-modal="phoneIMEI"
          >
            <div>
              <h4 className="text-[#000] text-[14px]">Phone IMEI</h4>
              <span className="text-[#ff8a15] text-[14px]">
                {imeiGadgets?.length}
              </span>
            </div>

            <div className="text-[20px]  text-gray-500">
              <IoIosArrowForward />
            </div>
          </div>
          {/* {modal.phoneIMEI && (
            <ModalContainer type="phoneIMEI">
              <PhoneImei title="Phone IMEI" type="Imei" gadgets={imeiGadgets} />
            </ModalContainer>
          )}
 */}
          {/* SERIAL NUMBER */}
          <div
            className="flex p-[10px] justify-between items-center rounded-[5px] border border-[#d0d5dd] cursor-pointer  clickModalOpen"
            id="btn"
            onClick={() => navigate(`/${username}/gadgets/serial`)}
            data-modal="serialNO"
          >
            <div>
              <h4 className="text-[#000] text-[14px]" >
                Serial number
              </h4>
              <span className="text-[#ff8a15] text-[14px]">
                {serialGadgets?.length}
              </span>
            </div>

            <div className="text-[20px] text-gray-500">
              <IoIosArrowForward />
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default NewGadget;
