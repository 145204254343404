import React, { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TicketSearchComp from "../../components/TicketComp/TicketSearchComp";
import EventDetailDetailsComp from "../../components/TicketComp/EventDetailDetailsComp";
import TicketInfoCalender from "../../components/TicketComp/TicketInfoCalender";
import ReceverDetail from "../../components/TicketComp/ReceverDetail";
import ChooseTicket from "./ChooseTicket";
import useGetTickets from "Hooks/useGetTickets";
import useGetEventDetails from "Hooks/useGetEventDetails";
import Spin from "components/Spin/Spin";

const EventDetail = () => {
  const [isChooseOpen, setIsChooseOpen] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { handleBuyTicketClose } = useGetEventDetails();

  const {
    eventDetails,
    isEventDetailLoading,
    eventDetailRefetch,
    isEventDetailFetching
  } = useGetTickets(id);

  const eventDetail = useMemo(() => ({
    ...eventDetails?.data?.event_details,
    ...eventDetails?.data?.available_tickets,
    ...eventDetails?.data?.remaining_tickets,
  }), [eventDetails]);

  const pageRef = useRef(null);
  const receiverDetailRef = useRef(null);

  const scrollToTop = useCallback(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    scrollToTop();
    eventDetailRefetch();
  }, [eventDetailRefetch, scrollToTop]);

  const handleCloseAllClick = useCallback(() => {
    handleBuyTicketClose();
    navigate(-1);
  }, [handleBuyTicketClose, navigate]);

  const handleChooseClick = useCallback(() => {
    setIsChooseOpen(true);
  }, []);

  const handleCloseChooseClick = useCallback(() => {
    setIsChooseOpen(false);
  }, []);
  // https://2geda.net/ticket/event/a90d044

  const handleGetTicketClick = useCallback(() => {
    if (receiverDetailRef.current) {
      receiverDetailRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (isEventDetailLoading || isEventDetailFetching) {
    return <Spin />;
  }

  return (
    <div className="bg-neutral-100">
      {isChooseOpen && (
        <ChooseTicket
          eventDetail={eventDetail}
          handleCloseChooseClick={handleCloseChooseClick}
          handleCloseAllClick={handleCloseAllClick}
        />
      )}
      {!isChooseOpen && eventDetail && (
        <div ref={pageRef}>
          <TicketSearchComp
            label="Event details"
            handleCloseContainerClick={handleCloseAllClick}
            add="add"
          />
          <div className="px-2">
            <div className="w-[99%] h-[320px] lg-h-[400px] mx-auto">
              <img
                src={eventDetail.event_image || "https://img.freepik.com/free-photo/restaurant-hall-with-tables-decorated-with-tall-vases-with-roses_1304-4823.jpg"}
                alt="Event"
                className="w-[100%] h-[100%] object-cover"
              />
            </div>
            <EventDetailDetailsComp
              handleGetTicketClick={handleGetTicketClick}
              eventDetail={eventDetail}
            />
            <TicketInfoCalender eventDetail={eventDetail} />
            <ReceverDetail
              receiverDetailRef={receiverDetailRef}
              eventDetail={eventDetail}
              handleChooseClick={handleChooseClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
