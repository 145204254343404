import EventCategory from "../../components/TicketComp/EventCategory";
import EventThisWeek from "../../components/TicketComp/EventThisWeek";
import PopularTicket from "../../components/TicketComp/PopularTicket";
import PromoteTicket from "../../components/TicketComp/PromoteTicket";
import TicketSearch from "../../components/TicketComp/TicketSearch";
import Spin from "components/Spin/Spin";
import useGetTickets from "Hooks/useGetTickets";
import "./style.css";
import AllTickets from "components/TicketComp/AllTickets";
import { useEffect, useRef, useState } from "react";
import ContainerError from "components/ContainerError";

const Ticket = () => {
  const pageRef = useRef();
  const {
    allEvents,
    isAllEventsLoading,
    allTickets,
    allTicketsError,
    allEventsError,
    isAllTicketsLoading,
    isAllEventsFetching,
    isAllTicketsFetching,
    allEventsRefetch,
    allTicketsRefetch,
  } = useGetTickets();

  const tickets = allTickets?.data?.message;
  const events = allEvents?.data?.data;

  const [filteredData, setFilteredData] = useState(null);
  const [queries, setQueries] = useState({
    searchTerm: "",
    venueTerm: "",
    citiesTerm: "",
    eventName: "",
    statesTerm: "",
    categoryTerm: "",
  });

  useEffect(() => {
    const filterApiData = (
      eventsData,
      ticketsData,
      {
        searchTerm = "",
        venueTerm = "",
        citiesTerm = "",
        eventName = "",
        statesTerm = "",
        categoryTerm = "",
      }
    ) => {
      const eventThisWeek = eventsData?.events_this_week;
      const popularEvent = eventsData?.popular_events;
      const promotedEvent = eventsData?.promoted_events;
      const tickets = Array.isArray(ticketsData) ? ticketsData : [];

      const customFilter = (
        tickets,
        searchTerm,
        venueTerm,
        citiesTerm,
        statesTerm,
        eventName,
        categoryTerm
      ) => {
        return tickets?.filter((event) => {
          const titleMatches = event.title
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase());
          const venueMatches = event.venue_name
            ?.toLowerCase()
            .includes(venueTerm.toLowerCase());
          const citiesMatches =
            event.venue_address
              ?.toLowerCase()
              .includes(citiesTerm.toLowerCase()) ||
            event.venue_name?.toLowerCase().includes(citiesTerm.toLowerCase());
          const statesMatches =
            event.venue_address
              ?.toLowerCase()
              .includes(statesTerm.toLowerCase()) ||
            event.venue_name?.toLowerCase().includes(statesTerm.toLowerCase());
          const eventNameMatches = event.title
            ?.toLowerCase()
            .includes(eventName.toLowerCase());

          let categoryMatches = true;
          if (categoryTerm) {
            categoryMatches = event.event_category === categoryTerm;
          }

          return (
            titleMatches &&
            venueMatches &&
            citiesMatches &&
            eventNameMatches &&
            statesMatches &&
            categoryMatches
          );
        });
      };

      const filteredTickets = customFilter(
        tickets,
        searchTerm,
        venueTerm,
        citiesTerm,
        statesTerm,
        eventName,
        categoryTerm
      );

      const filteredEventsThisWeek = customFilter(
        eventThisWeek,
        searchTerm,
        venueTerm,
        citiesTerm,
        statesTerm,
        eventName,
        categoryTerm
      );
      const filteredPromotedEvents = customFilter(
        promotedEvent,
        searchTerm,
        venueTerm,
        citiesTerm,
        statesTerm,
        eventName,
        categoryTerm
      );
      const filteredPopularEvents = customFilter(
        popularEvent,
        searchTerm,
        venueTerm,
        citiesTerm,
        statesTerm,
        eventName,
        categoryTerm
      );

      return {
        all_events: filteredTickets,
        events_this_week: filteredEventsThisWeek,
        promoted_events: filteredPromotedEvents,
        popular_events: filteredPopularEvents,
      };
    };

    if (events && tickets) {
      const filteredResults = filterApiData(events, tickets, queries);
      setFilteredData(filteredResults);
    }
  }, [queries, events, tickets, setFilteredData]);

  useEffect(() => {
    allEventsRefetch();
    allTicketsRefetch();
  }, [allEventsRefetch, allTicketsRefetch]);

  if (allEventsError || allTicketsError) {
    return <ContainerError />;
  }

  const isLoading =
    isAllEventsLoading ||
    isAllTicketsLoading ||
    isAllEventsFetching ||
    isAllTicketsFetching;

  return (
    <div className="p-4" ref={pageRef}>
      <TicketSearch queries={queries} setQueries={setQueries} />
      {isLoading ? (
        <Spin />
      ) : (
        filteredData && (
          <>
            <AllTickets allEventsData={filteredData.all_events} />
            <PopularTicket popularEventsData={filteredData.popular_events} />
            <PromoteTicket promotedEventsData={filteredData.promoted_events} />
            <EventCategory events={filteredData.all_events} />
            <EventThisWeek eventsThisWeekData={filteredData.events_this_week} />
          </>
        )
      )}
    </div>
  );
};

export default Ticket;
