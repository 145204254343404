import { useRef, useState, useEffect } from "react";
import TvRightSide from "./TvRightSide";
import EditMovieProfileHeader from "./EditMovieProfileHeader";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FaCamera } from "react-icons/fa";
import { IoMdCheckbox } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaGlobeAfrica } from "react-icons/fa";
import TvTagsModal from "./TvTagsModal";
import { FaVideo } from "react-icons/fa6";
import "./tv.css";
import useCreateMovie from "Hooks/tv/useCreateMovie";
import Spinner from "components/Spinner";
import { useTv } from "Hooks/tv/useTv";

const Upload = () => {
  
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  
  const [data, setData] = useState({
    title: "",
    description: "",
    view_type: "",
  });

  const [eventImage, setEventImage] = useState("");
  const [eventVideo, setEventVideo] = useState("");
  const [isScheduled, setIsScheduled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedTagsString, setSelectedTagsString] = useState('');

  const [startDateTime, setStartDateTime] = useState("");
  const [dateError, setDateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [tagError, setTagError] = useState("");
  const [videoError, setVideoError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      view_type: "",
    },
  });

  const {userTvData} = useTv()

  const id = userTvData?.data?.data[0]?.id
  
  const {createMovie, createMovieError, createMovieStatus, isCreateMovieLoading} = useCreateMovie()

  
  const handleStartDateTimeChange = (e) => {
    setStartDateTime(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEventImage(URL.createObjectURL(file));
      setImage(file);
    }
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEventVideo(URL.createObjectURL(file));
      setVideo(file);
    }
  };

  const onSubmit = (data) => {
    if (!video) {
      setVideoError("Please add a video");
      setTimeout(() => setVideoError(""), 3000);
    } else if (!image) {
      setImageError("Please add a cover photo");
      setTimeout(() => setImageError(""), 3000);
    } else if (selectedTags.length === 0) {
      setTagError("Please select at least one tag");
      setTimeout(() => setTagError(""), 3000);
    } else if (isScheduled && startDateTime === "") {
      setDateError("Date and time required");
      setTimeout(() => setDateError(""), 3000);
    } else {
      const formData = {
        ...data,
        //startDate: isScheduled ? startDateTime?.substring(0, 10) : null,
        schedule: startDateTime,
        cover_photo: image,
        file: video,
        tags: selectedTagsString,
        channel: id
      }

      console.log(formData)
      createMovie(formData)
    }
  };

  const handleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fileRef = useRef(null);
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const handleSetIsScheduled = () => {
    setIsScheduled(!isScheduled);
  };

 
  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      {isModalOpen && (
        <TvTagsModal
          setIsModalOpen={setIsModalOpen}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          selectedTagsString={selectedTagsString}
          setSelectedTagsString={setSelectedTagsString}
        />
      )}
      <div className="lg:col-span-8 bg-white mt-2 py-2 px-4">
        <EditMovieProfileHeader header="Upload Movie" />
        <form
          style={{ margin: "10px" }}
          onSubmit={handleSubmit(onSubmit)}
          className="p-2"
        >
          <div className="my-4"></div>

          {/* UPLOAD VIDEO STARTS */}
          <div
            className="event-inp-overall-cont mt-6 border border-neutral-400 relative"
            style={{ border: "1px solid", borderRadius: "4px" }}
          >
            {!eventVideo && (
              <label htmlFor="video-input" className="absolute -top-4 left-4 z-10 bg-white">
                Upload video (MP4 format)
              </label>
            )}
            <input
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              id="video-input"
              ref={fileRef}
              onChange={handleVideoChange}
            />
            {eventVideo ? (
              <label htmlFor="video-input" className="cursor-pointer w-[100%] h-[100%] ">
                <div className="w-[100%] h-[300px] ">
                  <video src={eventVideo} className="w-[100%] h-[100%] object-cover" controls />
                </div>
              </label>
            ) : (
              <label
                htmlFor="video-input"
                className="mt-8 flex flex-col items-center justify-center cursor-pointer"
              >
                <FaVideo className="text-[18px] mb-2" />
                <h4 className="text-center text-neutral-500">Upload video</h4>
              </label>
            )}
            {videoError && (
              <div className="error-message text-[12px]" style={{ color: "red" }}>
                {videoError}
              </div>
            )}
          </div>
          {/* UPLOAD VIDEO ENDS */}

          <div className="border h-[60px] rounded relative px-3 flex">
            <label className="absolute -top-4 left-4 z-10 bg-white">Add Title</label>
            <input
              type="text"
              id="title"
              className="border-none outline-none text-[15px] absolute top-3 left-0"
              placeholder="Enter movie title"
              {...register("title", { required: "Movie title is required" })}
            />
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors.title && (
                <p className="text-red-500 text-[12px]">{errors.title.message}</p>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden">0/50 words</p>
            </div>
          </div>

          <div className="border rounded relative p-3 flex flex-col mt-4">
            <label className="absolute -top-4 left-4 z-10 bg-white">Movie Description</label>
            <textarea
              cols="20"
              rows="5"
              id="description"
              {...register("description", { required: "Movie Description is required" })}
              className="w-full text-[15px] text-black outline-none border-none mt-2 resize-none"
              placeholder="Add movie description"
            />
            <div className="flex justify-between items-center self-end w-full mt-4">
              {errors.description && (
                <p className="text-red-500 text-[12px]">{errors.description.message}</p>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden">0/500 words</p>
            </div>
          </div>

          {/*UPLOAD COVER PHOTO STARTS  */}
          <div
            className="event-inp-overall-cont mt-6 border border-neutral-400 relative"
            style={{ border: "1px solid", borderRadius: "4px" }}
          >
            {!eventImage && (
              <label htmlFor="cover-input" className="absolute -top-4 left-4 z-10 bg-white">
                Add cover photo
              </label>
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="cover-input"
              ref={fileRef}
              onChange={handleImageChange}
            />
            {eventImage ? (
              <label htmlFor="cover-input" className="cursor-pointer w-[100%] h-[100%] ">
                <div className="w-[100%] h-[100%] ">
                  <img src={eventImage} alt="uploaded" className="w-[100%] h-[100%] object-cover" />
                </div>
              </label>
            ) : (
              <label
                htmlFor="cover-input"
                className="mt-8 flex flex-col items-center justify-center cursor-pointer"
              >
                <FaCamera className="text-[18px] mb-2" />
                <h4 className="text-center text-neutral-500">Add cover photo</h4>
              </label>
            )}
            {imageError && (
              <div className="error-message text-[12px]" style={{ color: "red" }}>
                {imageError}
              </div>
            )}
          </div>
          {/* UPLOAD COVER PHOTO ENDS */}

          <div
            style={{
              border: "1px solid",
              borderRadius: "4px",
              borderColor: "#e6e3e3",
            }}
            className="border-none flex items-center cursor-pointer py-1 px-4 text-neutral-400 rounded"
          >
            <FaGlobeAfrica className="text-[18px] text-neutral-500 rounded" />
            <select
              id="view_type"
              {...register("view_type", { required: "Visibility status is required" })}
              className="border-none border-neutral-400 bg-white cursor-pointer text-neutral-400 text-[13px] outline-none"
            >
              <option value="">Visibility</option>
              <option value="PUBLIC">Public</option>
              <option value="PRIVATE">Private</option>
            </select>
          </div>
          {errors.view_type && (
            <p className="text-red-500 text-[12px]">{errors.view_type.message}</p>
          )}

          <div
            className="border h-[40px] rounded-full px-3 mt-[20px] text-[13px] text-[black] flex items-center cursor-pointer"
            onClick={handleShowModal}
          >
            <span className="text-[13px] text-neutral-500 ml-2">Add tag </span>
            <span className="text-[11px] text-neutral-400 ml-2 mt-1">(select all that apply)</span>
          </div>
          {tagError && (
            <span className="mt-0 text-[12px]" style={{ color: "red" }}>
              {tagError}
            </span>
          )}

          <div className="flex justify-between items-center my-4">
            <p className="text-[14px]">Movie premiere</p>
            {!isScheduled ? (
              <MdCheckBoxOutlineBlank
                className="text-[16px] text-neutral-600 mb-1"
                onClick={handleSetIsScheduled}
              />
            ) : (
              <IoMdCheckbox
                className="text-[16px] text-neutral-600 mb-1"
                onClick={handleSetIsScheduled}
              />
            )}
          </div>

          {isScheduled && (
            <>
              <label className="bg-white">Movie release date and time (GMT+1)</label>
              <div
                className="h-[50px] rounded flex border-neutral-400 border"
                style={{ border: "1px solid", borderRadius: "4px" }}
              >
                <input
                  className="border-none outline-none text-[13px]"
                  type="datetime-local"
                  value={startDateTime}
                  onChange={handleStartDateTimeChange}
                />
              </div>
            </>
          )}
          {dateError && (
            <div className="error-message text-[12px]" style={{ color: "red" }}>
              {dateError}
            </div>
          )}

          <button
            className="w-full mt-4 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded hover:opacity-90"
            type="submit"
            disabled={createMovieStatus === 'pending'}
          >
            {createMovieStatus === 'pending' ? <Spinner /> : 'Upload now'}
          </button>
        </form>
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-4 py-2 ">
        <TvRightSide noUpload />
      </div>
    </div>
  );
};

export default Upload;
