
import MoviesCardItem from "./MoviesCardItem";


const MoviesCard = ({ data }) => {
  
  return (
    <div className="p-2 grid gap-4 grid-flow-col lg:auto-cols-[30%] md:auto-cols-[70%] auto-cols-[80%] overflow-x-scroll overscroll-contain">
      {data?.map((item) => (
        <MoviesCardItem item={item}  key={item?.id}/>
      ))}
    </div>
  );
};

export default MoviesCard;
