import React, { useContext, useEffect, useState } from "react";
import SpecialPicksCard from "../../Components/SpecialPicksCard";
import Ad from "../../Assets/AD.jpeg";
import NewCard from "../../Components/NewCard";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import axios from "axios";
import AudioPlayer2 from "pages/Stereo/AudioPlayer2/AudioPlayer";
import AudioPlayer from "pages/Stereo/AudioPlayer/AudioPlayer";
import { MusicPlayerContext } from "pages/Stereo/context/MusicPlayerContext";

export default function New() {
  const [recentAlbums, setRecentAlbums] = useState([])
  const [quickpicks, setQuickPicks] = useState([])
  const [songs, setSongs] = useState([])
  const authToken = localStorage.getItem("authToken")
  const navigation = useNavigate()
  const [modalOpen1, setModalOpen1] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [clickedIndex1, setClickedIndex1] = useState(null); // Initialize with null
 const [currentSong, setCurrentSong] = useState(songs[clickedIndex1])
//  const [modalOpen, setModalOpen] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
 const [currentSong1, setCurrentSong1] = useState(recentAlbums[clickedIndex])
 const [minimized,setMinimized] = useState(false)
 const [AlbumSongs, setAlbumSongs] = useState([]);
 const [Albums, setAlbums] = useState([])
 const [mode, setMode] = useState(false)
 const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)


 const handleItemClick = (index, tracks) => {
  console.log('Clicked index:', index);
  // setCurrentSong(quickpicks[clickedIndex]);
  setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
};

const handleItemClick1 = (index) => {
  console.log('Clicked index:', index);
  // setCurrentSong(quickpicks[clickedIndex]);
  setClickedIndex(index); // Set the clicked index to state
};
  

  const GetRecentAlbums = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/albums/recent-album/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setRecentAlbums(res?.data?.data);
        console.log(recentAlbums + "newAlbums state===");
        console.log(JSON.stringify(res.data) + "newAlbums====");
      }).catch(e=>console.log(e));
  };

  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setSongs(res?.data?.data);
        console.log(songs + "songs state===");
        console.log(JSON.stringify(res.data) + "songs====");

        
      }).catch(e=>console.log(e));
  };

  const handleAlbumSongsList = async(id) => {
    // setAlbumId(id)
    await axios.get(`https://api.2geda.net/api/stereo/artist/albums/${id}/songs/`,  {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then((res)=>{
      setAlbumSongs(res?.data?.data)
      setTracks(res?.data?.data)
      setModalOpen(!modalOpen)
      console.log(res?.data?.data, "album songs====")
    }).catch(e => console.log(e))
  }

  
  

  useEffect(()=>{
    GetRecentAlbums()
    GetSongs()
  },[])
  return (
    <div>
      <div className="mx-3">
        <span className="text-xl font-medium">New Release</span>
      </div>
      <div
        className={songs.length>0?"flex overflow-x-scroll gap-2 mx-3 mt-3":null}
        style={{ scrollbarWidth: "none" }}>
          {songs?.length>0?songs?.map((res, index)=>{
                  return (
                    <NewCard onClick={()=>handleItemClick(index, songs)} modalOpen={modalOpen1} setModalOpen={setModalOpen1} img={res.cover_image} category={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }).reverse():<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <NewCard />
        <NewCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <NewCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <NewCard />
        <NewCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <NewCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <NewCard />
        <NewCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <NewCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <NewCard />
        <NewCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <NewCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        /> */}
      </div>

      <div className="mx-3 mt-5">
        <span className="text-xl font-medium">New Albums</span>
      </div>
      <div
        className={recentAlbums?.length>0?"flex mx-3 gap-3 overflow-x-scroll justify-between h-full":null}
        style={{ scrollbarWidth: "none" }}>
          {recentAlbums?.length>0?recentAlbums?.map(res=>{
                  return (
                  <NewCard onClick={()=>handleAlbumSongsList(res.id)} category={res.name} img={res.cover_image} artist={res.artist.artist_name} modalOpen={mode} setModalOpen={setMode}  />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        
      </div>

      {/* ad */}
      <div style={{ margin: "10px 10px" }}>
        <img
          src={Ad}
          alt="Advertisement"
          style={{
            height: "80px",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      {/* {modalOpen1 && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen1 && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen1} modalOpen={modalOpen1} tracks={songs} index={clickedIndex1}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}

{/* {modalOpen3 && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen3 && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen3} modalOpen={modalOpen3} tracks={AlbumSongs} index={0}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}


    </div>
  );
}
