import useGetEventDetails from "Hooks/useGetEventDetails";
import AdScence from "components/AdScence";
import DashMessage from "components/Dashboard/DasMess";
import Follower from "components/Dashboard/Follower";
import React, { useEffect, useRef } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

const TicketLayout = () => {
  const location = useLocation();
  
  const navigate = useNavigate();
  const pageRef = useRef(null);

  const { handleCreateTicketClose, handleEditTicketClose, handleBuyTicketClose } =
    useGetEventDetails();

  const sellTicketOpen = () => {
    handleBuyTicketClose()
    navigate("/ticket/sell-ticket");


  };
  const buyTicketOpen = () => {
    handleCreateTicketClose();
    handleEditTicketClose();
    navigate("/ticket");
  };


  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -8 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);


  return (
    <div className="lg:grid lg:grid-cols-12" ref={pageRef}>
      <div className="lg:col-span-7 bg-white lg:overflow-y-auto lg:max-h-screen" >
        <div className="lg:hidden block w-full">
          <button
            className="bg-[#FF8A15] mx-auto text-[#fff] px-6 py-3 mt-2 text-[14px] w-[95%] rounded flex items-center justify-between gap-2"
            onClick={
              location.pathname.startsWith("/ticket/sell-ticket")
                ? buyTicketOpen
                : sellTicketOpen
            }
          >
            {location.pathname.startsWith("/ticket/sell-ticket")
              ? "Buy ticket"
              : "Sell ticket"}
            <FaChevronRight />
          </button>
        </div>
        <Outlet />
      </div>

      <div className="lg:col-span-2 hidden lg:block bg-white mx-3 lg:h-screen">
        <img
          src="images/ads1.png"
          alt=""
          className="h-[100%] w-[100%] object-cover"
        />
        <AdScence />
      </div>
      <div className="lg:col-span-3 hidden lg:block lg:h-screen">
        <button
          className="bg-[#FF8A15] text-[#fff] lg:h-16 px-6 lg:py-4 py-2 lg:text-[15px] text-[13px] w-full rounded flex items-center justify-between gap-2"
          onClick={
            location.pathname.startsWith("/ticket/sell-ticket")
              ? buyTicketOpen
              : sellTicketOpen
          }
        >
          {location.pathname.startsWith("/ticket/sell-ticket")
            ? "Buy ticket"
            : "Sell ticket"}
          <FaChevronRight />
        </button>

        <Follower />
        <div className="">
          <DashMessage />
        </div> 
      </div>
    </div>
  );
};

export default TicketLayout;
