import React from 'react'
// import PostImage from 'assets/images/sample-post-image.png'
import { useFetchImages } from 'api/services/feeds'
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'


const Feedimages = () => {
  const { data: getImages, isPending } = useFetchImages()
  // const fileBaseUrl = `https://2geda-backend.s3.amazonaws.com`

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        gap: '1rem',
      }}
    >
      <div className='pb-8'>
      {getImages?.length === 0 && (
        <div className='text-3xl'>
          <h1>No image at the moment, please come back later</h1>
          <img src={EmptyImage} alt='nothing here' />
        </div>
      )}
      </div>
      {getImages?.map((item) => (
        <div key={item?.file_id}>
          {console.log(item?.file)}
          <img
            src={item?.file ? item.file : '/assets/images/placeholder.jpg'}
            alt='imagefeeds'
            style={{ height: '120px', objectFit: 'cover' }}
          />
        </div>
      ))}
    </div>
  )
}

export default Feedimages
