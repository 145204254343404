import React, { useContext, useEffect, useState } from "react";
import MoreBg from "../Assets/moreBg.jpeg";
import Arrow from "../Assets/whiteback.svg";
import Logo from "../Assets/2gedaLogo.svg";
import Music from "../Assets/MusicImg.svg";
import MoreCard from "../Components/MoreCard";
import Ad from "../Assets/AD.jpeg";
import LayoutMain from "../Layout/LayoutMain";
import Arrow2 from "../Assets/arrowback.svg";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import Header1 from "../Components/Header1";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import AudioPlayer2 from "../AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "../context/MusicPlayerContext";

export default function More() {
  const [quickpicks, setQuickPicks] = useState([])
  const authToken = localStorage.getItem("authToken")
  // const [modalOpen, setModalOpen] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
 const [currentSong, setCurrentSong] = useState(quickpicks[clickedIndex])
 const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

 const handleItemClick = (index, tracks) => {
  console.log('Clicked index:', index);
  // setCurrentSong(quickpicks[clickedIndex]);
  setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
};
  const GetQuickPicks = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/trending/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setQuickPicks(res?.data?.data);
        console.log(quickpicks + "quickpicks state===");
        console.log(JSON.stringify(res.data) + "quickpicks====");
      }).catch(e=>console.log(e));
  };

  useEffect(()=>{
    GetQuickPicks()
  },[])

  return (
    <LayoutMain>
      {/* <Header1/> */}
      <div className="bg-white pb-10 sm:mx-5 sm:pt-8">
        <div
          id="iconwrapper"
          className="hidden sm:flex mx-3 mb-5 align-middle items-center space-x-2">
          <button style={{ width: 24, height: 24 }}>
            <img src={Arrow2} />
          </button>
          <span style={{ fontSize: 20, fontWeight: "700" }} fontSize={"20px"}>
            Quick picks
          </span>
        </div>
        <header
          className="relative overflow-x-hidden h-[164px] sm:rounded-xl sm:mx-3 sm:h-[258px] sm:flex sm:justify-center sm:align-middle sm:items-center"
          // style={{ height: 164 }}
        >
          <img src={MoreBg} width={"100%"} style={{ height: "100%" }} />
          <div
            className="align-middle flex flex-col justify-center"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 2,
              backgroundColor: "rgba(79, 13, 163, 0.85)",
            }}>
            <div className="flex justify-between sticky top-0 align-middle items-center sm:hidden">
              <button
                style={{ width: 24, backgroundColor: "none", height: 24 }}>
                <img src={Arrow} width={24} height={24} />
              </button>
              <img src={Logo} width={35} height={35} />
            </div>

            {/* <div
              className="mt-[25px] w-full sm:flex sm:justify-center sm:align-middle sm:mt-[10%] sm:mb-[10%] md:mt-[20%] md:mb-[20%] lg:mt-[10%] lg:mb-[10%]"
              // style={{ marginTop: 25, width: "100%" }}
            > */}
            <div className="inline-block align-bottom mt-8">
              <div
                className="flex sm:justify-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  width: "100%",
                }}>
                <img
                  src={Music}
                  width={75}
                  height={75}
                  style={{ borderRadius: 5 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}>
                  <span
                    style={{ fontSize: 20, fontWeight: "500", color: "white" }}>
                    Quick picks
                  </span>
                  <span
                    style={{ fontSize: 14, fontWeight: "400", color: "white" }}>
                    {quickpicks.length} songs
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section style={{ marginTop: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 10,
              marginLeft: 10,
              gap: 20,
            }}>

{quickpicks?.length>0?quickpicks?.map((res, index)=>{
                  return (
                  <MoreCard onClick={()=>handleItemClick(index, quickpicks)} modalOpen={modalOpen} setModalOpen={setModalOpen} title={res.title} img={res.cover_image} artist={res.artist.artist_name} audio={res.audio_file} id={res.id}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}

            {/* <MoreCard />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} /> */}
          </div>
          {/* ad */}
          <div style={{ margin: "10px 10px" }}>
                <img
                  src={Ad}
                  alt="Advertisement"
                  style={{
                    height: "80px",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </div>
        </section>
      </div>
      {/* {modalOpen && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen && <AudioPlayer2 setModalOpen1={setModalOpen} minimized1={minimized} setMinimized1={setMinimized} modalOpen={modalOpen} tracks={quickpicks} index={clickedIndex}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}

    </LayoutMain>
  );
}
