// This file contains all API activities for commerce page
import axios from "axios";
import _2gedaservice, { setupAxios } from "..";
import { getLoginToken } from "../services/auth&poll";
const API_URL = `https://i.2geda.net/api`;

setupAxios();
// get-current-username
export const User = JSON.parse(localStorage.getItem("2gedaUserInfo"))?.user
  .username;

// get-some-user-address-details
export const Userdefaultaddress = JSON.parse(
  localStorage.getItem("2gedaUserInfo")
)?.address;

// get-username-id
export const UserID = JSON.parse(localStorage.getItem("2gedaUserInfo"))?.user
  .id;

// getuser_location
export const getUserlocation = JSON.parse(
  localStorage.getItem("2gedaUserInfo")
)?.created_at;

// reuest_to_get_all_products
export const getProduct = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/products/`);
  console.log("Commerce", response)
  return response;
};

// reuest_to_get_all_products
export const getCategories = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/categories/`);
  console.log("Category", response)
  return response;
};

// request-_2gedaservice-post-products-to-endpoint
export const Sellitem = async (requestBody) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/products/`,
    { ...requestBody },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

// reuest-_2gedaservice-post-andadd-sub-images-to-item-afterbeing-created

export const Addsubimagestoproduct = async (productid, payloadimages) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/products/${productid}/sub_images/`,
    { ...payloadimages },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

// add-an-item-to-cart
export const Addtocart = async (payloads) => {
  setupAxios();
  const response = await _2gedaservice.post(`${API_URL}/commerce/cart/`, {
    ...payloads,
  });
  return response;
};

// deletparticularitemfrom-cart
export const Deletecart = async (delid) => {
  setupAxios();
  // const response = await _2gedaservice.delete(`/commerce/cart/${delid}/`);
  const response = await _2gedaservice.delete(`/commerce/cart/${delid}/`);

  return response;
};

// get-a-particular-productinformation-by-id
export const getProductInfo = async (productid) => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/product/${productid}/`);

  return response;
};

// get-product-info-inside-store
export const getstoreproductdetail = async (productid) => {
  setupAxios();
  const response = await _2gedaservice.get(
    `/commerce/products-in-store/${productid}/`
  );

  return response;
};

// request-to-delete-a-product-from-endpoint
// this can only be performed by a user-with-a-store
export const deleteProduct = async (productid) => {
  setupAxios();
  const response = await _2gedaservice.delete(
    `/commerce/products-in-store/${productid}/`
  );

  return response;
};

export const deleteStore = async (storeid) => {
  setupAxios();
  const response = await _2gedaservice.delete(`/commerce/stores/${storeid}/`);

  return response;
};

// find-item-seller-by-the seller id
export const getSellerdetails = async (userid) => {
  setupAxios();
  const response = await _2gedaservice.get(`account/profiles/`);
  const { data } = response.data;

  if (!Array.isArray(data)) {
    throw new Error("Unexpected response structure");
  }

  let filteredDetails = null;

  for (const item of data) {
    const { stickings } = item;
    if (Array.isArray(stickings)) {
      filteredDetails = stickings.find((user) => user.id === userid);
      if (filteredDetails) break;
    }
  }

  if (!filteredDetails) {
    throw new Error(`User with id ${userid} not found`);
  }

  return filteredDetails;
};

// rate-a-product
export const rateProduct = async (payload) => {
  setupAxios();
  const response = await _2gedaservice.post(`${API_URL}/commerce/ratings/`, {
    ...payload,
  });

  return response;
};

// get-product-rating
export const getproductRating = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/ratings`);

  return response;
};

// gel-listof-cart-items
export const getCarts = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/cart`);
  return response.data;
};

// create-store-api
export const createStore = async (payload) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/stores/`,
    { ...payload },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

// get-items-in-store
export const Storeslist = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/business`);
  // const response = await _2gedaservice.get(`/commerce/stores`);
  console.log("Stored", response)
  return response;
};

// get-products in a store
export const getStoreproducs = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/products`);
  return response;
};

// review-fetch-api-for -a-product
export const getReviewcontent = async (productID) => {
  setupAxios();
  const response = await _2gedaservice.get(
    `/commerce/products/${productID}/ratings/`
  );

  return response.data;
};

// edit-store-api
export const editStore = async (patchoptions, storeid) => {
  setupAxios();
  const request = await axios.patch(
    `${API_URL}/commerce/stores/${storeid}/`,
    { ...patchoptions },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getLoginToken()}`,
      },
    }
  );

  return request;
};

// edit-product
export const editProduct = async (patchdata, pid) => {
  setupAxios();
  const request = await axios.patch(
    `${API_URL}/commerce/products-in-store/${pid}/`,
    { ...patchdata },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return request;
};

// mark-as-sold
export const markasSold = async (pid) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/products/${pid}/mark-as-sold/`,
    null
  );
  return response;
};

// fetch-aplns-for-promote-item
export const promotePlan = async () => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/plan/`);

  return response;
};

// subscription-for-promote-product-API
export const SubscribePromote = async (plan_id) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/subscriptions/`,
    {
      plan_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

// patch-request-to-update-cart-item
export const Updatecart = async (payloads, pid) => {
  setupAxios();
  const response = await axios.put(`${API_URL}/commerce/cart/${pid}`, {
    ...payloads,
  });
  console.log("updatedCart", response)
  return response.data;
};

// create-checkout-endpoint
export const createCheckout = async (payloads) => {
  setupAxios();
  const response = await _2gedaservice.post(`${API_URL}/commerce/checkout/`, {
    ...payloads,
  });
  console.log("createcheckout",response)
  return response;
};

// fetch-list-of-oreders
export const Orderlist = async () => {
  const response = await _2gedaservice.get(`/commerce/orders/`);

  return response.data;
};

// create-checkout-endpoint
export const createPurchase = async (payloads) => {
  setupAxios();
  const response = await _2gedaservice.post(`${API_URL}/commerce/purchase/`, {
    order_id: payloads,
  });
  console.log("Purchase", response)
  return response.data;
};

export const getCartinfo = async (cid) => {
  setupAxios();
  const response = await _2gedaservice.get(`/commerce/cart/${cid}`);
  return response.data;
};

// reprt-item-seller-any
export const Reportuser = async (payloads) => {
  setupAxios();
  const response = await _2gedaservice.post(
    `${API_URL}/commerce/report/`,

    {
      ...payloads,
    }
  );
  return response.data;
};
