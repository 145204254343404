/* eslint-disable eqeqeq */
import useReadChat from "Hooks/chat/useReadChat";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import SingleChat from "./SingleChat";
//import { CiFaceSmile } from "react-icons/ci";

const filterByParticipantsIDs = (array, userID, otherID) => {
  return array?.filter((item) => {
    const [firstParticipant, secondParticipant] = item.participants;
    return (
      (firstParticipant.id == userID && secondParticipant.id == otherID) ||
      (firstParticipant.id == otherID && secondParticipant.id == userID)
    );
  });
};

const InnerChat = ({ data, userID, ID }) => {
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView();
    }
  };

  const conversation = filterByParticipantsIDs(data, userID, ID);

  useEffect(() => {
    handleGetRef();
  }, [data]);

  return (
    <div className="">
      <div className="p-4 h-[400px] overflow-y-auto [overflow-anchor:auto] bg-[#ecebeb]">
        {conversation?.length
          ? conversation[0]?.messages
              ?.slice()
              .reverse()
              .map((chat) => (
                <SingleChat key={chat?.id} userID={userID} chat={chat} />
              ))
          : null}
      </div>
    </div>
  );
};

export default InnerChat;
