import { Link, useNavigate } from "react-router-dom";
import { TbDotsVertical } from "react-icons/tb";
import { FaPlay } from "react-icons/fa";
import TvOptionsModal from "./TvOptionsModal";
import { useState } from "react";
import PromoteMovieModal from "./PromoteMovieModal";
import { useTv } from "Hooks/tv/useTv";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";

function limitString(str, limit = 20) {
  if (str?.length > limit) {
    return `${str?.substring(0, limit)}...`;
  }
  return str;
}

TimeAgo.addDefaultLocale(en);

const UserMoviesCardFull = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  const navigate = useNavigate();

  const timeAgo = new TimeAgo("en-US");

  const { userTvData, userTvDataError, userTvDataStatus, isUserTvDataLoading } =
    useTv();

  // eslint-disable-next-line eqeqeq
  const isUser = userTvData?.data?.data[0]?.id == data?.channel?.id;

  return (
    <div className="flex justify-between mt-6 items-center relative">
      {isPromoteModalOpen && (
        <PromoteMovieModal
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
          data={data}
        />
      )}
      {isModalOpen && (
        <TvOptionsModal
          setIsModalOpen={setIsModalOpen}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
          movie={data}
          isUser={isUser}
        />
      )}

      <div className="flex gap-2 items-center h-[170px] p-3">
        <div className="lg:h-full lg:w-[300px] h-full w-[130px] relative">
          <img
            src={data?.cover_photo}
            alt=""
            className="w-full h-full object-cover rounded hover:text-[#4f0da3] transition duration-300"
          />
          <div
            className="bg-neutral-100 h-[40px] w-[40px] opacity-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
            onClick={() => navigate(`/tv/movie/${data?.id}`)}
          >
            <FaPlay className="text-neutral-800 text-[14px]" />
          </div>
        </div>
        <div className="flex flex-col gap-1 text-[11px] lg:text-[12px] text-neutral-600">
          <div className="font-semibold text-[15px] lg:text-[18px] ml-2">
            {limitString(data?.title)}
          </div>
          <div className="text-neutral-500 text-[11px] lg:text-[13px] ml-2">
            {timeAgo.format(moment(data?.schedule).toDate())}
          </div>
          <div className="flex items-center gap-10 mt-2 ml-1">
            <div className="flex flex-col gap-2 items-center">
              <div className="lg:text-[18px] text-[16px] font-semibold">{data?.plays}</div>
              <div className="lg:text-[12px] text-[10px]">Plays</div>
            </div>
            <div className="flex gap-2 flex-col items-center ">
              <div className="lg:text-[18px] text-[16px] font-semibold">{data?.downloads ? data?.downloads : 0}</div>
              <div className="lg:text-[12px] text-[10px]">Downloads</div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <TbDotsVertical
          className="text-[16px]"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
    </div>
  );
};

export default UserMoviesCardFull;
