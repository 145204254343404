import { Spinner } from "@nextui-org/react";
import usePromoteTicket from "Hooks/tickets/usePromoteTicket";
import { FaTimes } from "react-icons/fa";

const tags = [
  {
    id: 1,
    plan: "Basic",
    amount: 500,
    users: "1000 users",
  },
  {
    id: 2,
    plan: "Standard",
    amount: 2500,
    users: "5,000 users",
  },
  {
    id: 3,
    plan: "Premium",
    amount: 4000,
    users: "10,000 users",
  },
  {
    id: 4,
    plan: "Pro",
    amount: 7500,
    users: "50,000 users",
  },
];

const PromoteTicketModal = ({
  setIsPromoteModalOpen,
  selectedTags,
  setSelectedTags,
  data,
}) => {
  const { isPromoteTicketLoading, promoteTicketStatus, promotingTicket } =
    usePromoteTicket(data.id);

  const handleTagClick = (tag) => {
    setSelectedTags(tag);
  };

  const handleClosePromoteModal = () => {
    setSelectedTags(null);
    setIsPromoteModalOpen(false);
  };

  const handlePromoteTicket = () => {
    const res = promotingTicket(data);
    console.log(res);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full md:w-1/2 lg:w-1/2 rounded-lg shadow-lg p-8 m-4 bg-white ">
        <div className="flex justify-between items-center text-[15px] mb-4">
          <div></div>
          <FaTimes className="text-[18px]" onClick={handleClosePromoteModal} />
        </div>

        <div className="flex flex-col gap-3 items-center">
          <div className="lg:h-[100px] lg:w-[100px] h-[100px] w-[80px] relative">
            <img
              src={data?.event_image}
              alt=""
              className="w-full h-full object-cover rounded hover:text-[#4f0da3] transition duration-300"
            />
          </div>
          <span className="font-semibold text-[15px]">Promote your ticket</span>
          <span className="text-[13px] max-w-[320px] text-center">
            Promote your ticket, attract a wider audience and boost your ticket
            visibility effortlessly
          </span>
        </div>

        <div className="mt-4">
          <span className="text-[14px] font-semibold">Select a plan</span>
        </div>
        <div className="w-full grid lg:grid-cols-2 grid-cols-1 lg:text-[13px] md:text-[12px] text-[12px] gap-4 p-2 mt-2">
          {tags.map((item) => (
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "#4f0da3",
              }}
              key={item?.id}
              className={`rounded border-none p-3 cursor-pointer ${
                selectedTags?.plan === item?.plan
                  ? " text-black"
                  : "border text-black"
              }`}
              onClick={() => handleTagClick(item)}
            >
              <div className="flex justify-between items-center">
                <span>{item?.plan}</span>
                <span className="px-2 py-1 bg-[#4f0da3] text-white text-[12px] rounded ">
                  {item?.amount} Naira
                </span>
              </div>
              <div className="font-semibold">{item?.users}</div>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <button
            className="bg-[#4f0da3] text-white lg:text-[13px] text-[12px] px-8 py-3 w-full rounded"
            onClick={handleClosePromoteModal}
          >
            {promoteTicketStatus === "pending" ? (
              <Spinner />
            ) : (
              "Proceed to checkout"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoteTicketModal;
