import React, { useEffect, useRef, useState } from "react";
import CardImage from "../Assets/Image5.jpeg";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";

export default function SpecialPicksCard({ category, img, audio, id, activePlayer, setActivePlayer, onClick, setModalOpen2, modalOpen2 }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [plays, setPlays] = useState()
  // const [song] = useState(new Audio(audio))
  const audioPlayer = useRef()
  const authToken = localStorage.getItem("authToken")

  const GetPlays = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlays(res?.data?.data);
        console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e));
  };

  // const handlePlaySong = () => {
  //   GetPlays()
  //   setIsPlaying(true)
  //   song.play()
  // }
  // const handlePauseSong = () => {
  //   setIsPlaying(false)
  //   song.pause()
  // }

  // const togglePlay = () => {
  //   if (activePlayer !== null && activePlayer !== song) {
  //     activePlayer.pause();
  //     // setIsPlaying(!isPlaying)
  //   }
  //   if (isPlaying) {
  //     song.pause();
  //     // setIsPlaying(!isPlaying)
  //   } else {
  //     song.play();
  //     // setIsPlaying(!isPlaying)
  //     setActivePlayer(song);
  //   }
  //   setIsPlaying(!isPlaying);
  // };
  useEffect(()=>{
    console.log(audio, "molly===")

  },[])
  return (
    <div
    // onClick={()=>togglePlay()}
    onClick={()=>{onClick(); setModalOpen2(!modalOpen2); GetPlays()}}
      style={{
        minWidth: 111.89,
        minHeight: 115.23,
        maxWidth: 111.89,
        maxHeight: 115.23,
      }}>
      <div className="w-full relative" style={{ minHeight: 99.9, maxHeight: 99.9 }}>
        <img
          src={img ? img : CardImage}
          width={"100%"}
          style={{ minHeight: 99.9, maxHeight: 99.9 }}
          className="rounded-md"
        />
        <img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <main className="flex flex-col justify-center items-center">
        <label style={{fontSize:"10.66px", fontWeight:"400", color:"black"}}>
          {category ? category : "Unknown song"}
        </label>
        {/* <Text fontSize={"12px"} fontWeight={"400"} color={"#403F3F"}>
          {artist ? artist : "Billie Eilish"}
        </Text> */}
      </main>
    </div>
  );
}
