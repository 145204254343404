import { useEffect, useRef } from "react";
import TicketSearchComp from "../../components/TicketComp/TicketSearchComp";
import { MdCloudDone } from "react-icons/md";
import "./second.css";
import TicketActualReport from "../../components/TicketComp/TicketActualReport";
import { useState } from "react";
import { GoFilter } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";

const TicketReport = () => {
  const [isReportOpen] = useState(true);

  const navigate = useNavigate();

  const {id} = useParams()

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -10 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  /* const handleReportGet = (e) => {
    e.preventDefault();
    setIsReportOpen(true);
  };
  */

  const goToCreateEvent = () => {
    navigate("/ticket/sell-ticket/create-event-one");
  };

  const goBack = () => {
    navigate("/ticket/sell-ticket/my-events");
  };

  return (
    <div className="p-4" ref={pageRef}>
      <div ref={pageRef}>
        <TicketSearchComp
          label={"Ticket reports"}
          handleCloseContainerClick={goBack}
          add={"add"}
        />
      </div>
      <form action="">
        <div className="double-input mv-dwn">
          {/* <div className="flex items-center cursor-pointer py-2 px-4 text-neutral-400 border">
            <GoFilter className="text-[20px] text-neutral-500 rounded" />
            <select
              name=""
              id=""
              className="border-none bg-white cursor-pointer text-neutral-400 text-[14px] outline-none"
            >
              <option value="">January</option>
              <option value="">Febuary</option>
              <option value="">March</option>
            </select>
          </div> */}
        </div>
        <div className="act-btn-cont flex cent mv-bit">
          <button
            className="p-3 text-[15px] w-[300px] flex items-center gap-3 bg-[#4f0da3] text-[#fff] justify-center rounded"
            onClick={goToCreateEvent}
          >
            <MdCloudDone className="mb-1 text-[17px]" />
            Create event
          </button>
        </div>
      </form>

      {isReportOpen && <TicketActualReport id={id}/>}
    </div>
  );
};

export default TicketReport;

