import { AiOutlineClose } from 'react-icons/ai'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import './post-modal.css'
import Custombutton from 'components/Custom-button/Custombutton'
import { useForm } from 'react-hook-form'
import { useCreateJob } from 'api/services/feeds'

const PostJobModal = ({ handleCloseMainContainerClick, hdClose }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({})

  const { mutateAsync: createJobs, isPending } = useCreateJob()

  const data = [
    { label: 'Monthly', value: 'monthly', key: 2 },
    { label: 'Weekly', value: 'weekly', key: 1 },
    { label: 'Yearly', value: 'yearly', key: 3 },
  ]

  const handleCloseJob = () => {
    // hdClose()
    handleCloseMainContainerClick()
    console.log(hdClose, 'hdcloss')
    console.log(handleCloseMainContainerClick, 'hdclosslllll')
  }

  const onSubmit = async (data) => {
    try {
      console.log(data, 'data')
      console.log({ is_job_post: true, job_details: data }, 'data')
      const res = await createJobs({
        is_job_post: true,
        hashtags: [],
        tagged_users: [],
        job_details: data,
      })
      if (res?.data?.status) {
        console.log(res, 'hhh')
        // handleCloseMainContainerClick()
        // hdClose()
        handleCloseJob()
        Swal.fire({
          icon: 'success',
          title: 'Job Successful!',
          text: 'Your text content job has been successfully posted.',
          confirmButtonText: 'OK',
        }).then(() => {
          Swal.close()
        })
      }
    } catch (error) {
      // handleCloseMainContainerClick()
      // hdClose()
      handleCloseJob()
      Swal.fire({
        icon: 'error',
        title: 'An error occured',
        text: 'unable to create job at this time, please try again',
        confirmButtonText: 'OK',
      })
    }
  }

  return (
    <>
      <div className='postFormModal-container flex flex-col space-y-4  '>
        <div className='post-clos-box2 relative'>
          <div className='post-ead text-center'>Post a job</div>
          <div className='absolute top-2 right-0'>
            <AiOutlineClose
              className='cls-post'
              onClick={handleCloseMainContainerClick}
            />
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col space-y-4'
        >
          <div className=''>
            <input
              type='text'
              placeholder='Job Title'
              {...register('job_title')}
              class='w-full rounded-lg bg-transparent  outline-none text-[13px] text-gray-700 font-light placeholder:text-[13px] placeholder:text-gray-700 placeholder:font-light'
            />
          </div>
          <div className=''>
            <input
              type='text'
              placeholder='Company Name'
              {...register('company_name')}
              class='w-full rounded-lg bg-transparent  outline-none text-[13px] text-gray-700 font-light placeholder:text-[13px] placeholder:text-gray-700 placeholder:font-light'
            />
          </div>
          <div className=''>
            <input
              type='text'
              placeholder='Location'
              {...register('location')}
              class='w-full rounded-lg bg-transparent  outline-none text-[13px] text-gray-700 font-light placeholder:text-[13px] placeholder:text-gray-700 placeholder:font-light'
            />
          </div>
          <div className='grid md:grid-cols-3 gap-4'>
            <input
              type='text'
              placeholder='enter Min Salary'
              {...register('min_salary')}
              class='w-full rounded-lg bg-transparent  outline-none text-[13px] text-gray-700 font-light placeholder:text-[13px] placeholder:text-gray-700 placeholder:font-light'
            />
            <input
              type='text'
              placeholder='enter Max Salary'
              {...register('max_salary')}
              class='w-full rounded-lg bg-transparent  outline-none text-[13px] text-gray-700 font-light placeholder:text-[13px] placeholder:text-gray-700 placeholder:font-light'
            />
            <div className='relative'>
              <select
                className='px-4 py-4  text-gray-700  focus:outline-none'
                name='period'
                id='period'
                {...register('period')}
              >
                {data?.map((item) => (
                  <option
                    className='text-gray-700 bg-white'
                    key={item?.label}
                    value={item.key}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path
                    fillRule='evenodd'
                    d='M7.293 8.707a1 1 0 011.414 0L10 10.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414zM7 7a1 1 0 100-2 1 1 0 000 2z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
            </div>
          </div>

          <textarea
            name='job'
            id=''
            placeholder='Job description'
            className='text-area min-h-60'
            // onChange={(event) => setContentText(event.target.value)}
            {...register('job_description')}
          />
          <div className='down-post-feed w-full'>
            <Custombutton
              className='postbtn bg-[#4f0da3] text-[16px] text-white py-3 rounded-md w-full'
              type='submit'
              // onClick={handlePost}
              name={isPending ? 'Posting' : 'Post Job'}
              disabled={isPending}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default PostJobModal
