export const EmojiHug = () => {
    return (
			<>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.5467 19.8072C5.976 19.8072 1.04492 16.8001 1.04492 10.2015C1.04492 3.60288 5.976 0.595703 10.5467 0.595703C13.0859 0.595703 15.4286 1.47208 17.1651 3.07018C19.0491 4.82293 20.0484 7.2974 20.0484 10.2015C20.0484 13.1055 19.0491 15.5628 17.1651 17.3156C15.4286 18.9137 13.0696 19.8072 10.5467 19.8072Z"
						fill="url(#paint0_radial_237_14630)"
					/>
					<path
						d="M10.5467 19.8072C5.976 19.8072 1.04492 16.8001 1.04492 10.2015C1.04492 3.60288 5.976 0.595703 10.5467 0.595703C13.0859 0.595703 15.4286 1.47208 17.1651 3.07018C19.0491 4.82293 20.0484 7.2974 20.0484 10.2015C20.0484 13.1055 19.0491 15.5628 17.1651 17.3156C15.4286 18.9137 13.0696 19.8072 10.5467 19.8072Z"
						fill="url(#paint1_linear_237_14630)"
					/>
					<path
						d="M18.3923 4.49121C19.2655 5.96902 19.7209 7.72865 19.7209 9.68589C19.7209 12.59 18.7216 15.0472 16.8376 16.8C15.1011 18.3981 12.7421 19.2917 10.2192 19.2917C7.26053 19.2917 4.15116 18.0286 2.32617 15.3497C4.08891 18.3843 7.40306 19.8072 10.5468 19.8072C13.0697 19.8072 15.4288 18.9136 17.1653 17.3155C19.0493 15.5628 20.0486 13.1055 20.0486 10.2014C20.0486 8.01218 19.4801 6.06697 18.3923 4.49121Z"
						fill="#EB8F00"
					/>
					<path
						opacity="0.8"
						d="M4.99259 13.2084C6.46736 13.2084 7.6629 11.9543 7.6629 10.4074C7.6629 8.86048 6.46736 7.60645 4.99259 7.60645C3.51781 7.60645 2.32227 8.86048 2.32227 10.4074C2.32227 11.9543 3.51781 13.2084 4.99259 13.2084Z"
						fill="url(#paint2_radial_237_14630)"
					/>
					<path
						opacity="0.8"
						d="M16.0004 11.0433C17.4752 11.0433 18.6707 9.7893 18.6707 8.24237C18.6707 6.69544 17.4752 5.44141 16.0004 5.44141C14.5256 5.44141 13.3301 6.69544 13.3301 8.24237C13.3301 9.7893 14.5256 11.0433 16.0004 11.0433Z"
						fill="url(#paint3_radial_237_14630)"
					/>
					<path
						d="M16.0014 7.88146C14.7399 9.10152 13.0689 10.0638 11.1358 10.5278C9.2027 10.9917 7.30235 10.9058 5.66411 10.3731C5.36923 10.27 5.15626 10.6824 5.41838 10.8886C7.073 12.1774 9.28461 12.7273 11.4962 12.1774C13.7078 11.6447 15.4771 10.1497 16.4273 8.22514C16.5911 7.93302 16.2307 7.65807 16.0014 7.88146Z"
						fill="#422B0D"
					/>
					<path
						d="M8.07366 7.50409C8.07366 7.50409 8.05728 7.4869 8.04089 7.46972C8.02451 7.45254 7.99175 7.43535 7.95898 7.40098C7.92622 7.3838 7.89345 7.34943 7.86069 7.33225C7.82792 7.29788 7.77878 7.26351 7.72963 7.22914C7.68048 7.19478 7.63134 7.16041 7.58219 7.14323C7.53304 7.10886 7.4839 7.09167 7.43475 7.09167C7.3856 7.07449 7.35284 7.07449 7.33646 7.07449C7.32007 7.07449 7.32007 7.07449 7.30369 7.07449C7.30369 7.07449 7.30369 7.07449 7.28731 7.07449H7.32007L7.23816 7.09167C7.23816 7.09167 7.23816 7.09167 7.25454 7.09167H7.23816C7.22178 7.10886 7.18901 7.12604 7.15625 7.14323C7.12349 7.17759 7.07434 7.21196 7.04157 7.24633C7.00881 7.2807 6.97604 7.33225 6.94328 7.3838C6.87775 7.4869 6.8286 7.57282 6.79584 7.64156C6.76307 7.71029 6.73031 7.74466 6.73031 7.74466L6.69754 7.8134C6.53372 8.10552 6.17331 8.22581 5.87843 8.07115C5.68184 7.96805 5.56716 7.77903 5.55078 7.57282C5.55078 7.57282 5.55078 7.50409 5.55078 7.3838C5.56716 7.26351 5.58355 7.09167 5.64908 6.88547C5.7146 6.67926 5.82928 6.4215 6.04225 6.18093C6.15693 6.06064 6.28799 5.92317 6.45181 5.83725C6.48457 5.80289 6.53372 5.7857 6.58287 5.76852C6.63202 5.75133 6.66478 5.71697 6.73031 5.69978L6.81222 5.66542C6.84499 5.64823 6.87775 5.64823 6.89413 5.64823L6.97604 5.63105L7.02519 5.61386H7.05796H7.09072L7.17263 5.59668H7.32007C7.41837 5.59668 7.51666 5.59668 7.61495 5.61386C7.81154 5.64823 7.97537 5.71697 8.12281 5.7857C8.41769 5.94036 8.59789 6.12938 8.74533 6.28403C8.81086 6.36995 8.87639 6.43869 8.90916 6.50742C8.95831 6.57616 8.99107 6.64489 9.02383 6.69645C9.0566 6.748 9.0566 6.78236 9.07298 6.79955C9.07298 6.81673 9.08936 6.83392 9.08936 6.83392C9.20404 7.14323 9.04022 7.4869 8.71257 7.60719C8.48322 7.69311 8.23748 7.64156 8.07366 7.50409Z"
						fill="#422B0D"
					/>
					<path
						d="M13.6908 6.35232C13.6908 6.35232 13.6745 6.33514 13.6581 6.31796C13.6417 6.30077 13.6089 6.28359 13.5762 6.24922C13.5434 6.23204 13.5106 6.19767 13.4779 6.18049C13.4451 6.14612 13.396 6.11175 13.3468 6.07738C13.2977 6.04302 13.2485 6.00865 13.1994 5.99146C13.1502 5.9571 13.1011 5.93991 13.0519 5.93991C13.0192 5.92273 12.9864 5.92273 12.97 5.92273C12.9536 5.92273 12.9536 5.92273 12.9373 5.92273H12.9209H12.9536L12.8717 5.93991C12.8553 5.9571 12.8226 5.97428 12.7734 5.99146C12.7407 6.02583 12.7079 6.0602 12.6588 6.09457C12.626 6.12893 12.5932 6.18049 12.5605 6.23204C12.4949 6.33514 12.4458 6.42106 12.413 6.4898C12.3803 6.55853 12.3475 6.5929 12.3475 6.5929L12.3147 6.64445C12.1509 6.93658 11.7905 7.05686 11.4956 6.90221C11.299 6.7991 11.1844 6.61008 11.168 6.40388C11.168 6.40388 11.168 6.33514 11.1844 6.21485C11.2007 6.09457 11.2171 5.92273 11.2826 5.71652C11.3482 5.51032 11.4629 5.25256 11.6758 5.01199C11.7905 4.8917 11.9216 4.75423 12.0854 4.66831C12.1181 4.65112 12.1673 4.61676 12.2164 4.59957C12.2656 4.58239 12.2983 4.54802 12.3639 4.53084L12.4622 4.49647C12.4949 4.47929 12.5277 4.47929 12.5441 4.47929L12.626 4.4621L12.6751 4.44492H12.7079H12.7407L12.8226 4.42773H12.97C13.0683 4.42773 13.1666 4.42773 13.2649 4.44492C13.4615 4.47929 13.6253 4.54802 13.7728 4.61676C14.0676 4.77141 14.2478 4.96043 14.3953 5.11509C14.4608 5.20101 14.5263 5.26974 14.5591 5.33848C14.6083 5.40721 14.641 5.47595 14.6738 5.5275C14.7066 5.57905 14.7066 5.61342 14.7229 5.6306C14.7393 5.64779 14.7393 5.66497 14.7393 5.66497C14.854 5.97428 14.6902 6.31796 14.3625 6.43824C14.1004 6.55853 13.8547 6.50698 13.6908 6.35232Z"
						fill="#422B0D"
					/>
					<path
						opacity="0.66"
						d="M6.49483 13.9061C6.31626 13.3769 6.18848 18.8585 6.18848 18.8585C6.66684 19.0699 7.15012 19.2554 7.65142 19.3964C7.84965 18.9272 8.10849 18.0629 7.97251 17.0834C7.75299 15.518 6.86179 14.9973 6.49483 13.9061Z"
						fill="url(#paint4_linear_237_14630)"
					/>
					<path
						d="M6.59798 13.3516C6.5177 13.123 6.41613 12.9529 6.15893 12.9529C6.04098 12.9529 5.84111 12.9838 5.73463 13.154C5.52493 13.4839 5.60684 14.1025 5.76739 14.6404C5.85749 14.9445 5.83292 15.0562 5.83292 15.0562C5.38732 15.027 4.86964 14.3895 4.3536 13.7537C4.03742 13.3636 3.85393 13.0045 3.54595 12.6058C3.35919 12.3635 3.0856 12.1195 2.76451 12.2432C1.88478 12.5852 2.97257 13.9135 3.24451 14.2778C3.55414 14.6919 3.86049 15.0889 3.86049 15.0889C3.57216 15.0184 3.39031 14.9376 2.88246 14.3912C2.44833 13.9238 2.1256 13.3206 1.88969 13.0955C1.35399 12.5852 1.02634 12.7519 0.883818 13.0921C0.751121 13.4083 0.970644 13.9668 1.11972 14.2279C1.85857 15.5133 2.80219 16.0168 2.80219 16.0168C2.02567 15.9807 1.41297 15.1078 1.16559 14.8431C0.448048 14.0784 0.120401 15.0253 0.511939 15.7109C0.692144 16.0254 0.87235 16.247 1.20163 16.6028C1.73406 17.175 2.23536 17.4345 2.23536 17.4345C2.23536 17.4345 2.02075 17.4688 1.8045 17.3502C1.5129 17.1904 1.18689 16.9499 1.01815 16.8124C0.534874 16.4223 0.0745305 16.7574 0.259651 17.2764C0.474259 17.8761 1.25733 18.3847 1.73078 18.7542C2.65147 19.469 3.79987 20.0412 4.96302 20.0017C6.25722 19.9588 7.14842 19.0257 7.499 17.7145C7.70214 16.9516 7.21723 15.7384 7.09436 15.4325C6.68972 14.4427 6.67825 13.5801 6.59798 13.3516Z"
						fill="url(#paint5_radial_237_14630)"
					/>
					<path
						d="M3.99657 16.4187C4.01951 17.8587 4.88122 18.4602 5.54962 18.5925C5.28095 18.1818 5.1417 17.68 5.15808 17.1834C5.17446 16.6851 5.35795 16.0648 5.62989 15.728C5.90184 15.3911 5.83303 15.0595 5.83303 15.0595C5.83303 15.0595 5.64955 15.044 5.58075 15.0011C5.36122 14.8602 5.10893 14.7468 4.9189 14.5629C4.77965 14.4271 3.97364 14.9787 3.99657 16.4187Z"
						fill="url(#paint6_radial_237_14630)"
					/>
					<path
						d="M1.17577 12.8887C1.04143 12.9797 0.967714 13.1361 0.966076 13.2925C0.9628 13.4489 1.00703 13.6052 1.06109 13.753C1.17577 14.0503 1.35598 14.3166 1.5411 14.5727C1.73113 14.827 1.93591 15.0727 2.14888 15.3099C2.36185 15.5487 2.57973 15.7842 2.8009 16.0196C2.52731 15.8512 2.27339 15.6484 2.03912 15.425C1.80649 15.1999 1.58533 14.9559 1.39857 14.6844C1.2069 14.4146 1.05454 14.1139 0.954609 13.7908C0.908738 13.6293 0.875973 13.4557 0.903823 13.2839C0.923482 13.1138 1.02833 12.9505 1.17577 12.8887Z"
						fill="url(#paint7_linear_237_14630)"
					/>
					<path
						d="M0.216797 17.0808C0.223808 17.0153 0.233156 16.9592 0.244841 16.9102C0.228482 16.9639 0.219134 17.02 0.216797 17.0808Z"
						fill="url(#paint8_radial_237_14630)"
					/>
					<path
						d="M0.832924 17.7417C0.714972 17.6231 0.634698 17.5234 0.574084 17.4186C0.505278 17.2983 0.46596 17.1712 0.462684 17.0526C0.459407 16.9203 0.470875 16.8223 0.590466 16.7261C0.649442 16.678 0.814904 16.6952 0.811627 16.6935C0.809989 16.6917 0.806713 16.6917 0.805075 16.69C0.705142 16.6488 0.611763 16.6385 0.528213 16.6522C0.528213 16.6522 0.528213 16.6522 0.529851 16.6505L0.528213 16.6522C0.387325 16.6763 0.279202 16.7708 0.236608 16.91C0.228417 16.9461 0.221864 16.9873 0.216949 17.0354C0.215311 17.1093 0.226778 17.1918 0.257905 17.2794C0.305413 17.4117 0.38241 17.5406 0.47579 17.6644C0.53149 17.7537 0.597019 17.8379 0.657634 17.9187C0.754289 18.039 0.852583 18.1575 0.962345 18.2641C1.17695 18.484 1.4145 18.6765 1.66678 18.8449C1.78965 18.9239 3.00522 19.7058 4.07007 19.9206C4.07335 19.9172 2.0403 19.0373 0.832924 17.7417Z"
						fill="url(#paint9_linear_237_14630)"
					/>
					<path
						d="M2.29853 17.5271C2.28706 17.5271 2.27395 17.5254 2.26412 17.5202C2.00528 17.4068 1.74808 17.2453 1.47613 17.0253C1.22712 16.8174 1.00596 16.5906 0.819203 16.3534C0.714356 16.2263 0.620977 16.0871 0.545618 15.9685L0.522683 15.9307C0.445686 15.8053 0.36705 15.6764 0.317903 15.5252C0.254012 15.3465 0.23763 15.1626 0.273671 14.9925C0.314627 14.788 0.426027 14.6488 0.581659 14.5783C0.804459 14.477 1.05347 14.7347 1.03873 14.7278C0.866711 14.6471 0.715994 14.6918 0.676676 14.7192C0.586574 14.7845 0.529235 14.898 0.517768 15.0303C0.509577 15.1488 0.535788 15.2794 0.593127 15.4066C0.642274 15.5183 0.711079 15.6266 0.817564 15.7572C0.909306 15.8809 1.00432 15.9943 1.0977 16.106C1.29429 16.338 1.49743 16.5545 1.70057 16.7538C1.87586 16.9239 2.10849 17.1456 2.35914 17.3553C2.39518 17.3845 2.40501 17.4377 2.38044 17.479C2.36078 17.5099 2.33129 17.5271 2.29853 17.5271Z"
						fill="url(#paint10_linear_237_14630)"
					/>
					<path
						d="M2.87704 16.0459C2.90817 16.0098 2.90161 15.9875 2.86885 15.9531C2.62475 15.6936 2.41178 15.4616 2.21847 15.2451C1.99403 14.9959 1.79744 14.7571 1.61724 14.5165C1.43867 14.2708 1.25846 14.0062 1.14707 13.7192C1.10447 13.6023 1.05532 13.4477 1.0586 13.2947C1.0586 13.226 1.07662 13.1624 1.10611 13.1057C1.13396 13.0525 1.17492 13.0061 1.2257 12.9717C1.22734 12.9717 1.22734 12.97 1.22734 12.97C1.31089 12.9167 1.44686 12.8772 1.66475 12.9167C1.28304 12.6469 0.996348 12.7999 0.875119 13.0903C0.742422 13.4064 0.968498 13.9718 1.11922 14.233C1.85806 15.5183 2.76072 16.0321 2.76072 16.0321C2.80496 16.0613 2.8541 16.0699 2.87704 16.0459Z"
						fill="url(#paint11_linear_237_14630)"
					/>
					<path
						d="M3.98374 15.1352C3.70688 14.7503 2.59615 13.5285 2.624 12.7295C2.63219 12.4734 3.02209 12.2105 3.41199 12.4494C3.41691 12.4528 3.12694 12.1057 2.76325 12.2415C2.44707 12.3583 2.4012 12.6642 2.39793 12.7192C2.38154 12.8927 2.42742 13.0594 2.47165 13.1866C2.56339 13.4272 2.6797 13.6609 2.8468 13.9409C2.97131 14.154 3.11711 14.3705 3.30387 14.6266C3.40708 14.7658 3.51193 14.8929 3.61841 15.0132C3.69049 15.0957 3.76421 15.1747 3.83957 15.2521C3.85759 15.2693 3.88053 15.2796 3.90346 15.2796C3.92312 15.2796 3.94278 15.2727 3.95916 15.2607C3.99357 15.2297 4.00995 15.173 3.98374 15.1352Z"
						fill="url(#paint12_linear_237_14630)"
					/>
					<path
						d="M6.15961 12.9541C6.04166 12.9541 5.84179 12.985 5.73531 13.1552C5.52561 13.4851 5.60753 14.1037 5.76807 14.6416C5.79428 14.7292 5.81067 14.7996 5.8205 14.8581C5.84015 14.9646 5.74186 15.0505 5.64684 15.0127C5.24548 14.8546 4.79988 14.303 4.35428 13.7549C4.33134 13.7257 4.31004 13.6982 4.28711 13.669C4.29202 13.748 4.54759 14.1776 4.89653 14.6398C4.98991 14.7653 5.6108 15.475 5.94173 15.2722C6.1465 15.1485 5.8123 14.1862 5.80903 13.937C5.80739 13.8013 5.78937 13.626 5.83033 13.4645C5.83852 13.4232 5.86309 13.3803 5.89422 13.3373C6.07278 13.0864 6.44302 13.0761 6.59538 13.3459L6.60029 13.3528C6.51838 13.1225 6.41681 12.9541 6.15961 12.9541Z"
						fill="url(#paint13_linear_237_14630)"
					/>
					<path
						opacity="0.66"
						d="M14.5947 13.9061C14.7732 13.3769 14.901 18.8585 14.901 18.8585C14.4227 19.0699 13.9394 19.2554 13.4381 19.3964C13.2399 18.9272 12.981 18.0629 13.117 17.0834C13.3365 15.518 14.2261 14.9973 14.5947 13.9061Z"
						fill="url(#paint14_linear_237_14630)"
					/>
					<path
						d="M14.4912 13.3516C14.5714 13.123 14.673 12.9529 14.9302 12.9529C15.0481 12.9529 15.248 12.9838 15.3545 13.154C15.5642 13.4839 15.4823 14.1025 15.3217 14.6404C15.2316 14.9445 15.2562 15.0562 15.2562 15.0562C15.7018 15.027 16.2195 14.3895 16.7355 13.7537C17.0517 13.3636 17.2352 13.0045 17.5432 12.6058C17.7299 12.3635 18.0035 12.1195 18.3246 12.2432C19.2043 12.5852 18.1166 13.9135 17.8446 14.2778C17.535 14.6919 17.2286 15.0889 17.2286 15.0889C17.517 15.0184 17.6988 14.9376 18.2067 14.3912C18.6408 13.9238 18.9635 13.3206 19.1994 13.0955C19.7351 12.5852 20.0628 12.7519 20.2053 13.0921C20.338 13.4083 20.1185 13.9668 19.9694 14.2279C19.2306 15.5133 18.2869 16.0168 18.2869 16.0168C19.0635 15.9807 19.6762 15.1078 19.9235 14.8431C20.6411 14.0784 20.9687 15.0253 20.5772 15.7109C20.397 16.0254 20.2168 16.247 19.8875 16.6028C19.3551 17.175 18.8538 17.4345 18.8538 17.4345C18.8538 17.4345 19.0684 17.4688 19.2846 17.3502C19.5762 17.1904 19.9022 16.9499 20.071 16.8124C20.5543 16.4223 21.0146 16.7574 20.8295 17.2764C20.6149 17.8761 19.8318 18.3847 19.3583 18.7542C18.4377 19.469 17.2893 20.0412 16.1261 20.0017C14.8319 19.9588 13.9407 19.0257 13.5901 17.7145C13.387 16.9516 13.8719 15.7384 13.9948 15.4325C14.3978 14.4427 14.4109 13.5801 14.4912 13.3516Z"
						fill="url(#paint15_radial_237_14630)"
					/>
					<path
						d="M17.0923 16.4187C17.0693 17.8587 16.2076 18.4602 15.5392 18.5925C15.8079 18.1818 15.9471 17.68 15.9308 17.1834C15.9144 16.6851 15.7309 16.0648 15.459 15.728C15.187 15.3911 15.2558 15.0595 15.2558 15.0595C15.2558 15.0595 15.4393 15.044 15.5081 15.0011C15.7276 14.8602 15.9799 14.7468 16.1699 14.5629C16.3076 14.4271 17.1152 14.9787 17.0923 16.4187Z"
						fill="url(#paint16_radial_237_14630)"
					/>
					<path
						d="M19.9116 12.8887C20.0459 12.9797 20.1196 13.1361 20.1213 13.2925C20.1262 13.4506 20.082 13.6052 20.0279 13.753C19.9132 14.0503 19.733 14.3166 19.5479 14.5727C19.3579 14.827 19.1531 15.0727 18.9401 15.3099C18.7271 15.5487 18.5092 15.7842 18.2881 16.0196C18.5617 15.8512 18.8156 15.6484 19.0499 15.425C19.2825 15.1999 19.5037 14.9559 19.6904 14.6844C19.8821 14.4146 20.0344 14.1139 20.1344 13.7908C20.1802 13.6293 20.213 13.4557 20.1852 13.2839C20.1639 13.1138 20.0607 12.9505 19.9116 12.8887Z"
						fill="url(#paint17_linear_237_14630)"
					/>
					<path
						d="M20.8806 17.0808C20.8736 17.0153 20.8642 16.9592 20.8525 16.9102C20.8666 16.9639 20.8782 17.02 20.8806 17.0808Z"
						fill="url(#paint18_radial_237_14630)"
					/>
					<path
						d="M20.2547 17.7417C20.3727 17.6231 20.453 17.5234 20.5136 17.4186C20.5824 17.2983 20.6217 17.1712 20.625 17.0526C20.6282 16.9203 20.6168 16.8223 20.4972 16.7261C20.4382 16.678 20.2727 16.6952 20.276 16.6935C20.2777 16.6917 20.2809 16.6917 20.2826 16.69C20.3825 16.6488 20.4759 16.6385 20.5594 16.6522C20.5594 16.6522 20.5594 16.6522 20.5578 16.6505L20.5594 16.6522C20.7003 16.6763 20.8085 16.7708 20.851 16.91C20.8592 16.9461 20.8658 16.9873 20.8707 17.0354C20.8723 17.1093 20.8609 17.1918 20.8297 17.2794C20.7822 17.4117 20.7052 17.5406 20.6119 17.6644C20.5562 17.7537 20.4906 17.8379 20.43 17.9187C20.3334 18.039 20.2351 18.1575 20.1253 18.2641C19.9107 18.484 19.6732 18.6765 19.4209 18.8449C19.298 18.9239 18.0824 19.7058 17.0176 19.9206C17.0159 19.9172 19.049 19.0373 20.2547 17.7417Z"
						fill="url(#paint19_linear_237_14630)"
					/>
					<path
						d="M18.7891 17.5271C18.8006 17.5271 18.8137 17.5254 18.8236 17.5202C19.0824 17.4068 19.3396 17.2453 19.6115 17.0253C19.8606 16.8174 20.0817 16.5906 20.2685 16.3534C20.3733 16.2263 20.4667 16.0871 20.5421 15.9685L20.565 15.9307C20.642 15.8053 20.7206 15.6764 20.7698 15.5252C20.8337 15.3465 20.85 15.1626 20.814 14.9925C20.773 14.788 20.6617 14.6488 20.506 14.5783C20.2832 14.477 20.0342 14.7347 20.049 14.7278C20.221 14.6471 20.3717 14.6918 20.411 14.7192C20.5011 14.7845 20.5601 14.898 20.5715 15.0303C20.5797 15.1488 20.5535 15.2794 20.4962 15.4066C20.447 15.5183 20.3782 15.6266 20.2718 15.7572C20.18 15.8809 20.085 15.9943 19.9916 16.106C19.795 16.338 19.5919 16.5545 19.3887 16.7538C19.2135 16.9239 18.9808 17.1456 18.7302 17.3553C18.6941 17.3845 18.6843 17.4377 18.7089 17.479C18.7269 17.5099 18.758 17.5271 18.7891 17.5271Z"
						fill="url(#paint20_linear_237_14630)"
					/>
					<path
						d="M18.2126 16.0461C18.1814 16.01 18.188 15.9877 18.2208 15.9533C18.4649 15.6938 18.6778 15.4619 18.8711 15.2454C19.0956 14.9962 19.2922 14.7573 19.4724 14.5168C19.6493 14.2727 19.8295 14.0081 19.9409 13.7211C19.9835 13.6043 20.0326 13.4496 20.0294 13.2967C20.0294 13.228 20.0113 13.1644 19.9819 13.1077C19.954 13.0544 19.913 13.008 19.8623 12.9736C19.8606 12.9736 19.8606 12.9719 19.8606 12.9719C19.7771 12.9187 19.6411 12.8791 19.4232 12.9187C19.8049 12.6489 20.0916 12.8018 20.2128 13.0922C20.3455 13.4084 20.1195 13.9737 19.9687 14.2349C19.2299 15.5203 18.3272 16.0341 18.3272 16.0341C18.2846 16.0616 18.2339 16.0702 18.2126 16.0461Z"
						fill="url(#paint21_linear_237_14630)"
					/>
					<path
						d="M17.1058 15.1352C17.3827 14.7503 18.4934 13.5285 18.4656 12.7295C18.4574 12.4734 18.0675 12.2105 17.6776 12.4494C17.6727 12.4528 17.9626 12.1057 18.3263 12.2415C18.6425 12.3583 18.6884 12.6642 18.6916 12.7192C18.708 12.8927 18.6622 13.0594 18.6179 13.1866C18.5262 13.4272 18.4099 13.6609 18.2428 13.9409C18.1183 14.154 17.9725 14.3705 17.7857 14.6266C17.6825 14.7658 17.5776 14.8929 17.4712 15.0132C17.3991 15.0957 17.3254 15.1747 17.25 15.2521C17.232 15.2693 17.209 15.2796 17.1861 15.2796C17.1665 15.2796 17.1468 15.2727 17.1304 15.2607C17.0944 15.2297 17.078 15.173 17.1058 15.1352Z"
						fill="url(#paint22_linear_237_14630)"
					/>
					<path
						d="M14.9309 12.9541C15.0489 12.9541 15.2487 12.985 15.3552 13.1552C15.5649 13.4851 15.483 14.1037 15.3225 14.6416C15.2962 14.7292 15.2799 14.7996 15.27 14.8581C15.2504 14.9646 15.3487 15.0505 15.4437 15.0127C15.8451 14.8546 16.2907 14.303 16.7363 13.7549C16.7592 13.7257 16.7805 13.6982 16.8034 13.669C16.7985 13.748 16.5429 14.1776 16.194 14.6398C16.1006 14.7653 15.4797 15.475 15.1488 15.2722C14.944 15.1485 15.2782 14.1862 15.2815 13.937C15.2831 13.8013 15.3012 13.626 15.2602 13.4645C15.252 13.4232 15.2274 13.3803 15.1963 13.3373C15.0177 13.0864 14.6475 13.0761 14.4951 13.3459L14.4902 13.3528C14.5721 13.1225 14.6721 12.9541 14.9309 12.9541Z"
						fill="url(#paint23_linear_237_14630)"
					/>
					<defs>
						<radialGradient
							id="paint0_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(10.5467 10.2015) scale(9.33133 9.78786)"
						>
							<stop offset="0.5" stop-color="#FDE030" />
							<stop offset="0.9188" stop-color="#F7C02B" />
							<stop offset="1" stop-color="#F4A223" />
						</radialGradient>
						<linearGradient
							id="paint1_linear_237_14630"
							x1="10.5467"
							y1="19.8072"
							x2="10.5467"
							y2="0.595703"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1579" stop-color="#F4A223" />
							<stop offset="0.333" stop-color="#F7C02B" />
							<stop offset="0.8071" stop-color="#FDE030" stop-opacity="0" />
						</linearGradient>
						<radialGradient
							id="paint2_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(4.98694 10.4085) rotate(-11.1187) scale(2.91068 2.89066)"
						>
							<stop stop-color="#ED7770" />
							<stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
						</radialGradient>
						<radialGradient
							id="paint3_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(16.0015 8.24828) rotate(-11.1187) scale(2.91037 2.89036)"
						>
							<stop stop-color="#ED7770" />
							<stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
						</radialGradient>
						<linearGradient
							id="paint4_linear_237_14630"
							x1="7.09863"
							y1="21.4204"
							x2="7.09863"
							y2="16.1119"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#BF360C" />
							<stop offset="1" stop-color="#BF360C" stop-opacity="0.2" />
						</linearGradient>
						<radialGradient
							id="paint5_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(4.90822 15.5229) scale(6.37335 6.68517)"
						>
							<stop offset="0.3302" stop-color="#FFF176" />
							<stop offset="1" stop-color="#FFC400" />
						</radialGradient>
						<radialGradient
							id="paint6_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(6.15491 16.9977) rotate(-43.6536) scale(1.96443 1.97374)"
						>
							<stop stop-color="#FFC709" />
							<stop offset="1" stop-color="#FFC709" stop-opacity="0" />
						</radialGradient>
						<linearGradient
							id="paint7_linear_237_14630"
							x1="3.16082"
							y1="15.761"
							x2="0.984322"
							y2="13.7204"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#B7537A" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<radialGradient
							id="paint8_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(6.90892 15.0232) scale(9.09183 9.09178)"
						>
							<stop offset="0.3302" stop-color="#FFF176" />
							<stop offset="1" stop-color="#FFC400" />
						</radialGradient>
						<linearGradient
							id="paint9_linear_237_14630"
							x1="0.216867"
							y1="18.2838"
							x2="4.07068"
							y2="18.2838"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint10_linear_237_14630"
							x1="0.255438"
							y1="16.0401"
							x2="2.3912"
							y2="16.0401"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint11_linear_237_14630"
							x1="0.795566"
							y1="14.4174"
							x2="2.89693"
							y2="14.4174"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint12_linear_237_14630"
							x1="2.35302"
							y1="13.7438"
							x2="3.99679"
							y2="13.7438"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint13_linear_237_14630"
							x1="4.28775"
							y1="14.13"
							x2="6.77873"
							y2="14.13"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FFC400" />
							<stop offset="0.8727" stop-color="#DA6727" />
						</linearGradient>
						<linearGradient
							id="paint14_linear_237_14630"
							x1="13.9904"
							y1="21.4204"
							x2="13.9904"
							y2="16.1119"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#BF360C" />
							<stop offset="1" stop-color="#BF360C" stop-opacity="0.2" />
						</linearGradient>
						<radialGradient
							id="paint15_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(16.1804 15.5229) rotate(180) scale(6.37335 6.68517)"
						>
							<stop offset="0.3302" stop-color="#FFF176" />
							<stop offset="1" stop-color="#FFC400" />
						</radialGradient>
						<radialGradient
							id="paint16_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(14.9393 17.0068) rotate(-136.346) scale(1.96443 1.97374)"
						>
							<stop stop-color="#FFC709" />
							<stop offset="1" stop-color="#FFC709" stop-opacity="0" />
						</radialGradient>
						<linearGradient
							id="paint17_linear_237_14630"
							x1="17.9277"
							y1="15.761"
							x2="20.1042"
							y2="13.7204"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#B7537A" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<radialGradient
							id="paint18_radial_237_14630"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(14.1881 15.0232) rotate(180) scale(9.09146 9.09178)"
						>
							<stop offset="0.3302" stop-color="#FFF176" />
							<stop offset="1" stop-color="#FFC400" />
						</radialGradient>
						<linearGradient
							id="paint19_linear_237_14630"
							x1="20.8719"
							y1="18.2838"
							x2="17.0181"
							y2="18.2838"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint20_linear_237_14630"
							x1="20.8334"
							y1="16.0401"
							x2="18.6976"
							y2="16.0401"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint21_linear_237_14630"
							x1="20.2936"
							y1="14.4176"
							x2="18.1922"
							y2="14.4176"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint22_linear_237_14630"
							x1="18.7361"
							y1="13.7438"
							x2="17.0923"
							y2="13.7438"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.1273" stop-color="#DA6727" />
							<stop offset="1" stop-color="#FFC400" />
						</linearGradient>
						<linearGradient
							id="paint23_linear_237_14630"
							x1="16.8023"
							y1="14.13"
							x2="14.3113"
							y2="14.13"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FFC400" />
							<stop offset="0.8727" stop-color="#DA6727" />
						</linearGradient>
					</defs>
				</svg>
			</>
		);
}
