import React from "react";
import CardImage from "../Assets/Image5.jpeg";
import Indicator from "../Assets/audioIndicator.svg"

export default function NewCard({ category, img, artist, onClick, modalOpen, setModalOpen }) {
  return (
    <div onClick={()=>{onClick(); setModalOpen(!modalOpen)}} className="min-w-[168px] max-w-[168px] max-h-[192px]">
      <div className="w-full max-h-[150px] min-h-[150px] relative">
        <img
          src={img ? img : CardImage}
          width={"100%"}
          //   style={{ minHeight: 99.9, maxHeight: 99.9 }}
          className="rounded-md max-h-[150px] min-h-[150px]"
        />
        <img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <div className="flex flex-col justify-center items-center align-middle">
        <span className="text-base font-normal">
          {category ? category : "UNKNOWN SONG"}
        </span><br/>
        <span className="font-normal text-xs text-[#403F3F]">
          {artist ? artist : "UNKNOWN ARTIST"}
        </span>
      </div>
    </div>
  );
}
