/* eslint-disable eqeqeq */
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { PiMegaphoneBold } from "react-icons/pi";
import { PiMegaphoneLight } from "react-icons/pi";
import { GoCheck } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";
import { usePublicMessages } from "Hooks/chat/usePublicMessages";
import PublicTextMessage from "./PublicTextMessage";

const groupMessagesBySender = (messages) => {
  const grouped = messages?.reduce((groups, message) => {
    const { sender } = message;
    if (!groups[sender]) {
      groups[sender] = [];
    }
    groups[sender].push(message);
    return groups;
  }, {});

  return Object?.keys(grouped)
    .map((senderId) => ({
      senderId: Number(senderId),
      messages: grouped[senderId],
    }))
    .sort((a, b) => new Date(b.messages[0].created_at) - new Date(a.messages[0].created_at));
};


const PublicMessages = () => {
  const navigate = useNavigate();

  const { profileData, isProfileDataLoading, profileDataError } = useProfile();
  const { publicMessages, isPublicMessagesLoading, publicMessagesError } =
    usePublicMessages();

  const myID = profileData?.data?.data?.user?.id;

  const allPublicMessages = publicMessages?.data;

  const myPublicMessages = allPublicMessages?.filter(
    (item) => item.sender != myID
  );

  console.log("my Public messages", myPublicMessages);

  const groupedMessages = groupMessagesBySender(allPublicMessages);

  console.log("grouped Messages", groupedMessages);

  return (
    <div className="mt-[30px]">
      <div
        style={{ border: "1px solid #d1d5db" }}
        className=" rounded flex items-center justify-between cursor-pointer"
        onClick={() => navigate(`/chat/public-message/${myID}`)}
      >
        <div className="flex items-center gap-4 py-3 px-3">
          <PiMegaphoneBold className="text-[22px] text-[#4f0da3]" />

          <div className="">
            <h3 className="text-[15px] font-semibold">Create public message</h3>
            <h3 className="text-neutral-400 font-normal text-[13px]">
              Send a message to everyone on 2GEDA
            </h3>
          </div>
        </div>
        <div>
          <FaChevronRight className="text-[14px] text-neutral-300 mr-4" />
        </div>
      </div>
      {groupedMessages?.map((item) => (
        <PublicTextMessage
          key={item?.id}
          text={item?.messages[0]?.text}
          date={item?.messages[0]?.created_at}
          userID={myID}
          senderID={item?.messages[0].sender}
          messages={item?.messages}
        />
      ))}
    </div>
  );
};

export default PublicMessages;

