const Loader = () => {
  return (
    <>
      <div className="loading_cont">
        <span></span>
      </div>
    </>
  );
};

export default Loader;
