import React, { useContext } from 'react'
import { MusicPlayerContext } from '../context/MusicPlayerContext'

export default function ContextModal({children}) {
    const {modalOpen} = useContext(MusicPlayerContext)
  return (
    <>{modalOpen && <div>
        {children}
        </div>}</>
  )
}
