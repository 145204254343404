import useGetTickets from "Hooks/useGetTickets";
import { useEffect, useRef } from "react";
import { FaArrowLeftLong, FaChevronRight, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const SellTicketDash = () => {
  const navigate = useNavigate();

  const pageRef = useRef(null)

  const { overview, overviewRefetch } = useGetTickets();

  const user = overview?.data?.message[0] ? overview?.data?.message[0] : [];

  const goBack = () => {
    navigate("/ticket");
  };

  const goToCreateTicket = () => {
    navigate("/ticket/sell-ticket/create-event-one");
  };

  const goToMyEvents = () => {
    navigate("/ticket/sell-ticket/my-events");
  };

  const goToPayout = () => {
    navigate("/ticket/sell-ticket/payout");
  };

  useEffect(() => {
    overviewRefetch()
  }, [overviewRefetch])

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -8 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);


  
  return (
    <div ref={pageRef}>
      <div>
        <div className="lg:mt-0 p-4">
          <div className="flex justify-between items-center">
            <div className="flex w-full items-center gap-4 mt-4">
              <FaArrowLeftLong onClick={goBack} className="text-[18px]" />
              <h2 className="font-semibold text-[17px] mt-2">Sell Tickets</h2>
            </div>
          </div>
          <div className=" mt-12 text-[#fff]">
            <button
              className="p-3 text-[15px] w-full flex items-center gap-3 bg-[#4f0da3] justify-center hover:opacity-90 rounded"
              onClick={goToCreateTicket}
            >
              <FaPlus className="mb-1" />
              Create event
            </button>
          </div>
          <div className="mt-12">
            <div>
              <h2>Account overview</h2>
            </div>
            <div className="grid grid-cols-2 gap-4 text-[#fff]">
              <div
                className="bg-zinc-800 rounded h-[100px] p-6 flex flex-col justify-center cursor-pointer hover:opacity-90"
                onClick={goToMyEvents}
              >
                <p className="text-white lg:text-[18px] text-[16px]">
                  {user?.total_event || 0}
                </p>
                <p className="text-white">Total events</p>
              </div>
              <div className="bg-orange-600 rounded h-[100px] p-6 flex flex-col justify-center">
                <p className="text-white lg:text-[18px] text-[16px] cursor-default">
                  {user?.total_tickets_sold || 0}
                </p>
                <p className="text-white cursor-default">Total tickets sold</p>
              </div>
              <div className="bg-green-500 rounded h-[100px] p-6 flex flex-col justify-center">
                <p className="text-white lg:text-[18px] text-[16px] cursor-default">
                  {user?.total_earning || '0.00'}
                </p>
                <p className="text-white cursor-default">Total earnings</p>
              </div>
              <div className="bg-neutral-300 rounded h-[100px] p-6 flex flex-col justify-center">
                <p className="text-black lg:text-[18px] text-[16px] cursor-default">
                  {user?.current_balance || '0.00'}
                </p>
                <p className="text-black cursor-default">Current balance</p>
              </div>
            </div>
            <div className="mt-10">
              <div
                className="flex items-center justify-between border p-4 mt-2 rounded cursor-pointer"
                onClick={goToMyEvents}
              >
                <div className="">
                  <h4 className="text-[16px] text-black">My events</h4>
                  <p className="text-neutral-400">
                    View and manage all your events
                  </p>
                </div>
                <FaChevronRight className="text-black" />
              </div>
              <div
                className="flex items-center justify-between border p-4 mt-4 rounded cursor-pointer"
                onClick={goToPayout}
              >
                <div>
                  <h4 className="text-[16px] text-black">Payouts</h4>
                  <p className="text-neutral-400">Manage your withdrawals</p>
                </div>
                <FaChevronRight className="text-black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellTicketDash;
