import { VscDebugStepOut } from "react-icons/vsc";
import TicRepDetail from "../Modals/TicRepDetail";
import { useState } from "react";
import moment from "moment";

/* function convertTo12HourFormat(time) {
  let [hours, minutes] = time?.split(":").map(Number);

  let period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  let formattedHours = hours?.toString().padStart(2, "0");
  let formattedMinutes = minutes?.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${period}`;
}
 */


const EachReportCol = ({lastName, firstName, baseID, date, amount, quantity}) => {
  const [isTicOpen, setIsTicOpen] = useState(false);

  const getCurrentDate = () => {
    const currentDate = moment(date).format("DD MMM, YYYY");
    return `${currentDate}`;
  };

  const handleTicOpen = (e) => {
    setIsTicOpen(true);
  };
  const handleTicClose = (e) => {
    setIsTicOpen(false);
  };
  
  return (
    <>
      {isTicOpen && (
        <div className="modal-full-container">
          <TicRepDetail handleTicClose={handleTicClose} />
        </div>
      )}
      <div className="each-col-rep-cont flex " onClick={handleTicOpen}>
        <div className="rep-col-left flex ">
          <div className="rep-icon-bx flex">
            <VscDebugStepOut className="rotate-icon" />
          </div>

          <div className="flex items-center gap-4">
            <div className="">
              <div className="name-byr">{lastName} {firstName}</div>
              <div className="id-buyr">ID: {baseID?.trim()}</div>
            </div>
            <div className="flex items-center justify-center py-1 px-4 rounded-full bg-[#4f0da3] text-[#fff]">{quantity} tickets</div>
          </div>
        </div>
        <div className="rep-col-right">
          <div className="name-byr">{getCurrentDate()}</div>
          <div className="total-amt-byr"># {amount}</div>
        </div>
      </div>
    </>
  );
};

export default EachReportCol;
