import axios from "axios";
import { url } from "utils/index";
import { getLoginToken } from "./auth&poll";

export const getTvChannel = async () => {
  const res = await axios.get(`${url}/tv/user/channels/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getSingleTvChannel = async (id) => {
  const res = await axios.get(`${url}/tv/channel/?channel_id=${id}`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return res;
};

export const updateTvChannel = async (tvDetails, id) => {
  const res = await axios.patch(
    `${url}/tv/channel/?channel_id=${id}`,
    tvDetails,
    {
      headers: {
        Authorization: `Bearer ${getLoginToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const createTvChannel = async (formData) => {
  const res = await axios.post(`${url}/tv/channel/`, formData, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const createMovieFn = async (formData) => {
  const res = await axios.post(`${url}/tv/channel/videos/`, formData, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const updateMovieFn = async (formData, id) => {
  const res = await axios.patch(
    `${url}/tv/channel/videos/?video_id=${id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getLoginToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const getLiked = async () => {
  const res = await axios.get(`${url}/tv/like/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const likeMovieFn = async (formData) => {
  const res = await axios.post(`${url}/tv/like/`, formData, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const unlikeMovieFn = async (id) => {
  const res = await axios.delete(`${url}/tv/like/?video_id=${id}`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getAllMovies = async () => {
  const res = await axios.get(`${url}/tv/videos/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getAllMoviesSearch = async (queryString) => {
  const res = await axios.get(`${url}/tv/videos/search/?q=${queryString}`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getUserMoviesFn = async (id) => {
  const res = await axios.get(`${url}/tv/channel/videos/?channel_id=${id}`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return res;
};

export const incrementPlaysFn = async (data) => {
  const res = await axios.post(`${url}/tv/video/increment-plays/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const deleteMovie = async (id) => {
  const res = await axios.delete(`${url}/tv/video/${id}/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getMovieStats = async (id) => {
  const res = await axios.get(`${url}/tv/video/${id}/statistics/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return res;
};

export const watchMovieFn = async (data) => {
  const res = await axios.post(`${url}/tv/watch-history/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getWatched = async () => {
  const res = await axios.get(`${url}/tv/watch-history`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return res;
};

export const downloadMovieFn = async (data) => {
  const res = await axios.post(`${url}/tv/video/download/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getDownloadedMoviesFn = async () => {
  const res = await axios.get(`${url}/tv/video/download`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });

  return res;
};

export const postCommentFn = async (data) => {
  const res = await axios.post(`${url}/tv/comment/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const getCommentsFn = async (id) => {
  const res = await axios.get(`${url}/tv/video/${id}/comments/`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getChannelStats = async (id) => {
  const res = await axios.get(`${url}/tv/channel/${id}/statistics`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const getSubscribers = async (id) => {
  const res = await axios.get(`${url}/tv/channel/${id}/subscribers`, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return res;
};

export const subscribeFn = async (data) => {
  const res = await axios.post(`${url}/tv/channel/subscribe/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const unsubscribeFn = async (data) => {
  const res = await axios.post(`${url}/tv/channel/unsubscribe/`, data, {
    headers: {
      Authorization: `Bearer ${getLoginToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

