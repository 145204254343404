import moment from "moment";
import PromoteTicketModal from "pages/Tv/PromoteTicketModal";
import { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";

const demoImage =
  "https://img.freepik.com/free-photo/restaurant-hall-with-tables-decorated-with-tall-vases-with-roses_1304-4823.jpg";

const TickectCardFull = ({ data }) => {
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  return (
    <div className="tick-full-card-container">
      {isPromoteModalOpen && (
        <PromoteTicketModal
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
          data={data}
        />
      )}
      <div className="tic-image-detail-bx">
        <div className="img-tick-cont">
          {/* <div className="ver-lay">
            <img src="images/lo5.png" alt="" />
          </div> */}
          <img src={data?.event_image || demoImage} alt="" />
        </div>
        <div className="event-details">
          <div className="event-date">
            {moment(data?.start_date).format("DD MMM, YYYY")}
          </div>
          <div className="event-name">{data?.title}</div>
          <div className="location-box">
            <MdLocationOn />
            <div className="loca-txt">{data?.venue_name}</div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <button
          className="w-full mt-3 border border-[#4f0da3] text-black py-3 text-[14px] rounded"
          onClick={() => setIsPromoteModalOpen(true)}
        >
          Promote Ticket
        </button>
        <Link
          to={`/ticket/event/${data?.id}`}
          className="w-full mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded text-center no-underline"
        >
          Get Ticket
        </Link>
      </div>
    </div>
  );
};

export default TickectCardFull;
