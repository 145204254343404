import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AudioPlayer2 from '../AudioPlayer2/AudioPlayer'
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import SpecialPicksCard from '../Components/SpecialPicksCard';
import { MusicPlayerContext } from '../context/MusicPlayerContext';

export default function SpecialPicksSection() {
    const [songs, setSongs] = useState([])
    const authToken = localStorage.getItem("authToken")
  const [minimized,setMinimized]=useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [activePlayer, setActivePlayer] = useState(null);
  const [clickedIndex3, setClickedIndex3] = useState(null); // Initialize with null
 const [currentSong3, setCurrentSong3] = useState(songs[clickedIndex3])
  const navigation = useNavigate()
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)


  const handleItemClick3 = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };

  const handlePlayPause = (id) => {
    if (id === currentlyPlaying) {
      setCurrentlyPlaying(null);
    } else {
      setCurrentlyPlaying(id);
    }
  };


    const GetBigHits = () => {
        axios
          .get(`https://api.2geda.net/api/stereo/songs/`, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "X-CSRFToken": process.env.REACT_TOKEN,
            },
          })
          .then((res) => {
            // Filter the items where plays < 3
            setSongs(res.data.data)
            // const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
            // Assuming setBigHit is a function to update state
            // setBigHit(filteredHits);
            // console.log(filteredHits,"bighits==="); // This will log the filtered items
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };

      useEffect(()=>{
        GetBigHits()
      }, [])
    
  return (
    <div>
      <div
                className={songs?.length>0?"mx-[10px] flex flex-row my-[10px] gap-[10px] overflow-x-scroll":null}
                style={{ scrollbarWidth: "none" }}>
                {/* <SpecialPicksCard />
                <SpecialPicksCard
                  img={require("../Assets/Image6.jpeg")}
                  category={"Life of the party"}
                />
                <SpecialPicksCard
                  img={require("../Assets/Image3.jpeg")}
                  category={"Afrojams"}
                />
                <SpecialPicksCard />
                <SpecialPicksCard
                  img={require("../Assets/Image6.jpeg")}
                  category={"Life of the party"}
                />
                <SpecialPicksCard
                  img={require("../Assets/Image3.jpeg")}
                  category={"Afrojams"}
                /> */}
                {songs?.length>0?songs?.map((res, index)=>{
                  return (
                  <SpecialPicksCard onClick={()=>handleItemClick3(index, songs)} setModalOpen2={setModalOpen2} modalOpen2={modalOpen2} name={res.title} img={res.cover_image?res.cover_image:null} artist={res.artist.artist_name}  id={res.id} category={res.title} audio={res.audio_file?res.audio_file:null} activePlayer={activePlayer} setActivePlayer={setActivePlayer} handlePlayPause={handlePlayPause} />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
              </div>

              {/* {modalOpen2 && (
          <div
          id="modelConfirm"
          className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
          <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen2 && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen2} modalOpen={modalOpen2} tracks={songs} index={clickedIndex3}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  )
}
