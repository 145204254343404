import { useMutation, useQueryClient } from "@tanstack/react-query";
import { watchMovieFn} from "api/services/tv";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useWatchMovie = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    status: watchMovieStatus,
    mutate: watchMovie,
    error: watchMovieError
  } = useMutation({
    mutationFn: (formData) => watchMovieFn(formData),

    onSuccess: () => {
        queryClient.invalidateQueries({
            queryKey: ["watched"],
          });
    },
  });

  return {
    watchMovie,
    watchMovieStatus,
    watchMovieError
  };
};

export default useWatchMovie;
