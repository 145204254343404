import React, { useState } from 'react'
import JobCard from './FeedsJob'
import JobDescription from './JobDescription'

const JobListing = () => {
  const [showDescription, setShowDescription] = useState(false)
  const [selectedJob, setSelectedJob] = useState(null)

  const handleViewDescription = (job) => {
    setSelectedJob(job)
    setShowDescription(true)
  }

  const handleBack = () => {
    setShowDescription(false)
    setSelectedJob(null)
  }

  return (
    <div>
      {showDescription ? (
        <JobDescription job={selectedJob} onBack={handleBack} />
      ) : (
        <JobCard onViewDescription={handleViewDescription} />
      )}
    </div>
  )
}

export default JobListing
