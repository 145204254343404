import { Link } from "react-router-dom";

const EventCategory = ({ events }) => {
  const allCategories = events?.length
  const allFestivals = events?.filter(
    (event) => event.event_category === "festival"
  ).length;
  const allConcerts = events?.filter(
    (event) => event.event_category === "concerts"
  ).length;
  const allFashion = events?.filter(
    (event) => event.event_category === "fashion"
  ).length;
  const allOthers = events?.filter(
    (event) => event.event_category === "others"
  ).length;

  const data = [
    {
      img: "images/c12.png",
      name: "all-tickets",
      total: `${allCategories}`,
    },
    {
      img: "images/cat1.png",
      name: "Festival",
      total: `${allFestivals}`,
    },
    {
      img: "images/cat2.png",
      name: "Concerts",
      total: `${allConcerts}`,
    },
    {
      img: "images/cat3.png",
      name: "Fashion",
      total: `${allFashion}`,
    },
    {
      img: "images/cat4.png",
      name: "Others",
      total: `${allOthers}`,
    },
  ];

  return (
    <div>
      <div className="flex w-full items-center justify-between py-1 mt-6">
        <h2 className="font-semibold text-[15px]">Event categories</h2>
      </div>
      <div className="p-2 grid gap-4 grid-flow-col lg:auto-cols-[28%] md:auto-cols-[40%] auto-cols-[40%] overflow-x-scroll overscroll-contain">
        {data.map((item, index) => (
          <div className="border shadow-sm flex justify-center items-center gap-3 p-4 px-6">
            <div className="flex items-center justify-center w-12 h-12 bg-neutral-300 rounded-full">
              <img src={item.img} alt={item.img} className="w-8 h-8" />
            </div>

            {item?.total > 0 ? (
              <Link
                to={`/ticket/${item.name.toLowerCase()}`}
                key={index}
                className="text-black no-underline"
              >
                <div className="flex flex-col justify-center items-center">
                  <p className="text-[14px] font-semibold">{item.name === "all-tickets" ? "All" : item.name}</p>
                  <p className="text-[12px]">
                    {item.total} {item.total > 1 ? "events" : "event"}
                  </p>
                </div>
              </Link>
            ) : (
              <div className="flex flex-col justify-center items-center cursor-default">
                <p className="text-[14px] font-semibold cursor-default">{item.name}</p>
                <p className="text-[12px] cursor-default">
                  {item.total} {item.total > 1 ? "events" : "event"}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventCategory;
