import Stacked from "components/newCommerce/shared/Stacked";
import Header from "components/newCommerce/typography/txtHeader";
import { useState, useContext, useEffect } from "react";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import Template from "components/newCommerce/shared/template";
import { FormatwithComma } from "utils/commerceUtils";
import { Modal, Box, CircularProgress } from "@mui/material";
import { SubscribePromote } from "api/commerce/Apiactions";
import { FaTimes } from "react-icons/fa";
import { ModalContext } from "Context/ModalContext";
let bg;
const switchtagbg = (curr) => {
  switch (curr) {
    case 1:
      bg = "#FF6347";
      break;
    case 2:
      bg = "#1E90FF";
      break;
    case 3:
      bg = "#32CD32";
      break;
    case 4:
      bg = "#FFD700";
      break;
    case 5:
      bg = "#FF4500";
      break;
    case 6:
      bg = "#8A2BE2";
      break;
    default:
      bg = "red";
  }
  return bg;
};

const Proceedpay = ({ plannedchose }) => {
  const { promotedDetails } = useContext(ModalContext);
  const [timeVal, setTimeval] = useState(
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(new Date().getDate()).padStart(2, "0")}`
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [tabpayment, settabPayment] = useState(false);
  // const [paymentstatus,setPaymentstatus] = useState(null)
  const handleDateChange = (payload) => {
    setTimeval(payload);
    console.log(payload);
  };

  // handling-subscription
  const handleSubscribe = async () => {
    handleOpen();
    setError(null); // Clear any previous errors
    setSuccess(false);
    setLoading(true);
    // mock-payment-for-now
    try {
      const subscribe = await SubscribePromote(plannedchose.id);
      console.log(subscribe);
      // once-payment-chosen,call a new tab to open url
      setLoading(false);
      setSuccess(true);
      if (subscribe.paystack.authorization_url) {
        setTimeout(() => {
          window.open(subscribe.paystack.authorization_url, "_blank");
        }, 2500);
      }
      settabPayment(true);
    } catch (err) {
      setError(err);
      setLoading(false);
      setSuccess(false);
    }
  };

  // syncing-and-connecting-for-transaction-status

  // const checkPaymentStatus = async () => {
  //   try {
  //     const status = await CheckPaymentStatus(transactionId);
  //     if (status.data.status === "success") {
  //       setSuccess(false);
  //       setLoading(false)
  //       handleClose();
  //       handlePaymentres()
  //     } else if (status.data.status === "failed") {
  //       setError("Payment failed. Please try again.");
  //       setLoading(false);
  //       handleClose();
  //
  //     }
  //   } catch (err) {
  //     setError("Error checking payment status. Please try again.");
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   let interval;
  //   if (success && transactionId) {
  //     interval = setInterval(checkPaymentStatus, 5000);
  //   }
  //   return () => clearInterval(interval);
  // }, [success, transactionId]);

  return (
    <Stacked d="column" g={2} jc={"center"} ai={"center"} cname={"pt-[5rem]"}>
      {/* preview-item */}
      <div>
        <Template content={promotedDetails} hidebuy={true} />
      </div>
      {/* payment-details */}
      <Stacked
        d="column"
        g={1}
        styles={{
          border: "1px solid #4f0da3 ",
          width: "clamp(300px, 350px, 370px)",
        }}
        cname={"py-2 pr-3 pl-2 text-left rounded-lg"}
      >
        <div className="flex justify-between item-center">
          <Header title={"You have Selected"} fs={"13px"} />

          <Header title={plannedchose.duration} fs={"13px"} />
        </div>
        <div className="flex justify-between item-center ">
          <div
            className="rounded-[16px] p-2 justify-end"
            style={{
              background: switchtagbg(Number(plannedchose.id)),
              color: `${bg === "#FFD700" ? "rgba(34, 34, 34, 0.7)" : "#ffff"}`,
            }}
          >
            {plannedchose.tag}
          </div>
          <Header
            title={FormatwithComma(plannedchose?.price)
              .toString()
              .replace(/^/, "₦")}
            cname={"justify-self-end"}
          />
        </div>
      </Stacked>
      <div
        className="flex flex-col gap-y-2"
        style={{ width: "clamp(300px, 350px, 370px)" }}
      >
        <Header title={"Start date"} />
        <div>
          <input
            style={{ color: "rgba(0, 0, 0, 0.5)" }}
            type="date"
            id={`start-date`}
            className="bg-gray-50 border leading-none border-gray-300  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-[450] text-[1.7rem]"
            value={timeVal || ""}
            onChange={(e) => handleDateChange(e.target.value)}
            //   disabled={checkDisabled(day)}
            disabled
            required
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Promote Subcription"
        aria-describedby="Waitin to subscribe to a plan"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#ffff",
            paddingBlock: 4,
            paddingInline: 3,
            borderRadius: 2,
          }}
        >
          {loading ? (
            <Stacked d="column" ai="center" jc="center" g={3}>
              <CircularProgress />
              <Header
                title={`${
                  success ? "Connecting Payment..." : "Gathering Information..."
                }`}
              />
            </Stacked>
          ) : (
            <>
              {success && (
                <Stacked d="column" ai="center" jc="center" g={3}>
                  <CircularProgress />
                  <Header
                    title={`${
                      tabpayment
                        ? "Checking Payment status.."
                        : "Connecting Payment..."
                    }`}
                  />
                  {tabpayment && <Header title={"Do not Close !"} />}
                </Stacked>
              )}

              {error && (
                <Stacked d="column" ai="center" jc="center" g={3}>
                  <FaTimes fontSize={"4.2rem"} fill="red" />
                  <Header title={"Error try again"} />
                </Stacked>
              )}
            </>
          )}
        </Box>
      </Modal>
      {/* this-modal-appears-for-when-payissuccesful-or-failed */}

      <ButtonSide
        title="Proceed to pay >>"
        bg="#4F0DA3"
        cl="#ffff"
        isDisabled={tabpayment}
        styles={{
          paddingInline: "8rem",
          marginTop: "5rem",
          paddingBlock: ".9rem",
          fontSize: "1.4rem",
        }}
        br="5px"
        click={handleSubscribe}
      />
    </Stacked>
  );
};

export default Proceedpay;
