import React, { useState } from "react";
import "../Vote/CreatePoll/CreatePoll.css";
import toast from "react-hot-toast";
import { ReportHotelApi } from "api/services/hotels";
import { FaTimes } from "react-icons/fa";

const ReportHotel = ({ onClose, fetchHotels, id }) => {

    const [isLoading, setIsLoading] = useState(false);

    const [report, setReport] = useState({
        hotel: id,
        comment: "",
    });

    const handleInputChange = (name, value) => {

        setReport((prevState) => ({ ...prevState, [name]: value }));
    };


    const handleReportHotel = async (e) => {
        e.preventDefault();
        const missingFields = [];
        if (!report.comment) missingFields.push("comment");
        if (missingFields.length > 0) {
            toast.error(`Missing required fields: ${missingFields.join(", ")}`);
            return;
        }

        try {
            setIsLoading(true);

            const resp = await ReportHotelApi(report);
            if (resp.data.status) {
                toast.success("Report sent successfully");
                fetchHotels();
                onClose();
            }
        } catch (error) {
            toast.error(error.response.data.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="form-wrapper about-modal" style={{ padding: "0" }}>
            <div
                className="createTop"
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "20px",
                    justifyContent: "center",
                    boxShadow: "10px 10px 20px lightgray",
                    padding: "20px 10px"
                }}
            >
                <h2 style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>Report this listing</h2>
                <div className="hotel-close-btn" style={{ marginLeft: "auto" }}><FaTimes onClick={onClose} /></div>
            </div>


            <div style={{ padding: "20px" }}>


                <form  onSubmit={handleReportHotel}>
                <textarea rows={'10'} name="comment" className="w-full p-4 bg-gray-100 my-20" placeholder="Share your experience" value={report.comment}
                    onChange={(e) => handleInputChange("comment", e.target.value)}></textarea>



                <button
                    className="create-poll-btn outline-none" style={{ width: "100%", margin: "20px 0" }}
                    disabled={isLoading}
                >
                    {isLoading ? "Loading..." : "Report listing"}
                </button>
                </form>
       
            </div>
        </div>
    );
};

export default ReportHotel;
