import useGetEventDetails from "Hooks/useGetEventDetails";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckbox } from "react-icons/io";
import { useForm } from "react-hook-form";

const ReceverDetail = ({ receiverDetailRef, eventDetail }) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSetAgreed = () => {
    setIsAgreed(!isAgreed);
  };

  const { handleBuyTicketOne } = useGetEventDetails();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
    },
  });

  const onSubmit = (data) => {
    handleBuyTicketOne({ ...data, isAgreed, ticketID: eventDetail?.id });
    navigate(`/ticket/purchase-ticket-detail/${eventDetail.id}`);
  };

  const noTicket =
    typeof eventDetail?.vip_ticket_available === "string" &&
    typeof eventDetail?.regular_ticket_available === "string";

  return (
    <>
      <div ref={receiverDetailRef} className="receiver-detail-container">
        <h4 className="text-[17px]">Receiver details</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="border h-[60px] rounded relative px-3 mt-6 flex">
            <label className="absolute -top-4 left-4 z-10  bg-white">
              First Name
            </label>
            <div className="">
              <input
                autoComplete="off"
                type="text"
                id="firstName"
                className="border-none outline-none text-[15px] absolute top-3 left-0 "
                placeholder="Enter your first name"
                {...register("firstName", {
                  required: "First name is required",
                })}
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.firstName?.message}
                </p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden"></p>
            </div>
          </div>

          <div className="border h-[60px] rounded relative px-3 mt-6 flex">
            <label className="absolute -top-4 left-4 z-10  bg-white">
              Last Name
            </label>
            <div className="">
              <input
                autoComplete="off"
                type="text"
                id="firstName"
                className="border-none outline-none text-[15px] bg-white absolute top-3 left-0 "
                placeholder="Enter your last name"
                {...register("lastName", {
                  required: "Last name is required",
                })}
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.lastName?.message}
                </p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden"></p>
            </div>
          </div>
          <div className="border h-[60px] rounded relative px-3 flex mt-6">
            <label className="absolute -top-4 left-4 z-10  bg-white">
              Email
            </label>
            <div className="">
              <input
                autoComplete="off"
                type="email"
                id="email"
                className="border-none outline-none text-[15px] absolute top-3 left-0 "
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                })}
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.email?.message}
                </p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden"></p>
            </div>
          </div>
          <div className="border h-[60px] rounded relative px-3 mt-6 flex">
            <label className="absolute -top-4 left-4 z-10  bg-white">
              Address
            </label>
            <div className="">
              <input
                autoComplete="off"
                type="text"
                id="address"
                className="border-none outline-none text-[15px] absolute top-3 left-0 "
                placeholder="Enter your address"
                {...register("address", {
                  required: "Address is required",
                })}
              />
            </div>
            <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
              {errors ? (
                <p className="text-red-500 text-[12px]">
                  {errors?.address?.message}
                </p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400 lg:block hidden"></p>
            </div>
          </div>
          <div className="mt-4 flex items-center gap-3">
            <div className="text-blue-500 text-[19px]">
              {!isAgreed ? (
                <MdCheckBoxOutlineBlank onClick={handleSetAgreed} />
              ) : (
                <IoMdCheckbox onClick={handleSetAgreed} />
              )}
            </div>
            <div className="text-[13px] lg:text-[15px]">
              I have read and agreed to{" "}
              <Link to={"/privacypolicy"}>terms of condition</Link> and{" "}
              <Link to={"/privacypolicy"}>privacy policy</Link>
            </div>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              disabled={!isAgreed || noTicket}
              className="bg-[#4f0da3] text-[#fff] p-3 w-full  rounded text-[14px]"
            >
              Purchase Tickets
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReceverDetail;
