import { useEffect, useState } from "react";
import { PiCopySimple } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import useGetEventDetails from "Hooks/useGetEventDetails";
import moment from "moment";
import useGetTickets from "Hooks/useGetTickets";
import Spin from "components/Spin/Spin";

const trimTitle = (str) => {
  if (str?.length > 28) {
    return str?.slice(0, 28) + "...";
  }
  return str;
};

function convertTo12HourFormat(time) {
  let [hours, minutes] = time?.split(":").map(Number);

  let period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  let formattedHours = hours?.toString().padStart(2, "0");
  let formattedMinutes = minutes?.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

const ReportSummary = () => {
  const { handleSummaryClose, summaryDetail } = useGetEventDetails();

  const eventID = summaryDetail?.id.trim();
  const { ticketReportData } = useGetTickets();

  const allTicketReports = ticketReportData?.data;

  const ticketReport = allTicketReports?.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item.event?.id?.trim() == eventID
  );

  const urlString = `https://2geda.net/ticket/event/${eventID}`;
  const [isCopied, setIsCopied] = useState(false);


    const regularTicketsSold = ticketReport
  ?.map(item => item.regular_ticket)
  ?.reduce((acc, i) => acc + (i || 0), 0);

    const vipTicketsSold = ticketReport
  ?.map(item => item.vip_ticket)
  ?.reduce((acc, i) => acc + (i || 0), 0);

  const {
    eventDetails,
    isEventDetailLoading,
    eventDetailError,
    eventDetailRefetch,
    isEventDetailFetching,
  } = useGetTickets(eventID);

  const summary = {
    ...eventDetails?.data?.event_details,
    ...eventDetails?.data?.available_tickets,
    ...eventDetails?.data?.remaining_tickets,
  };

  
  const demoImage =
    "https://img.freepik.com/free-photo/restaurant-hall-with-tables-decorated-with-tall-vases-with-roses_1304-4823.jpg";

  const venue = `${summary.venue_name}, ${summary?.venue_address}`;

  const getCurrentDate = () => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = moment(summary.start_date).format("DD MMM, YYYY");

    const day = moment(summary.start_date).weekday();

    return `${weekDays[day]?.slice(0, 3)} ${currentDate}`;
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(urlString);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    eventDetailRefetch();
  }, [eventDetailRefetch]);

  if (isEventDetailFetching || isEventDetailLoading) {
    return <Spin />;
  }

  return (
    <div className="report-summary-container">
      <img src={summary?.event_image || demoImage} alt="" />
      <div className="dwn-contt">
        <div className="grid grid-cols-12">
          <div className="col-span-9">
            <div className="byr-ct">Event title</div>
            <div className="det-byr-nm tic-mm-red">{summary.title}</div>
          </div>
          <div className="col-span-3">
            <div className="byr-ct">Date</div>
            <div className="det-byr-nm tic-mm-red">{getCurrentDate()}</div>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-4">
          <div className="col-span-9">
            <div className="byr-ct">Location</div>
            <div className="det-byr-nm tic-mm-red">{venue}</div>
          </div>
          <div className="col-span-3">
            <div className="byr-ct">Time</div>
            <div className="det-byr-nm tic-mm-red">
              {convertTo12HourFormat(summary?.start_time)}
            </div>
          </div>
        </div>
        <div className="copy-url-cont">
          <div className="url-txt">{urlString}</div>
          <PiCopySimple className="cp-icon" onClick={handleCopyClick} />
        </div>
        {isCopied && <div className="copied">Copied</div>}

        <div className="sold-tic-boxx flex ">
          <div className="cat-tic">
            <div className="cat-tct">Regular</div>
            <div className="tic-tct">{summary.regular_ticket_quantity}</div>
          </div>
          <div className="sold-boxx flex">{regularTicketsSold} sold</div>
          <div className="sold-pri">
            # {summaryDetail?.regular_ticket_price}
          </div>
        </div>
        <div className="sold-tic-boxx flex">
          <div className="cat-tic">
            <div className="cat-tct">VIP</div>
            <div className="tic-tct">{summary.vip_ticket_quantity}</div>
          </div>
          <div className="sold-boxx flex">{vipTicketsSold} sold</div>
          <div className="sold-pri"># {summary?.vip_ticket_price}</div>
        </div>
      </div>
      <div
        className="w-10 h-10 bg-neutral-500 rounded-full flex items-center justify-center absolute top-4 right-4"
        onClick={handleSummaryClose}
      >
        <AiOutlineClose className="text-[#fff] text-[18px]" />
      </div>
    </div>
  );
};

export default ReportSummary;
