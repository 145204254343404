import { useMutation, useQueryClient } from "@tanstack/react-query";
import {unsubscribeFn} from "api/services/tv";
import { useNavigate } from "react-router-dom";

const useUnsubscribe = (id) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    status: unsubscribeStatus,
    mutate: unsubscribe,
    error: unsubscribeError
  } = useMutation({
    mutationFn: (formData) => unsubscribeFn(formData),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscribers"],
      });

    },
  });

  return {
    unsubscribe,
    unsubscribeStatus,
    unsubscribeError
  };
};

export default useUnsubscribe;
