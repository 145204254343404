import Header from "../typography/txtHeader";
import Stacked from "../shared/Stacked";
import { ModalContext } from "Context/ModalContext";
import { FiArrowLeft } from "react-icons/fi";
import { LiaTimesSolid } from "react-icons/lia";
import { useContext, useState, useEffect, useRef } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CiShare2 } from "react-icons/ci";
import { MdOutlineReport } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import { MdDelete } from "react-icons/md";
// import { MdOutlineEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { GoMegaphone } from "react-icons/go";
const SellHeader = ({
  title,
  hide,
  hideArr,
  clickBack,
  brad,
  dots,
  cname,
  dangerClick,
  titleCol,
  purpose,
  isDelete,
  setIdeletetype,
}) => {
  const { isOpen, closeModal, cartContent } = useContext(ModalContext);

  const handleClose = () => {
    isOpen && closeModal();
  };
  const [hideNav, SetNav] = useState(false);

  const action = () => {
    SetNav(true);
  };

  return (
    <>
      <Stacked
        pt={2.5}
        // styles={styleBt}
        bg="#ffff"
        pl={3}
        pr={3}
        pb={1}
        ai="center"
        jc="space-between"
        d="row"
        cname={cname}
        styles={{
          borderBottom: brad || "2px solid #d9d9d9",
          position: "sticky",
          top: 0,
          zIndex:
            title === "Product Information" || "My orders" ? "990" : "999",
          display: cartContent === "ordercompleted" && "none",
        }}
      >
        <FiArrowLeft
          className={`${hideArr ? "invisible" : "visible"}`}
          onClick={() => {
            // go-back
            clickBack ? clickBack() : window.history.back();
            // window.history.back();
          }}
          fontSize="25px"
          stroke="rgba(0, 0, 0, 0.6)"
        />
        <Header fs="20px" fw="700" cl={titleCol || "#000000"} title={title} />

        {/* show-on-condtition */}
        <div className={`${hide ? "invisible" : "visible"} mobile_btns_dot`}>
          {dots === "caution" && (
            <div onClick={() => dangerClick()}>
              <CgDanger fontSize="25px" />
            </div>
          )}
          {dots && dots !== "caution" && dots !== "promote" ? (
            <div className="action_nav" onClick={action}>
              <BiDotsVerticalRounded fontSize="20px" />
            </div>
          ) : !dots && dots !== "caution" ? (
            <div onClick={handleClose}>
              <LiaTimesSolid fontSize="25px" stroke="rgba(0, 0, 0, 0.6)" />
            </div>
          ) : dots && dots === "promote" ? (
            <Flashsvg />
          ) : null}
        </div>
        {hideNav ? (
          <AbsoluteNav
            nav={hideNav}
            config={SetNav}
            isuse={purpose}
            clikcedif={isDelete}
            deltype={setIdeletetype}
          />
        ) : null}
      </Stacked>
    </>
  );
};

const AbsoluteNav = ({ nav, config, isuse, clikcedif, deltype }) => {
  const { isOpen, openModal, setModalContent } = useContext(ModalContext);

  const showReview = (payload) => {
    nav && config(false);
    console.log("Shared");
    setModalContent(payload);
    !isOpen && openModal();
  };

  const optionsRef = useRef(null);

  useEffect(() => {
    const closeOptions = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        config(false);
      }
    };
    optionsRef
      ? document.addEventListener("mousedown", closeOptions)
      : document.removeEventListener("mousedown", closeOptions);

    return () => {
      document.removeEventListener("mousedown", closeOptions);
    };
  }, [nav, config]);

  const Location = useLocation();
  const searchparams = new URLSearchParams(Location.search);
  const productid = searchparams.get("id");
  return (
    <div
      ref={optionsRef}
      className="absolute top-[5rem] right-12 bg-[#ffff] rounded-lg flex flex-col py-3 px-2 gap-y-4 shadow-lg "
    >
      {/* share-modal-button */}

      {isuse === "producetdetails" ? (
        <>
          <div
            className="flex flex-row gap-x-2 items-center cursor-pointer hover:bg-[#d9d9d9] p-y-3 px-2 rounded-lg select-none"
            onClick={() => showReview("sharemodal")}
          >
            <CiShare2 fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Share product</span>
          </div>
          {/* report-seller */}
          <div className="flex flex-row gap-x-2 items-center cursor-pointer hover:bg-[#d9d9d9] p-y-3 px-2 rounded-lg select-none">
            <MdOutlineReport fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Report seller</span>
          </div>
          {/* report-item */}
          <div className="flex flex-row gap-x-2 items-center cursor-pointer hover:bg-[#d9d9d9] p-y-3 px-2 rounded-lg select-none">
            <MdOutlineReport fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Report thie item</span>
          </div>
        </>
      ) : isuse === "managestoredetails" ? (
        <>
          {/* managestoreproduct-popup */}
          <div
            className="flex flex-row gap-x-2 items-center cursor-pointer hover:bg-[#d9d9d9] p-y-3 px-2 rounded-lg select-none"
            onClick={() => showReview("sharemodal")}
          >
            <CiShare2 fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Share product</span>
          </div>
          {/* Delete-item */}
          <div
            className="flex flex-row gap-x-2 items-center cursor-pointer hover:bg-[#d9d9d9] p-y-3 px-2 rounded-lg select-none"
            onClick={() => {
              config(false);
              deltype("del");
              clikcedif(productid);
            }}
          >
            <MdDelete fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Delete Item</span>
          </div>
          {/* promote-item */}
          <button className="flex flex-row gap-x-4 items-center cursor-pointer hover:bg-[#d9d9d9] py-2 px-2 rounded-lg select-none ">
            <GoMegaphone fontSize="20px" stroke="#222222" />
            <span className="text-left text-[1.6rem]">Promote Item</span>
          </button>
        </>
      ) : null}
    </div>
  );
};
export default SellHeader;

// flash-svg

export const Flashsvg = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1659_28342)">
        <path
          d="M18.2832 0.111218C18.4422 0.203418 18.5653 0.346827 18.6323 0.518016C18.6993 0.689206 18.7063 0.878057 18.6521 1.05372L15.7254 10.5632H21.1253C21.284 10.5632 21.4392 10.6096 21.5719 10.6967C21.7045 10.7839 21.8087 10.9079 21.8716 11.0536C21.9345 11.1993 21.9534 11.3602 21.9259 11.5165C21.8984 11.6728 21.8257 11.8176 21.7168 11.9331L8.71682 25.7456C8.59104 25.8794 8.42365 25.9666 8.24196 25.993C8.06026 26.0195 7.87496 25.9836 7.71626 25.8912C7.55757 25.7989 7.43483 25.6555 7.36806 25.4844C7.30129 25.3134 7.29442 25.1248 7.34857 24.9493L10.2752 15.4382H4.87532C4.71663 15.4383 4.5614 15.3919 4.42878 15.3047C4.29617 15.2176 4.19197 15.0935 4.12907 14.9478C4.06616 14.8021 4.04729 14.6412 4.0748 14.4849C4.1023 14.3286 4.17496 14.1838 4.28382 14.0683L17.2838 0.255843C17.4095 0.122266 17.5766 0.0351168 17.758 0.00856422C17.9395 -0.0179884 18.1246 0.0176176 18.2832 0.109593V0.111218Z"
          fill="url(#paint0_linear_1659_28342)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1659_28342"
          x1="13.0003"
          y1="0"
          x2="13.0003"
          y2="26.0015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA800" />
          <stop offset="1" stopColor="#FF0000" />
        </linearGradient>
        <clipPath id="clip0_1659_28342">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
