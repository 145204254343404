import { useQuery } from "@tanstack/react-query";
//import { getProfileData } from "services/profile_business_API";
import { getAllRewards } from "../../api/services/profile";

export const useGetRewards = () => {
  const {
    status: rewardStatus,
    data,
    error,
  } = useQuery({
    queryKey: ["profile"],
    queryFn: getAllRewards,
  });

  const rewards = data?.data

  return { rewardStatus, rewards, error };
};


