import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FiShare2 } from 'react-icons/fi'
import { MdOutlineContentCopy } from 'react-icons/md'
import { useFeedsRepost } from 'api/hooks/feeds'
import Swal from "sweetalert2";

const Sharepost = ({ postId, postData }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const showSwalMessage = (icon, title, text, onConfirm = null) => {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: "OK",
    }).then(() => {
      if (onConfirm) onConfirm(); // Execute the callback if provided
    });
  };

  const { repost } = useFeedsRepost({
    postId,
    onSuccess: (response) => {
      console.log({ response });
      showSwalMessage(
        "success",
        "Repost Successful!",
        "Your post has been successfully shared.",
        handleClose // Pass handleClose as the callback to execute on confirmation
      );
    },
    onError: (errorResponse) => {
      console.log({ errorResponse });
      showSwalMessage(
        "error",
        "Repost Failed",
        "There was an issue sharing your post. Please try again.",
        handleClose
      );
    },
  });

  // const { repost } = useFeedsRepost({
  //   postId,
  //   onSuccess: (response) => {
  //     console.log({ response })
  //     handleClose()
  //   },
  //   onError: (errorResponse) => {
  //     console.log({ errorResponse })
  //   },
  // })

  
  const handleRepost = () => {
    console.log(rest, 'rest')
    repost(rest)
  }
  const { created_at, id, hashtags, ...rest } = Object.assign({}, postData)
  const baseUrl = `https://www.2geda.net`;
  // const urlText = `${baseUrl}/Home/${postId}`; 

  const handleCopyUrl = async () => {
    const urlText = `${baseUrl}/Home/${postId}`; 
    console.log(urlText);

    try {
      await navigator.clipboard.writeText(urlText); 
      console.log("Copied:", urlText);
    } catch (err) {
      console.error("Failed to copy:", err);
    }

    handleClose();
  };

  // const handleCopyUrl = async () => {
  //   const urlText = window.location.href.includes('Home')
  //     ? `${baseUrl}${postData?.post_url}`
  //     : `${baseUrl}${postData?.post_url}`
  //     console.log(urlText)
  //   try {
  //     await navigator.clipboard.writeText(urlText)
  //     console.log('copied', urlText)
  //   } catch (err) {
  //     console.error('Failed to copy: ', err)
  //   }
  //   handleClose()
  // }

  return (
    <div className='share-post-container'>
      <Button
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          background: 'transparent',
          minWidth: 'fit-content',
          padding: 0,
        }}
      >
        <FiShare2 size={22} color='#000000b9' />
      </Button>
      <Menu
        id='positioned-menu'
        aria-labelledby='positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: { marginLeft: -8 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className='share-post-dropdown'
      >
        <MenuItem
          onClick={handleRepost}
          sx={{
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <FiShare2 /> share post on 2geda
        </MenuItem>
        <MenuItem
          onClick={handleCopyUrl}
          sx={{
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <MdOutlineContentCopy /> Share outside 2geda
        </MenuItem>
      </Menu>
    </div>
  )
}

export default Sharepost;
