import {
  Box,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Modal,
} from "@mui/material";
import Description from "components/newCommerce/typography/txtDescription";
import { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import useLocalstorage from "api/hooks/commerce/uselocalStorage";
import Stacked from "components/newCommerce/shared/Stacked";
import { ModalContext } from "Context/ModalContext";
const textfield = {
  fontFamily: "Ubuntu !important",
  background: "#ffff",

  "&:placeholder": {
    fontFamily: "Ubuntu !important",
  },
};

const Menulist = styled(MenuItem)({
  fontFamily: "Ubuntu !important",
  fontSize: "1.2rem !important",
});
const labelStyle = {
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Ubuntu",
  cursor: "pointer",
  userSelect: "none",
  margin: "0 !important",
};
const DetailsForm = () => {
  const [val, setVal] = useState({
    fname: "",
    secname: "",
    del_address: "",
    region: "",
    phone_number: "",
    city: "",
    isdefault: "",
  });
  const [disable, setDisable] = useState(true);
  // toggle-radio-functionality
  const [ischeck, setIschecked] = useState(false);
  // uselocal-storage
  const { setParsedField, saveForm, updateForm } = useLocalstorage();
  const { detailsBuyerEdit, setDetailsBuyerEdit } = useContext(ModalContext);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setVal((prev) => ({
      ...prev,
      [name]: value,
      id: window.crypto.randomUUID(),
      isdefault: ischeck,
    }));
  };

  const checkRadio = () => {
    setIschecked(!ischeck);
  };

  useEffect(() => {
    const isAllFilled = Object.values(val).every(
      (val) =>
        typeof val === "boolean" ||
        (typeof val === "string" && val.trim() !== "")
    );

    setDisable(!isAllFilled);
  }, [val, ischeck]);

  useEffect(() => {
    if (detailsBuyerEdit) {
      const {
        fname,
        secname,
        region,
        city,
        del_address,
        phone_number,
        isdefault,
      } = detailsBuyerEdit;

      const autofills = {
        fname,
        secname,
        del_address,
        region,
        phone_number,
        city,
        isdefault,
      };
      setIschecked(isdefault);

      setVal(autofills);

      // console.log(autofills);
      // const updatedVal = Object.entries(detailsBuyerEdit).reduce(
      //   (acc, [key, value]) => {
      //     acc[key] = value;
      //     return acc;
      //   }
      // );
      // setVal(updatedVal);
    }
    // eslint-disable-next-line
  }, [detailsBuyerEdit]);
  const [isopen, setisopen] = useState(false);
  const handleSave = () => {
    if (detailsBuyerEdit) {
      const newidvalue = detailsBuyerEdit.id;
      val.id = newidvalue;
      val.isdefault = ischeck;
      setParsedField(val);
      console.log(val);
      setTimeout(() => {
        updateForm();
      }, 1000);
    } else if (!detailsBuyerEdit) {
      val.isdefault = ischeck;
      setParsedField(val);
      saveForm();
    }
    console.log(val);
    handlemodalOpen();
  };

  const handlemodalOpen = () => setisopen(true);

  const handlemodalClose = () => {
    setisopen(false);
  };

  const handleupdateSave = () => {
    if (detailsBuyerEdit) {
      updateForm();
    } else {
      saveForm();
    }
    setTimeout(() => {
      handlemodalClose();
    }, 500);
    setVal({
      fname: "",
      secname: "",
      del_address: "",
      region: "",
      phone_number: "",
      city: "",
    });
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-4 details_buyer">
        {/* first-name of buyer*/}
        <TextField
          sx={textfield}
          variant="outlined"
          label="Firstname"
          fullWidth
          size="medium"
          value={val.fname || ""}
          name="fname"
          onChange={handleChange}
        />
        {/* last-name */}
        <TextField
          sx={textfield}
          variant="outlined"
          label="Lastname"
          value={val.secname || ""}
          fullWidth
          size="medium"
          name="secname"
          onChange={handleChange}
        />
        {/* Delivery-address */}
        <TextField
          sx={textfield}
          variant="outlined"
          label="Delivery address"
          value={val.del_address || ""}
          fullWidth
          size="medium"
          name="del_address"
          onChange={handleChange}
        />
        {/* region- */}
        <FormControl fullWidth>
          <Select
            inputProps={{
              name: "region",
              id: "customer-region",
            }}
            size="medium"
            sx={{ background: "#ffff", fontFamily: "Ubuntu !important" }}
            onChange={handleChange}
            name="region"
            value={val.region || "placeholder"}
          >
            <Menulist value="placeholder">Region</Menulist>
            <Menulist value="west">West</Menulist>
            <Menulist value="south">South</Menulist>
            <Menulist value="east">East</Menulist>
            <Menulist value="north">North</Menulist>
          </Select>
        </FormControl>
        {/* phone-number */}
        <TextField
          sx={textfield}
          variant="outlined"
          label="Phone number"
          fullWidth
          size="medium"
          type="number"
          name="phone_number"
          onChange={handleChange}
          value={val.phone_number || ""}
        />
        {/* city */}
        <FormControl fullWidth>
          <Select
            inputProps={{
              name: "city",
              id: "customer-city",
            }}
            size="medium"
            sx={{ background: "#ffff", fontFamily: "Ubuntu !important" }}
            onChange={handleChange}
            name="city"
            value={val.city || "placeholder"}
          >
            <Menulist value="placeholder" disabled>
              City
            </Menulist>
            <Menulist value="ibadan">Ibadan</Menulist>
            <Menulist value="abuja">Abuja</Menulist>
            <Menulist value="kaduna">Kaduna</Menulist>
            <Menulist value="imo">Imo</Menulist>
          </Select>
        </FormControl>
      </div>
      {/* mock-data-hhere-to-span-width */}
      <Description
        title={titleText}
        sx={{ textAlign: "left !important", visibility: "hidden !important" }}
      />
      <div className="flex_setaddress">
        <div>
          <input
            type="radio"
            name="default_address"
            id="addressselect"
            style={{
              margin: 0,
              padding: 0,
              width: "18px",
              height: "18px",
              marginTop: ".6rem",
            }}
            checked={ischeck}
          />
        </div>
        <label
          htmlFor="addressselect"
          style={labelStyle}
          className="label_style"
          onClick={checkRadio}
        >
          Set as default address
        </label>
      </div>

      <Modal
        open={isopen}
        onClose={handlemodalClose}
        aria-labelledby="Store Delete modal"
        aria-describedby="add your variations"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#ffff",
            paddingBlock: 4,
            paddingInline: 2.5,
            borderRadius: 2,
          }}
        >
          <Stacked d="column" g={4}>
            <>
              <Description
                align={"center"}
                fs={"1.6rem"}
                title={`Are you absolutely sure you want to ${
                  detailsBuyerEdit ? "Update" : "Save"
                }  `}
                cl="#000000"
              />
              <Stacked d="row" jc={"space-between"}>
                <ButtonSide
                  title="Cancel"
                  cl="rgba(34, 34, 34, 0.7)"
                  bg="transparent"
                  styles={{
                    boxShadow: "none !important",
                    fontSize: "1.5rem !important",
                  }}
                  click={handlemodalClose}
                  className="mark_sold_btns"
                />
                <ButtonSide
                  title={detailsBuyerEdit ? "Update Details" : "Save Details"}
                  cl="rgba(255, 0, 0, 1)"
                  bg="transparent"
                  styles={{
                    boxShadow: "none !important",
                    fontSize: "1.5rem !important",
                  }}
                  click={handleupdateSave}
                  className="mark_sold_btns"
                />
              </Stacked>
            </>
          </Stacked>
        </Box>
      </Modal>
      {/* padding-top-buttn */}
      <div className="flex items-center justify-center pt-[6rem]">
        <ButtonSide
          title={`${detailsBuyerEdit ? "Update" : "Save"}`}
          bg="#4f0da3"
          cl="#ffff"
          jc="initial"
          click={handleSave}
          isDisabled={disable}
          styles={{ paddingInline: "7rem" }}
        />
      </div>
    </>
  );
};

let titleText =
  "Exceptional product! Impressive quality, versatile features, and user-friendly design. A must-have for anyone seeking top-notch performance. Highly recommend this outstanding product.";
export default DetailsForm;
