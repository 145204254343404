import { useRef, useState, useEffect } from "react";

import TvRightSide from "./TvRightSide";
import EditMovieProfileHeader from "./EditMovieProfileHeader";

import { libraryMovies } from "./Tv";
import ChannelMovieCard from "./ChannelMovieCard";
import { Link, useParams } from "react-router-dom";
import { useTv } from "Hooks/tv/useTv";
import { useGetLikedMovies } from "Hooks/tv/useGetLikedMovies";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";
import { useGetChannelStats } from "Hooks/tv/useGetChannelStats";

const ChannelContent = () => {
  
  const pageRef = useRef(null);

  const {id} = useParams()

  const isReady = false

  
  const {channelStats, isChannelStatsLoading, channelStatsError} = useGetChannelStats(id)

  console.log('channel Stats', channelStats)

  
  const allChannelStats = channelStats?.data?.data
  
  
  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  
  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white min-h-screen mt-2 py-2 px-4">
        <EditMovieProfileHeader header="Channel Stats" />

        {/* <div className="flex items-center cursor-pointer mt-1 py-1 px-4 text-neutral-400 rounded border">
          <select
            name=""
            id=""
            //value={feesOption}
            //onChange={(e) => setFeesOption(e.target.value)}
            className="border-none  bg-white cursor-pointer text-neutral-400 text-[15px] outline-none"
          >
            <option value="29">All</option>
            <option value="3">Today</option>
            <option value="12">This month</option>
            <option value="12">This year</option>
          </select>
        </div>
 */}
        <div className="mt-8 border pt-4 px-4">
          <Link
            className="text-[14px] text-neutral-800 no-underline"
            to=""
          >
            <h4 className="font-light text-neutral-800 text-[14px]">
              Total Downloads
            </h4>
            <h2 className="text-neutral-800">{allChannelStats?.downloads}</h2>
          </Link>
          {/* {isReady && <ChannelMovieCard data={allDownloadedMovies} />} */}
        </div>
        <div className="mt-8 border pt-4 px-4">
          <Link
            className="text-[14px] text-neutral-800 no-underline"
            to=""
          >
            <h4 className="font-light text-neutral-800 text-[14px]">
              Total Plays
            </h4>
          </Link>
          <h2 className="text-neutral-800">{allChannelStats?.plays}</h2>
          
        </div>
        <div className="mt-8 border pt-4 px-4">
          <Link
            className="text-[14px] text-neutral-800 no-underline"
            to=""
          >
            <h4 className="font-light text-neutral-800 text-[14px]">
              Total Likes
            </h4>
          </Link>
          <h2 className="text-neutral-800">{allChannelStats?.likes}</h2>
          
        </div>
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default ChannelContent;
