import { BiSearch } from "react-icons/bi";
import { useState } from "react";
import { TbCategory } from "react-icons/tb";
import { MdLocationCity } from "react-icons/md";
import { FaHouse } from "react-icons/fa6";
import { MdEventNote } from "react-icons/md";
import { TbBuildingEstate } from "react-icons/tb";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

const TicketSearh = ({ queries, setQueries }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQueries((prevQueries) => ({
      ...prevQueries,
      [name]: value,
    }));
  };


  return (
    <div className="w-full flex items-center relative">
      {isFilterOpen && (
        <div className=" py-2 shadow-sm z-10 absolute right-0 top-20 lg:w-[35%] w-[75%] bg-[#fff] rounded">
          <div className="flex items-center cursor-pointer py-2 px-4  text-neutral-400 border-b">
            {<TbCategory className="text-[20px]" />}
            <select
              name="categoryTerm"
              value={queries.categoryTerm}
              onChange={handleInputChange}
              className="border-none outline-none bg-white cursor-pointer text-neutral-400 text-[14px]"
            >
              <option value="">All categories</option>
              <option value="festival">festival</option>
              <option value="concerts">concerts</option>
              <option value="fashion">fashion</option>
              <option value="others">others</option>
            </select>
          </div>
          <div className="flex gap-2 items-center cursor-pointer text-neutral-400 border-b py-1 px-4">
            <MdEventNote className="text-[20px]" />
            <input
              type="text"
              placeholder="Enter name"
              name="eventName"
              value={queries.eventName}
              className="border-none border-b text-[14px] text-neutral-600 outline-none"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex gap-2 items-center cursor-pointer text-neutral-400 border-b py-1 px-4">
            <MdLocationCity className="text-[20px]" />
            <input
              type="text"
              placeholder="Enter city"
              name="citiesTerm"
              value={queries.citiesTerm}
              className="border-none border-b text-[14px] text-neutral-600 outline-none"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex gap-2 items-center cursor-pointer text-neutral-400 border-b py-1 px-4">
            <TbBuildingEstate className="text-[20px]" />
            <input
              type="text"
              placeholder="Enter state"
              name="statesTerm"
              value={queries.statesTerm}
              className="border-none border-b text-[14px] text-neutral-600 outline-none"
              onChange={handleInputChange}
            />
          </div>

          <div className="flex gap-2 items-center cursor-pointer text-neutral-400 border-b-none py-1 px-4">
            <FaHouse className="text-[20px]" />
            <input
              type="text"
              placeholder="Enter venue"
              name="venueTerm"
              value={queries.venueTerm}
              className="border-none border-b text-[14px] text-neutral-600 outline-none py-3"
              onChange={handleInputChange}
            />
          </div>

          {/* <div className="flex items-center  text-neutral-400 py-2 px-4 border-b">
            <FaTimeline className="text-[20px]" />
            <select
              name=""
              id=""
              className="border-none border-b cursor-pointer text-neutral-400 text-[14px] outline-none"
            >
              <option value="">Everytime</option>
              <option value="">Today</option>
              <option value="">This week</option>
              <option value="">This month</option>
              <option value="">This year</option>
            </select>
          </div> */}
        </div>
      )}
      <div className="w-[95%] border flex items-center px-4 ">
        <BiSearch className="text-black text-[16px]" />
        <input
          type="text"
          className=" text-[14px] border-none outline-none "
          placeholder="Find event"
          name="searchTerm"
          value={queries.searchTerm}
          onChange={handleInputChange}
        />
      </div>

      <div className="filter-search-bus" onClick={handleFilterClick}>
        {isFilterOpen ? (
          <FaChevronRight className="text-neutral-500 text-[16px]" />
        ) : (
          <FaChevronDown className="text-neutral-500 text-[16px]" />
        )}
      </div>
    </div>
  );
};

export default TicketSearh;
