import EventCard from "./EventCard";
import { useNavigate } from "react-router-dom";

const EventThisWeek = ({ eventsThisWeekData }) => {
  const navigate = useNavigate();

  const goToAllEventsThisWeek = () => {
    navigate("/ticket/all-events-this-week");
  };

  return (
    <div className="trending-product-container">
      <div className="flex w-full items-center justify-between py- mt-6">
        <h2 className="font-semibold text-[17px]">Events this week</h2>
        {eventsThisWeekData?.length ? (
          <button
            className="text-[13px] mt-2 border-none border-b text-[#FF8A15]"
            onClick={goToAllEventsThisWeek}
          >
            View all
          </button>
        ) : null}
      </div>
      <div className="product-card-row">
        {!eventsThisWeekData?.length ? (
          <p className="text-[13px] mt-2 w-fit cursor-auto text-[#FF8A15]">
            No events for this week
          </p>
        ) : (
          <EventCard data={eventsThisWeekData} />
        )}
      </div>
    </div>
  );
};

export default EventThisWeek;
