import { dummyMyEventsData } from "pages/Ticket/dummydata";
import ActionButton from "../Commons/Button";
import UpActiveTick from "./UpActiveTick";

const ActiveEvent = ({ handleBackClick, events }) => {
  if (events?.length === 0) {
    return (
      <div className="searchEmpty-bbox">
        <div className="empt-box-sea">
          <img src="images/em1.png" alt="" />
          <div className="notin-is mt-4">Nothing here yet!</div>
          <div className="act-notin" onClick={handleBackClick}>
            <ActionButton label={"Create an event"} bg={"pruplr"}/>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="up-com-container">
        <UpActiveTick data={events} />
      </div>
    </>
  );
};

export default ActiveEvent;
