import SellHeader from "components/newCommerce/shared/sellHeader";
import { useEffect, useState } from "react";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { Reportuser } from "api/commerce/Apiactions";
import { UserID } from "api/commerce/Apiactions";
export const ReportItem = () => {
  const [count, setCount] = useState(0);
  const [val, setVal] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchparams = new URLSearchParams(location.search);

  const pid = searchparams.get("id");
  //onchanging text-area
  const handleChange = (e) => {
    setVal(e.target.value);
    // updating-count-words

    setCount(val.length);
  };

  //strictly-updating count state
  useEffect(() => {
    val === "" && setCount(0);
  }, [val]);

  const handleReport = async () => {
    if (val.trim().length < 1) {
      alert("Add Some text");
      return;
    }

    setLoading(true);
    const formdata = {
      product: pid,
      reason: val,
    };

    // send-form-data
    try {
      const response = await Reportuser(formdata);
      response && toast.success(`Report Added Succesfully`);
      setLoading(false);
    } catch (err) {
      err && toast.error(`Failed to Send Report`, err.message);

      setLoading(false);
    } finally {
      setVal("");
    }
  };
  return (
    <div>
      <SellHeader title="Report Item" hideArr={true} hide={false} />
      <div className="px-4 mt-6 pb-4">
        <div className="flex flex-col items-center justify-center mx-auto pt-3 px-[20%] gap-5">
          <div className="relative w-full">
            <textarea
              onChange={handleChange}
              value={val}
              maxLength={500}
              className="bg-[#4f0da308] text-[black] text-[13px] border-none rounded-md px-3 py-4 font-[Ubuntu]  w-[100%] resize-none placeholder:font-[ubuntu] placeholder:text-[#c4c4c4] placeholder:text-[13px]"
              name="review"
              placeholder="Share your experience"
              id="review"
              cols="100"
              rows="8"
            ></textarea>
            <p className="absolute bottom-[0.15rem] text-[13px] text-[#c4c4c4] right-3">{`${count}/500 words`}</p>
          </div>
          <div className="mt-1">
            {/* call-button-to-update fields and send as API */}
            <ButtonSide
              title={loading ? "Sending" : "Report item"}
              bg="#4f0da3"
              cl="#ffff"
              jc="initial"
              styles={{ paddingInline: "5rem" }}
              start={
                loading ? (
                  <AiOutlineLoading3Quarters className="animate-spin mt-[0.5rem] text-[20px] mr-4" />
                ) : null
              }
              isDisabled={loading ? true : false}
              click={handleReport}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Report-sellser

export const Reportseller = () => {
  const [count, setCount] = useState(0);
  const [val, setVal] = useState("");

  const [loading, setLoading] = useState(false);

  //onchanging text-area
  const handleChange = (e) => {
    setVal(e.target.value);
    // updating-count-words

    setCount(val.length);
  };

  //strictly-updating count state
  useEffect(() => {
    val === "" && setCount(0);
  }, [val]);

  const handleReport = async () => {
    if (val.trim().length < 1) {
      alert("Add Some text");
      return;
    }

    setLoading(true);
    const formdata = {
      product: UserID,
      reason: val,
    };

    // send-form-data
    try {
      const response = await Reportuser(formdata);
      response && toast.success(`Report Added Succesfully`);
      setLoading(false);
    } catch (err) {
      err && toast.error(`Failed to Send Report`, err.message);

      setLoading(false);
    } finally {
      setVal("");
    }
  };
  return (
    <div>
      <SellHeader title="Report seller" hideArr={true} hide={false} />
      <div className="px-4 mt-6 pb-4">
        <div className="flex flex-col items-center justify-center mx-auto pt-3 px-[20%] gap-5">
          <div className="relative w-full">
            <textarea
              onChange={handleChange}
              value={val}
              maxLength={500}
              className="bg-[#4f0da308] text-[black] text-[13px] border-none rounded-md px-3 py-4 font-[Ubuntu]  w-[100%] resize-none placeholder:font-[ubuntu] placeholder:text-[#c4c4c4] placeholder:text-[13px]"
              name="review"
              placeholder="Share your experience"
              id="review"
              cols="100"
              rows="8"
            ></textarea>
            <p className="absolute bottom-[0.15rem] text-[13px] text-[#c4c4c4] right-3">{`${count}/500 words`}</p>
          </div>
          <div className="mt-1">
            {/* call-button-to-update fields and send as API */}
            <ButtonSide
              title={loading ? "Sending report" : "Report Seller"}
              bg="#4f0da3"
              cl="#ffff"
              jc="initial"
              styles={{ paddingInline: "5rem" }}
              start={
                loading ? (
                  <AiOutlineLoading3Quarters className="animate-spin mt-[0.5rem] text-[20px] mr-4" />
                ) : null
              }
              isDisabled={loading ? true : false}
              click={handleReport}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
