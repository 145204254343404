import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const EditMovieProfileHeader = ({ header}) => {

    const navigate = useNavigate();
  
  const goBack = () => {
    navigate(-1);
  };

  
  return (
    <div className="px-[15px] py-[10px] flex  gap-4 items-center">
      <button onClick={goBack}>
        <FaArrowLeftLong className="text-[#222222] text-[20px] mb-1" />
      </button>

      <h2 className="title text-[#000] lg:text-[17px] text-[16px]">{header}</h2>

    </div>
  );
};

export default EditMovieProfileHeader;
