import React, { useEffect, useRef, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";
import kuda from "../../assets/profile_images/kuda-ad.png";
import { FaPlay } from "react-icons/fa";

import "./tv.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import MoviesCard from "./MoviesCard";
import TvRightSide from "./TvRightSide";
import { useTv } from "Hooks/tv/useTv";
import ContainerError from "components/ContainerError";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";
import Spin from "components/Spin/Spin";

import Spinner from "components/Spinner";
import TvCardFull from "./TvCardFull";
import { getAllMoviesSearch } from "api/services/tv";
import { useGetWatched } from "Hooks/tv/useGetWatchedMovies";

export function capWord(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const categories = [
  "Action",
  "Comedy",
  "Drama",
  "Horror",
  "Fantasy",
  "Romance",
  "Science-fiction",
  /* "Thriller",
  "Western",
  "Animation",
  "Documentary",
  "Musical", */
];

const Tv = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState([]);
  const [isMoviesLoading, setIsMoviesLoading] = useState(false);

  const { userTvData, userTvDataError } = useTv();

  const isTvData = userTvData?.data?.data?.length;


  useEffect(() => {
    const getSearchMovies = async () => {
      if (searchTerm.trim() === "") {
        setMovies([]);
        return;
      }

      const response = await getAllMoviesSearch(searchTerm);
      setMovies(response?.data?.results);
    };

    getSearchMovies();
  }, [searchTerm]);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const { allMovies, allMoviesError, isAllMoviesLoading } = useGetAllMovies();


  const filterByTag = (movies, tagName) => {
    return movies?.filter((movie) =>
      movie.tags.some((tag) => tag.name === tagName)
    );
  };

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  if (userTvDataError || allMoviesError) {
    return <ContainerError />;
  }

  return (
    <div className="lg:grid lg:grid-cols-12 gap-2 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 mb-4">
        {isAllMoviesLoading ? (
          <Spin />
        ) : (
          <>
            <div className="p-4">
              <div className="flex items-center justify-between mb-3 lg:mb-0">
                <h1
                  className={`lg:mb-3
            ${searchTerm && "text-[18px]"}
            `}
                >
                  {searchTerm ? "All Movies" : "Tv"}
                </h1>
                {isTvData ? (
                  <Link
                    className="text-[12px] bg-[#4f0da3] no-underline rounded text-white py-3 px-4 lg:hidden block"
                    to={`/tv/${userTvData?.data?.data[0]?.id}`}
                  >
                    View channel
                  </Link>
                ) : (
                  <Link
                    className="text-[12px] bg-[#4f0da3] no-underline rounded text-white py-3 px-4 lg:hidden block"
                    to={`/tv/create`}
                  >
                    Create channel
                  </Link>
                )}
              </div>
              <div className="mt-2">
                <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 ">
                  <IoMdSearch className="text-black text-[22px]" />
                  <input
                    type="text"
                    className="text-[14px] border-none outline-none"
                    placeholder="Search for movies"
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {searchTerm ? (
              <div className="ticket-full-box px-4">
                {isMoviesLoading ? (
                  <Spinner />
                ) : !movies.length && searchTerm ? (
                  <div className="text-[14px]">No results found</div>
                ) : (
                  movies?.map((item, index) => (
                    <TvCardFull data={item} key={index} />
                  ))
                )}
              </div>
            ) : (
              <>
                {/* SECOND  */}
                {
                  <div className="w-full">
                    <div className="tv-container">
                      <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={false}
                        loop={false}
                        slidesPerView="auto"
                        coverflowEffect={{
                          rotate: 0,
                          stretch: 0,
                          depth: 100,
                          modifier: 3.5,
                        }}
                        pagination={{
                          el: ".swiper-pagination",
                          clickable: true,
                        }}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                          clickable: false,
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper_container"
                      >
                        {allMovies?.data?.results?.map((item) => (
                          <SwiperSlide key={item?.id}>
                            <div className="flex justify-center items-center">
                              <img
                                src={item?.cover_photo}
                                alt=""
                                className="relative"
                              />
                              <div
                                className="bg-neutral-100  opacity-50 h-[80px] w-[80px] rounded-full absolute flex items-center justify-center"
                                onClick={() =>
                                  navigate(`/tv/movie/${item?.id}`)
                                }
                              >
                                <FaPlay className="text-neutral-800 text-[30px] hover:text-[#4f0da3] transition duration-300" />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                }

                {/* AD */}
                <div className="px-2">
                  <div className="hidden lg:block">
                    <img
                      src={ads}
                      alt="Ads"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>

                {/* POSTER */}
                <div className="w-full h-[380px] mt-3 p-2 relative">
                  <img
                    src={
                      allMovies?.data?.results[
                        allMovies?.data?.results?.length - 1
                      ]?.cover_photo
                    }
                    alt=""
                    className="w-full h-full object-cover hover:text-[#4f0da3] transition duration-300"
                  />
                  <div
                    className="bg-neutral-100 h-[60px] w-[60px] opacity-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
                    onClick={() =>
                      navigate(
                        `/tv/movie/${
                          allMovies?.data?.results[
                            allMovies?.data?.results?.length - 1
                          ]?.id
                        }`
                      )
                    }
                  >
                    <FaPlay className="text-neutral-800 text-[17px]" />
                  </div>

                  <div className="h-[70px] w-[98%] bg-[rgba(0,0,0,0.5)] absolute bottom-2 flex flex-col gap-2 p-2">
                    <div className="text-[18px] text-white">
                      {
                        allMovies?.data?.results[
                          allMovies?.data?.results?.length - 1
                        ]?.title
                      }
                    </div>

                    <div className="flex items-center gap-2 text-[12px] bg-[rgba(128,128,128,0)]">
                      {allMovies?.data?.results[
                        allMovies?.data?.results?.length - 1
                      ]?.tags
                        ?.map((tag) => tag.name)
                        ?.map((item, index) => (
                          <div
                            className="bg-[#FF8A15] py-2 px-4 rounded text-white"
                            key={index}
                          >
                            {item}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {/* Movies list */}
                <div className="p-2 mt-4">
                  <div>
                    <div className="flex justify-between w-full px-2 mt-2">
                      <div className="text-[15px] font-semibold">
                        Top movies
                      </div>
                      <Link
                        className="text-[13px] no-underline"
                        to={`/tv/movies/top`}
                      >
                        See more
                      </Link>
                    </div>
                    <MoviesCard data={allMovies?.data?.results} />
                  </div>
                  <div>
                    <div className="flex justify-between w-full px-2 mt-2">
                      <div className="text-[15px] font-semibold">
                        Recent movies
                      </div>
                      <Link
                        className="text-[13px] no-underline"
                        to={`/tv/movies/recent`}
                      >
                        See more
                      </Link>
                    </div>
                    <MoviesCard
                      data={allMovies?.data?.results?.slice().reverse()}
                    />
                  </div>
                  {categories?.map((item, index) => (
                    <div key={index}>
                      <div className="flex justify-between w-full px-2 mt-2">
                        <div className="text-[15px] font-semibold">
                          {capWord(item)} movies
                        </div>
                        {filterByTag(allMovies?.data?.results, item).length ? (
                          <Link
                            className="text-[13px] no-underline"
                            to={`/tv/movies/${item}`}
                          >
                            See more
                          </Link>
                        ) : null}
                      </div>
                      {filterByTag(allMovies?.data?.results, item).length ? (
                        <MoviesCard
                          data={filterByTag(allMovies?.data?.results, item)}
                        />
                      ) : (
                        <p className="text-[13px] ml-2 mb-4 mt-2 w-fit cursor-auto text-[#FF8A15]">
                          No {item} movies to display
                        </p>
                      )}
                    </div>
                  ))}
                </div>
                <div className="profile_activities_bottom_ads">
                  <img src={kuda} alt="KUDA" />
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="lg:col-span-4 hidden lg:block bg-neutral-100 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default Tv;
