import React, { useEffect, useRef } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

const LiveLayout = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -8 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  return (
    <div ref={pageRef}>
      <Outlet />
    </div>
  );
};

export default LiveLayout;
