import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import TvCardFull from "./TvCardFull";
import TvRightSide from "./TvRightSide";
import { useGetDownloadedMovies } from "Hooks/tv/useGetDownloadedMovies";
import LikedCardFull from "./LikedCardFull";

const DownloadedMovies = () => {
  const navigate = useNavigate();

  const {downloadedMovies} = useGetDownloadedMovies()
  
  const {user} = useParams()

  const allDownloadedMovies = downloadedMovies?.data?.results
  
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState(allDownloadedMovies);
  
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  };

  

  useEffect(() => {
    handleGetRef();
  }, []);


  
  useEffect(() => {
    handleGetRef();
  }, []);

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = allDownloadedMovies?.filter((movie) =>
      movie?.video?.title?.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredMovies(filtered);
  };

  const goToHome = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFilteredMovies(allDownloadedMovies);
  }, [allDownloadedMovies]);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 px-4">
        <div className="flex w-full items-center gap-4 mt-4 px-2">
          <FaArrowLeftLong onClick={goToHome} className="text-[18px]" />
          <h2 className="font-semibold text-[17px] mt-2">Downloaded movies</h2>
        </div>

        <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4 ">
          <BiSearch className="text-black text-[16px]" />
          <input
            type="text"
            className=" text-[14px] border-none outline-none "
            placeholder="search movie"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </div>

        <div className="ticket-full-box">
          {filteredMovies?.map((item, index) => (
            <LikedCardFull data={item} key={index} />
          ))}
        </div>
      </div>

      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default DownloadedMovies;
