import React, { useContext, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'


import { Dialog } from "@mui/material";
import image from "../../assets/images/hotel1.png"
import onebank from "../../assets/onebank.svg"
import hotels1 from "../../assets/images/hotel (1).png"
import HotelCard from 'components/Hotels/HotelCard'
import ShareHotel from 'components/Modals/Hotels/ShareHotel';
import { HotelsContext } from 'Context/HotelsContext';
import { FaShare } from 'react-icons/fa6';
import PromoteHotel from 'components/Modals/Hotels/PromoteHotel';
import { MdReport } from 'react-icons/md';
import { BiCartAdd, BiShareAlt } from 'react-icons/bi';
import ReportHotel from 'components/Modals/Hotels/ReportHotel';
import AboutHotel from 'components/Modals/Hotels/AboutHotel';

const SingleHotel = () => {
  const [showAboutModal, setShowAboutModal] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showPromoteModal, setShowPromoteModal] = useState(false)
  const [hotel, setHotel] = useState({})

  const {
    hotels, fetchHotels, setTriggerFunction
  } = useContext(HotelsContext);

  useEffect(() => {
    fetchHotels()

    let hotelData = localStorage.getItem('hotel')
    hotelData = JSON.parse(hotelData)

    setHotel(hotelData)
    console.log(hotelData)

  }, [])


  const features = ['Mountain view', 'Swimming pool', 'Indoor games']





  return (
    <div>
      <h2 className="flex items-center mt-5"><Link to={'/hotels'}><FaArrowLeft /></Link> &emsp; Hotel Information</h2>

      <div className="bg-white mt-5 mb-5 p-4">

        <div className="container mx-auto p-6">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div className="md:col-span-2 p-4">
              <img src={hotels1} alt="" class="w-full rounded-lg" />

              <button className="hotel-gray-btn" onClick={() => setShowShareModal(true)}><BiShareAlt />&nbsp; Share product </button>

              <button className="hotel-gray-btn" onClick={() => setShowReportModal(true)}><MdReport />&nbsp; Report this item </button>

              <button className="hotel-gray-btn" onClick={() => setShowPromoteModal(true)}><FaShare />&nbsp; Promote </button>
            </div>
            <div className="md:col-span-3 p-4">
              <h1>{hotel?.name}
              </h1>
              <p>Room in {hotel?.location}</p>

              <p style={{ color: "#FF8A15" }}>1 room - Private attached bathroom</p>

              <div className="flex items-center justify-between">

                <span>{hotel?.price ? hotel?.price : 'price'}</span>

                <button className="hotel-btn gap-2"><BiCartAdd /> RESERVE NOW</button>
              </div>


              <h2 className='mt-5'>About this place</h2>
              <p>{hotel?.description}</p>

              <p style={{ float: "right" }}><span className='purple' onClick={() => setShowAboutModal(true)}>View more {'>>'}</span></p>

              <h2 className='mt-12'>Features and Facilities</h2>

              <div>

              </div>


              <div className='ads mt-8'>
                <img src={onebank} alt="" />
              </div>

              <h2 className="mt-5">Similar Hotels</h2>

              <div className="flex gap-6 overflow-auto mt-5">
                {hotels && hotels.length > 0 ? (
                  hotels.map((hotel) => (
                    <HotelCard key={hotel.id} data={hotel} />
                  ))
                ) : (
                  <p className="col-span-full">No hotels available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showReportModal}
        onClose={() => setShowReportModal((prev) => !prev)}

      >
        <ReportHotel
          onClose={() => setShowReportModal((prev) => !prev)}
          fetchHotels={() => setTriggerFunction(true)}
          id={hotel?.id}
        />
      </Dialog>


      <Dialog
        open={showShareModal}
        onClose={() => setShowShareModal((prev) => !prev)}

      >
        <ShareHotel
          onClose={() => setShowShareModal((prev) => !prev)}
          fetchHotels={() => setTriggerFunction(true)}

        />
      </Dialog>

      <Dialog
        open={showPromoteModal}
        onClose={() => setShowPromoteModal((prev) => !prev)}

      >
        <PromoteHotel
          onClose={() => setShowPromoteModal((prev) => !prev)}
          id={hotel?.id}
        />
      </Dialog>


      {/* About modal */}
      <Dialog
        open={showAboutModal}
        onClose={() => setShowAboutModal((prev) => !prev)}

      >
        <AboutHotel
          onClose={() => setShowAboutModal((prev) => !prev)}
          content={hotel?.description}

        />
      </Dialog>


    </div>
  )
}

export default SingleHotel