import { useEffect } from "react";
import TicketSearchComp from "../../components/TicketComp/TicketSearchComp";
import HistoryCol from "../../components/TicketComp/HistoryCol";
import { useNavigate } from "react-router-dom";
import useTicketPayout from "Hooks/useTicketPayout";
import Spin from "components/Spin/Spin";
const PayoutHistory = () => {
  const navigate = useNavigate();

  const {
    payoutHistory,
    isPayoutHistoryLoading,
    refetchPayoutHistory,
    isPayoutHistoryRefetching,
  } = useTicketPayout();

  const payout = payoutHistory?.data?.data;

  const handleHistoryClose = () => {
    navigate("/ticket/sell-ticket/payout");
  };

  useEffect(() => {
    refetchPayoutHistory();
  }, [refetchPayoutHistory]);

  return (
    <div className="p-4">
      <TicketSearchComp
        label={"Payment history"}
        handleCloseContainerClick={handleHistoryClose}
        add={"add"}
      />

      {/* <div className="payout-container">
        {payout?.length ? <div className="flex items-center cursor-pointer py-1 px-4 text-neutral-400 border mb-4">
          <GoFilter className="text-[20px] text-neutral-500 rounded" />
          <select
            name=""
            id=""
            className="border-none bg-white cursor-pointer text-neutral-400 text-[14px] outline-none"
          >
            <option value="">Jan</option>
            <option value="">Feb</option>
            <option value="">March</option>
          </select>
        </div> : null}
      </div> */}

      <div className="row-with-hist-card mt-4">
        {isPayoutHistoryLoading || isPayoutHistoryRefetching ? (
          <Spin />
        ) : !payout?.length ? (
          <h4 className="ml-4 mt-4">You have not made any withdrawals</h4>
        ) : (
          payout
            ?.reverse()
            .map((item) => (
              <HistoryCol
                amount={item.amount}
                status={item.transaction_status}
                date={item.date}
              />
            ))
        )}
      </div>
    </div>
  );
};

export default PayoutHistory;
