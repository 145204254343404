import React from "react";
import { FaHeart } from "react-icons/fa";
import lady from "../../assets/live_images/girl-cook.jpg";
import girl from "../../assets/live_images/light-girl.jpg";


const LiveVideoCard = () => {
  return (
    <div className="grid grid-rows-8 h-[380px] mt-12 rounded border relative">
      <div className="row-span-6 bg-neutral-300">
      <img src={lady} alt="" className="w-full h-full object-cover" />
        <div className="w-full px-4 absolute top-2 flex justify-between items-center">
          <div className="py-2 px-4 bg-red-500 rounded">Live</div>
          <div className="border-2 border-neutral-500 h-12 w-12 flex items-center justify-center rounded-full p-2">
            <FaHeart className="text-[17px] text-black" />
          </div>
        </div>
      </div>
      <div className="row-span-2 flex items-center p-4 gap-4">
        <div>
          <div className="h-14 w-14 bg-red-500 rounded-full relative z-10">
            <div className="rounded-full absolute top-1 h-14 w-14 -z-10">
            <img src={girl} alt="" className="w-full h-full object-cover rounded-full"/>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
            <span className="text-black font-semibold text-[14px]">Tips for cooking under pressure</span>
            <span className="text-black text-[12px]">21k watching</span>
        </div>
      </div>
    </div>
  );
};

export default LiveVideoCard;
