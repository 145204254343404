import ActionButton from "../../Commons/Button";
import { Link } from "react-router-dom";

const SuccessPoll = () => {
  return (
    <div className="detail-bx-container bagw">
      <div className="searchEmpty-bbox ddwn">
        <div className="empt-box-sea">
          <img src="images/em2.png" alt="" />
          <div className="notin-is">Your payment has been confirmed</div>

          <div className="choose-act-btn">
            <Link to='/Voting'>
              <ActionButton label={"continue to polls"} bg={"wid"} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPoll;
