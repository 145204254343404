import PostJobModal from 'components/Modals/Post-form-modals/PostJobModal'
import PostFormModal from '../../Modals/Post-form-modals/PostFormModal'
import { useState } from 'react'

const PostFeedFormCont = ({
  hdClose,
  isModalOpen,
  selectedIcon,
  handleIconClick,
}) => {
  const [showJobModal, setShowJobModal] = useState(false)

  const handleJobIconClick = () => {
    setShowJobModal(true)
    hdClose()
  }
  const handleCloseJob = () => {
    hdClose()
    setShowJobModal(false)
  }
  return (
    <>
      {isModalOpen && (
        <div className='modal-full-container'>
          <PostFormModal
            handleCloseMainContainerClick={hdClose}
            selectedIcon={selectedIcon}
            handleIconClick={handleIconClick}
            handleJobIconClickProp={handleJobIconClick} // Updated prop name
          />
        </div>
      )}

      {showJobModal && (
        <div className='modal-full-container'>
          <PostJobModal
            handleCloseMainContainerClick={handleCloseJob}
            // handleCloseMainContainerClick={() => setShowJobModal(false)}
            // selectedIcon={selectedIcon}
            handleIconClick={handleJobIconClick}
          />
        </div>
      )}
    </>
  )
}

export default PostFeedFormCont
