// import React, { useEffect } from "react";

// function AdSenseAd() {
//   useEffect(() => {
//     // Dynamically load the AdSense script if not already loaded
//     if (
//       !document.querySelector(
//         'script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]'
//       )
//     ) {
//       const script = document.createElement("script");
//       script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
//       script.async = true;
//       document.body.appendChild(script);
//     }

//     try {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     } catch (e) {
//       console.error("AdSense error:", e);
//     }
//   }, []);

//   return (
//     <div className="w-full object-cover px-12 lg:px-0 bg-yellow-100 mb-8 mt-2">
//       <ins
//         className="adsbygoogle"
//         style={{ display: "block", height: "120px", objectFit: "cover" }}
//         data-ad-client="ca-pub-9398703909928104"
//         data-ad-slot="1234567890"
//         data-ad-format="auto"
//         data-full-width-responsive="true"
//       ></ins>
//     </div>
//   );
// }

// export default AdSenseAd;

// import React, { useEffect } from "react";

// function AdSenseAd() {
//   useEffect(() => {
//     // Ensure AdSense script is loaded only once
//     if (
//       !document.querySelector(
//         'script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]'
//       )
//     ) {
//       const script = document.createElement("script");
//       script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
//       script.async = true;
//       document.body.appendChild(script);
//     }

//     // Safeguard adsbygoogle initialization
//     if (window.adsbygoogle && typeof window.adsbygoogle.push === "function") {
//       try {
//         window.adsbygoogle.push({});
//       } catch (e) {
//         console.error("AdSense error:", e);
//       }
//     }
//   }, []);

//   return (
//     <div className="w-full object-cover px-12 lg:px-0 bg-yellow-100 mb-8 mt-2">
//       <ins
//         className="adsbygoogle"
//         style={{ display: "block", height: "100px", objectFit: "cover" }}
//         data-ad-client="ca-pub-9398703909928104"
//         data-ad-slot="1234567890"
//         data-ad-format="auto"
//         data-full-width-responsive="true"
//       ></ins>
//     </div>
//   );
// }

// export default AdSenseAd;

import React, { useEffect } from "react";

function AdSenseAd() {
// function AdSenseAd({ height = "h-[10px]" }) {
  // Default Tailwind height
  useEffect(() => {
    // Ensure AdSense script is loaded only once
    if (
      !document.querySelector(
        'script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]'
      )
    ) {
      const script = document.createElement("script");
      script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      document.body.appendChild(script);
    }

    // Safeguard adsbygoogle initialization
    if (window.adsbygoogle && typeof window.adsbygoogle.push === "function") {
      try {
        window.adsbygoogle.push({});
      } catch (e) {
        console.error("AdSense error:", e);
      }
    }
  }, []);

  return (
    <div
      className={`w-full h-48 object-cover mb-8 mt-2`}
      // className={`w-full h-5 object-cover px- lg:px-0 bg-yellow-100 mb-8 mt-2`}
    >
      <ins
        className="adsbygoogle w-full h-full object-cover"
        data-ad-client="ca-pub-9398703909928104"
        data-ad-slot="1234567890"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}

export default AdSenseAd;
