import React, { useEffect, useRef, useState } from 'react'
import { useFetchMusic } from 'api/services/feeds'
import './Feeds-Music.css'
import Lottie from 'lottie-react'

import 'react-h5-audio-player/lib/styles.css' // Default styles, which you can override
import HitsCard from 'pages/Stereo/Components/HitsCard'
import AudioPlayer2 from 'pages/Stereo/AudioPlayer2/AudioPlayer'
import NothingHere from '../../../../pages/Stereo/Assets/nothing_here.json'

const FeedsMusic = () => {
  const { data: getMusic, isPending } = useFetchMusic()

  const [songs, setSongs] = useState([])
  const [clickedIndex2, setClickedIndex2] = useState(null) // Initialize with null
  // const [currentSong2, setCurrentSong2] = useState(songs[clickedIndex2])
  const [modalOpen1, setModalOpen1] = useState(false)
  const [activePlayer, setActivePlayer] = useState(null)
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null)
  const [minimized, setMinimized] = useState(false)
  // const audioRef = useRef(null)

  const GetSongs = () => {
    setSongs(getMusic)
  }

  useEffect(() => {
    GetSongs()
  }, [])

  const handleItemClick2 = (index) => {
    // console.log('Clicked index:', index)
    // setCurrentSong(quickpicks[clickedIndex]);
    setClickedIndex2(index) // Set the clicked index to state
  }

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }

  const handlePlayPause = (id) => {
    if (id === currentlyPlaying) {
      setCurrentlyPlaying(null)
    } else {
      setCurrentlyPlaying(id)
    }
  }

 
  return (
    <>
      {songs?.length > 0 ? (
        songs.map((res, index) => {
          console.log("music", res);
          return (
            <div key={index} className="grid grid-cols-1">
              <audio src={res.file} controls></audio>
            </div>
          );
        })
      ) : (
        <p>No audio files yet</p>
      )}
    </>
  );

  // return (
  //   <>
  //     <div>
  //       <div
  //         className={
  //           songs?.length > 0
  //             ? 'flex mx-3 gap-3 overflow-x-scroll justify-between h-auto'
  //             : null
  //         }
  //         style={{
  //           scrollbarWidth: 'none',
  //           height: '100%',
  //         }}
  //       >
          
          
  //         {songs?.length > 0 ? (
  //           songs?.map((res, index) => {
  //             return (
  //               <div key={res?.id} className=''>
  //                 <HitsCard
  //                   onClick={() => handleItemClick2(index)}
  //                   setModalOpen1={setModalOpen1}
  //                   modalOpen1={modalOpen1}
  //                   name={res?.title}
  //                   img={res?.cover_image ? res?.cover_image : null}
  //                   artist={res?.artist?.artist_name}
  //                   audio={res?.file ? res?.file : null}
  //                   id={res?.file_id}
  //                   activePlayer={activePlayer}
  //                   setActivePlayer={setActivePlayer}
  //                   handlePlayPause={handlePlayPause}
  //                 />
  //               </div>
  //             )
  //           })
  //         ) : (
  //           <div className='flex justify-center items-center'>
  //             <Lottie
  //               animationData={NothingHere}
  //               style={{
  //                 width: '263.38px',
  //                 height: '100%',
  //               }}
  //             />
  //           </div>
  //         )}
  //       </div>
  //       {modalOpen1 && (
  //         <div
  //           id='modelConfirm'
  //           className={
  //             !minimized
  //               ? 'fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4'
  //               : ''
  //           }
  //         >
  //           <div
  //             className={
  //               !minimized
  //                 ? 'relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden'
  //                 : 'fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200'
  //             }
  //           >
  //             <main className='py-10 my-10'>
  //               <AudioPlayer2
  //                 minimized1={minimized}
  //                 setMinimized1={setMinimized}
  //                 setModalOpen1={setModalOpen1}
  //                 modalOpen1={modalOpen1}
  //                 tracks={songs}
  //                 index={clickedIndex2}
  //               />
  //             </main>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   </>
  // )
}

export default FeedsMusic
