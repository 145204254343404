import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useState, useContext } from "react";
import { ModalContext } from "Context/ModalContext";
import { Link, useNavigate } from "react-router-dom";
import Stacked from "../shared/Stacked";
import Description from "../typography/txtDescription";
import Pic from "../shared/ImageBordered";
import Header from "../typography/txtHeader";
import { ButtonSide } from "../shared/sideButton";
import { Absolutes } from "../shared/Absolutes";
import { Addtocart, getCarts } from "api/commerce/Apiactions";
import { FormatwithComma } from "utils/commerceUtils";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const Template = ({ content, hidebuy }) => {
  const navigate = useNavigate();
  const {
    // fetchItemid,
    // isOpen,
    // setModalContent,
    // openModal,
    // nextCartPopup,
    contentType,
  } = useContext(ModalContext);

  const [cartItems, setCartItems] = useState([]);
  // but-now-loading
  const [loading, setLoading] = useState(new Array(content.length).fill(false));

  useEffect(() => {
    async function fetch() {
      try {
        const cartResponse = await getCarts();
        setCartItems(cartResponse);
      } catch (err) {
        console.log(err);
      }
    }

    fetch();
  }, []);

  const isProductInCart = (productId) => {
    return cartItems.some((cartItem) => cartItem.product.id === productId);
  };

  const handleBuy = async (payload, target) => {
    const {
      name,
      description,
      price,
      inventory,
      category,
      variations,
      location,
      sold,
      id,
    } = payload;
    const formData = {
      product: {
        name,
        description,
        price,
        inventory,
        category,
        variations,
        location,
        sold,
        id,
      },
      quantity: 1,
    };
    if (isProductInCart(id)) {
      navigate("/commerce/cart");
    } else {
      setLoading((prev) => {
        const getstates = [...prev];
        getstates[target] = true;
        return getstates;
      });
      try {
        const response = await Addtocart(formData);
        if (response) {
          toast.success("Checkout Ready");
          setTimeout(() => {
            navigate("/commerce/cart");
          }, 350);
        }
      } catch (err) {
        console.error(err);
        err && toast.error("unknown error occoured");
      } finally {
        // setLoading((prev) => prev.map(() => false));
        setLoading((prev) => {
          const getstates = [...prev];
          getstates[target] = false;
          return getstates;
        });
      }
    }
  };
  return (
    <React.Fragment>
      {content.map((content, index) => {
        return (
          <Box key={content.id} className="sub_gridcommerce">
            <Stacked d="column" g=".4rem">
              <Box sx={{ position: "relative" }}>
                <Link
                  to={`/commerce/product/${
                    content.product_name || content.item_similar || content.name
                  }?id=${content.id}`}
                >
                  <Pic
                    look={`${
                      content.product_img ||
                      content.similar_img ||
                      content.product_image
                    }`}
                    alt={`product${
                      content.product_name ||
                      content.item_similar ||
                      content.name
                    }`}
                    styles={{
                      width: "100%",
                      aspectRatio: "1 / 1.2",
                      maxWidth: `${
                        contentType === "promoteitem" ? "200px" : ""
                      }`,
                    }}
                  />
                </Link>
                <Absolutes
                  item={content}
                  inCart={isProductInCart(content.id)}
                />
              </Box>
              <Description
                title={`${
                  content.product_name || content.item_similar || content.name
                }`}
                sx={{
                  textAlign: "left !important",
                  paddingLeft: "10px",
                  fontSize: "15px",
                  color: "#959393",
                }}
              />
              <Header
                title={FormatwithComma(content.price)
                  .toString()
                  .replace(/^/, "₦")}
                sx={{
                  textAlign: "left !important",
                  paddingLeft: "10px",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              />
              {!hidebuy ? (
                <ButtonSide
                  bg="
                  #4F0DA3"
                  title="Buy Now"
                  styles={{
                    justifyContent: "center",
                    fontSize: "16px",
                    fontWeight: "400",
                    paddingBlock: ".4rem",
                  }}
                  start={
                    loading[index] ? (
                      <AiOutlineLoading3Quarters className="animate-spin mt-[0.rem] text-[20px] mr-4 stroke-[1.9rem]" />
                    ) : null
                  }
                  isDisabled={loading[index] ? true : false}
                  // suspend-for-now
                  click={() => handleBuy(content, index)}
                />
              ) : null}
            </Stacked>
          </Box>
        );
      })}
    </React.Fragment>
  );
};

Template.propTypes = {
  content: PropTypes.array.isRequired,
};
export default Template;
