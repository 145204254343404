import { useQuery } from "@tanstack/react-query";
import { getCommentsFn } from "api/services/tv";

export const useGetComments = (id) => {
  const {
    isLoading: isGetCommentsLoading,
    data: comments,
    status: commentsStatus,
    error: commentsError,
  } = useQuery({
    queryKey: ["comments", id],
    queryFn: () => getCommentsFn(id),
    refetchOnWindowFocus: false,
    refetchOnMount: true, 
    refetchOnReconnect: false,
  });

  return {
    isGetCommentsLoading,
    comments,
    commentsStatus,
    commentsError,
  };
};
