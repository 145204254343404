import { FaHeart, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MoviesCardItem from "./MoviesCardItem";

function limitString(str, limit = 20) {
  if (str.length > limit) {
    return `${str.substring(0, limit)}...`;
  }
  return str;
}

const MovieCard = ({ data }) => {
  console.log('movieCard', data)
  const navigate = useNavigate()
  return (
    <div className="p-2 grid gap-4 grid-flow-col lg:auto-cols-[60%] md:auto-cols-[70%] auto-cols-[80%] overflow-x-scroll overscroll-contain">
      {data?.map((item) => (
        <MoviesCardItem key={item?.id} item={item}/>
      ))}
    </div>
  );
};

export default MovieCard;
