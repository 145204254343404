import React, { useState, useContext } from "react";
import "../Vote/CreatePoll/CreatePoll.css";
import toast from "react-hot-toast";
import { CreateHotelApi } from "api/services/hotels";
import { FaTimes } from "react-icons/fa";
import image from "../../../assets/share.svg"

const AboutHotel = ({ onClose, content }) => {

    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="form-wrapper hotel-small-modal about-modal" style={{ padding: "0" }}>
            <div
                className="createTop"
                style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "20px",
                    justifyContent: "space-between",
                    boxShadow: "10px 10px 20px lightgray",
                    padding: "10px"
                }}
            >   <h2>About this place</h2>
                <div className="hotel-close-btn"><FaTimes onClick={onClose} /></div>
            </div>

            <p style={{ padding: "20px"}}>{content}</p>
        </div>
    );                                                                                                                                                                                                                                                                                                                                                         
};

export default AboutHotel;
