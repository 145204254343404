import React from "react";
import Container from "components/newCommerce/layout/container";
import Search from "./Search";
const SearchProduct = () => {
  return (
    <>
      <Container main={<Search />} />
    </>
  );
};

export default SearchProduct;
