import React, { useState } from "react";
import { IoMdSearch } from "react-icons/io";
import MovieCard from "./MovieCard";
import { libraryMovies } from "./Tv";
import { HiDownload } from "react-icons/hi";
import { FaCheckCircle, FaHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useTv } from "Hooks/tv/useTv";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";
import ContainerError from "components/ContainerError";
import { useGetWatched } from "Hooks/tv/useGetWatchedMovies";
import LikedMoviesCard from "./LikedMoviesCard";
import SpinnerBlack from "components/ProfileComponents/SpinnerBlack";
import { useGetDownloadedMovies } from "Hooks/tv/useGetDownloadedMovies";

const TvRightSide = ({ user, noUpload }) => {
  //const [libraryTerm, setLibraryTerm] = useState("");

  const { userTvData, userTvDataError, userTvDataStatus } = useTv();
  const {allMovies, isAllMoviesLoading, allMoviesError} = useGetAllMovies()
  const {watchedMovies, isWatchedMoviesLoading, watchedMoviesError} = useGetWatched()
  const {downloadedMovies, isDownloadedMoviesLoading, downloadedMoviesError} = useGetDownloadedMovies()

  
  //const userTv = userTvData?.data?.data[0];

  const isTvData = userTvData?.data?.data?.length;

  const navigate = useNavigate();


  if (userTvDataError || allMoviesError) {
    return
  }

  return (
    <div className="px-2 pb-4 pt-2  bg-white">
      {/* <h4>Your Library</h4> */}
      <div>
        {/* <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 ">
          <IoMdSearch className="text-black text-[22px]" />
          <input
            type="text"
            className="text-[14px] border-none outline-none"
            placeholder="Find in library"
            value={libraryTerm}
            onChange={(e) => setLibraryTerm(e.target.value)}
          />
          <button className="bg-[#4f0da3] py-2 px-4 rounded text-white text-[12px]">
            Search
          </button>
        </div> */}
      </div>

      <div  className="mt-2">
        <Link
          className="text-[15px] font-semibold text-neutral-800 no-underline"
          to={`/tv/history-movies`}
        >
          Your history
        </Link>
        {watchedMovies?.data?.results?.length ? (
          <LikedMoviesCard data={watchedMovies?.data?.results?.slice()?.reverse()} history />
        ) : (
          <div style={{borderBottom: "1px solid #f5f5f5"}} className="text-[12px] mt-2 mb-4 h-[60px] text-[#FF8A15]"> {isWatchedMoviesLoading ? <SpinnerBlack /> : "No history available"}</div>
        )}
      </div>

      {/* UNDER */}
      <div className="bg-white">
        <div className="w-full p-2 ">
          <div  className="flex gap-2 w-full items-center justify-between">
            <div className="flex items-center gap-4">
              <div>
                <HiDownload className="text-[#4f0da3] text-[18px]" />
              </div>
              <Link
                className="text-[14px] text-neutral-800 no-underline"
                to={`/tv/downloads`}
              >
                <div className="flex flex-col gap-2">
                  <div className="text-[12px]">Downloads</div>
                  <div className="text-[12px]">{downloadedMovies?.data?.results?.length}</div>
                </div>
              </Link>
            </div>
            <div>
              <FaCheckCircle className="text-[#4f0da3] text-[18px]" />
            </div>
          </div>
        </div>

        <div className="flex p-2 gap-4 mt-2">
          <FaHeart className="text-[#4f0da3] text-[18px]" />
          <Link
            className="text-[14px] text-neutral-800 no-underline"
            to={`/tv/liked`}
          >
            <div className="text-[12px]">Liked movies</div>
          </Link>
        </div>

        {isTvData ? (
          <div>
            {user ? null : (
              <div className="bg-neutral-100 h-2 w-full mt-4"></div>
            )}
            {/* SECOND UNDER */}
            {user ? null : (
              <div className="p-2 mt-4">
                {/*  Header*/}
                <div>
                  <h4>Your Channel</h4>
                </div>
                {/* circle */}
                {userTvData?.data?.data[0]?.profile_photo ? (
                  <div className="flex justify-center flex-col items-center my-12 gap-4">
                    <div className="h-[100px] w-[100px] rounded-full flex justify-center items-center">
                      <img
                        src={userTvData?.data?.data[0]?.profile_photo}
                        alt=""
                        className="h-full w-full rounded-full object-cover"
                      />
                    </div>
                    <div className="text-[18px]">
                      {userTvData?.data?.data[0]?.name}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center flex-col items-center my-12 gap-4">
                    <div className="h-[100px] w-[100px] rounded-full bg-[#4f0da3] flex justify-center items-center">
                      <div className="text-white text-[28px]">
                        {userTvData?.data?.data[0]?.name[0]?.toUpperCase()}
                      </div>
                    </div>
                    <div className="text-[18px]">
                      {userTvData?.data?.data[0]?.name}
                    </div>
                  </div>
                )}
                {/* card */}
                <div className="mt-4 p-2 py-8 bg-neutral-200">
                  <div className="flex justify-around text-[16px] text-neutral-800 items-center">
                    <div className="flex flex-col gap-2 items-center">
                      <div className="text-[26px]">
                        {userTvData?.data?.data[0]?.subscribers?.length}
                      </div>
                      <div className=" text-[16px]">Subscribers</div>
                    </div>
                    <div
                      className="flex flex-col gap-2 items-center cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/tv/${userTvData?.data?.data?.[0]?.id}/movies`
                        )
                      }
                    >
                      <div className="text-[26px] ">
                        {userTvData?.data?.data[0]?.videos}
                      </div>
                      <div className=" text-[16px]">Videos</div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-4">
                    <Link
                      className=" text-[15px]"
                      to={`/tv/${userTvData?.data?.data[0]?.id}`}
                    >
                      View more
                    </Link>
                  </div>
                </div>
                {/* upload */}
                {!noUpload && (
                  <div className="flex flex-col items-center gap-2 mt-12">
                    <div className="text-[16px]">Upload Movie</div>
                    <div className="text-[12px] text-center">
                      Enhance your content by uploading movies now. Engage,
                      diversify, and attract a wider audience for greater
                      success
                    </div>
                    <div className="w-[50%]">
                      <button
                        className="text-white bg-[#4f0da3] text-[15px] p-2 w-full rounded"
                        onClick={() => navigate("/tv/upload")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-4">
            <button
              className="text-white bg-[#4f0da3] text-[15px] px-2 py-3 w-full rounded"
              onClick={() => navigate("/tv/create")}
            >
              Create channel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TvRightSide;
