import { useGetAllAccounts } from "Hooks/profile/useGetAllAccounts";
import { useGetPublicProfile } from "Hooks/profile/useGetPublicProfile";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";
import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";
import { useNavigate, useParams } from "react-router-dom";
import { noUser } from "utils/noUser";
import InnerChat from "./InnerChat";
import { CiFaceSmile } from "react-icons/ci";
import { BsSend } from "react-icons/bs";
import { useProfile } from "Hooks/profile/useProfile";
import useSendDirectMessage from "Hooks/chat/useSendDirectMessage";
import { useGetConversation } from "Hooks/chat/useGetConversation";

const DirectChat = () => {
  const { id } = useParams();
  const { allAccounts, isAllAccountsLoading, allAccountsError } =
    useGetAllAccounts();

  const { profileData, isProfileDataLoading, profileDataError } = useProfile();

  const { conversation, isConversationLoading } = useGetConversation();

  const { sendDirectMessage, sendDirectMessageStatus } = useSendDirectMessage();

  const user = allAccounts?.data?.data?.find(
    // eslint-disable-next-line eqeqeq
    (item) => item?.user?.id == id
  );

  const myID = profileData?.data?.data?.user?.id;
  //const myInfo2 = profileData?.data?.data?.id

  const [text, setText] = useState("");

  const pageReff = useRef(null);

  const handleGetRef = () => {
    if (pageReff && pageReff.current) {
      pageReff.current.scrollIntoView({ bottom: 0 });
    }
  };

  const handleSendMessage = async(e) => {
    e.preventDefault()
    const formData = { recipient_id: id, text, message_type: "direct_message" };
    sendDirectMessage(formData);
    setText("");
  };

  useEffect(() => {
    handleGetRef();
  }, [conversation]);

  const isActive = false;

  const navigate = useNavigate();
  return (
    <div className="bg-neutral-100 px-3 py-3">
      <div className=" flex items-center gap-3 p-2 shadow-sm bg-white h-[60px]">
        <FaArrowLeft
          className="text-[16px] ml-2 cursor-pointer"
          onClick={() => navigate("/chat")}
        />
        <div className=" flex items-center justify-between mt-2 w-full">
          <div className="flex items-center gap-3 py-3 ml-4">
            <div className=" h-[37px] rounded-full flex items-center justify-center w-[37px]">
              <img
                src={user?.profile_picture ? user?.profile_picture : noUser}
                alt="user"
                className="w-[100%] rounded-full h-[100%] object-cover"
              />
            </div>

            <div className="">
              {!user?.user?.first_name || !user?.user?.last_name ? (
                <span className="text-[14px] text-neutral-600 font-semibold">
                  {user?.user?.username}
                </span>
              ) : (
                <span className="text-[14px] text-neutral-600 font-semibold">
                  {user?.user?.first_name} {user?.user?.last_name}
                </span>
              )}

              {/* <div className="text-neutral-500 flex items-center gap-2 font-normal text-[13px]">
                <div className="bg-green-500 h-2 w-2 rounded-full mb-2"></div>
                <h3 className="font-light text-[11px]">Active now</h3>
              </div> */}
            </div>
          </div>
          <div className="">
            <TbDotsVertical className="text-[16px]" />
          </div>
        </div>
      </div>
      {/* <div className="">
        <p className="text-center text-purple-600">Yesterday</p>
      </div> */}
      <InnerChat userID={myID} ID={id} data={conversation?.data} />
      <div className=" row-span-1  w-[100%] bottom-0 border shadow-sm z-20">
        <form className="w-full border-none bg-neutral-100 rounded flex items-center py-2 px-4" onSubmit={handleSendMessage}>
          <CiFaceSmile className="text-black text-[24px]" />
          <input
            type="text"
            className="text-[14px] border-none outline-none"
            placeholder="Send a message"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button className="bg-rose-500 h-12 w-12 p-2 rounded-full flex justify-center items-center hover:opacity-70">
            <BsSend
              className="text-[18px] text-white "
              type="submit"
            />
          </button>
        </form>
      </div>
        {/* Banner ad */}
      <div className="hidden mt-2 lg:block">
        <img src={ads} alt="Ads" className="w-full h-full object-contain" />
      </div>
    </div>
  );
};

export default DirectChat;
