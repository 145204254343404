import React, { useState, useRef, useEffect } from "react";
import { useFetchVoiceNote } from "api/services/feeds";
import { FiPlay, FiPause } from "react-icons/fi";
import EmptyImage from "../../../../assets/2gede_nothing_here.svg";

const FeedsVoice = () => {
  const { data: fetchVoicenote, isPending } = useFetchVoiceNote();
  const [currentAudioId, setCurrentAudioId] = useState(null); // Track the currently playing audio
  const audioRefs = useRef({}); // Store references to all audio elements

  const togglePlay = (fileId) => {
    const currentAudio = audioRefs.current[fileId];

    // Ensure the reference exists
    if (!currentAudio) {
      console.warn(`Audio reference for fileId ${fileId} not found.`);
      return;
    }

    if (currentAudioId === fileId) {
      // Pause the current audio
      currentAudio.pause();
      setCurrentAudioId(null);
    } else {
      // Pause all other audios
      Object.values(audioRefs.current).forEach((audio) => audio?.pause());
      // Play the selected audio
      currentAudio.play();
      setCurrentAudioId(fileId);
    }
  };

  useEffect(() => {
    // Cleanup: Stop all audios when the component unmounts
    return () => {
      Object.values(audioRefs.current).forEach((audio) => audio?.pause());
    };
  }, []);

  if (isPending) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-gray-500 text-lg">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-6">
      {fetchVoicenote?.length === 0 && (
        <div className="text-center">
          <h1 className="text-2xl text-gray-600 mb-4">
            No Voice Notes Available
          </h1>
          <img
            src={EmptyImage}
            alt="Nothing here"
            className="w-40 mx-auto opacity-80"
          />
        </div>
      )}

      {fetchVoicenote?.map((item) => (
        <div
          key={item?.file_id}
          className="flex items-center space-x-4 p-4 bg-gray-100 rounded-lg shadow-md"
        >
          {/* Play/Pause Button */}
          <button
            onClick={() => togglePlay(item?.file_id)}
            className={`flex items-center justify-center w-12 h-12 rounded-full shadow-md transition ${
              currentAudioId === item?.file_id
                ? "bg-red-500 hover:bg-red-600"
                : "bg-purple-500 hover:bg-purple-600"
            } text-white`}
          >
            {currentAudioId === item?.file_id ? (
              <FiPause size={20} />
            ) : (
              <FiPlay size={20} />
            )}
          </button>

          {/* Waveform */}
          <div className="flex flex-grow space-x-1 h-10 items-center">
            {Array.from({ length: 40 }).map((_, index) => (
              <div
                key={index}
                className={`flex-grow bg-purple-500 rounded ${
                  currentAudioId === item?.file_id ? "animate-wave" : ""
                }`}
                style={{
                  width: "0.25rem",
                  height: `${Math.random() * (40 - 20) + 20}px`, // Random heights for bars
                  animationDelay: `${index * 0.05}s`,
                }}
              ></div>
            ))}
          </div>

          {/* Hidden Audio Element */}
          <audio
            ref={(el) => {
              if (el) {
                audioRefs.current[item?.file_id] = el;
              }
            }}
            src={item?.file}
            preload="metadata"
          />
        </div>
      ))}
    </div>
  );
};

export default FeedsVoice;
