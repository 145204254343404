import { useRef, useState, useEffect } from "react";

import TvRightSide from "./TvRightSide";
import EditMovieProfileHeader from "./EditMovieProfileHeader";
import workers from "../../assets/tv_images/workers.svg";

const TvMonetization = () => {
  const [cover, setCover] = useState(null);
  const [image, setImage] = useState(null);
  const [tempCover, setTempCover] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [data, setData] = useState({
    name: "",
    link: "",
    cover_image: null,
    profile_picture: null,
  });

  
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  /* useEffect(() => {
    
      setData({
        first_name: userInfo.user.first_name || "",
        last_name: userInfo.user.last_name || "",
        cover_image: userInfo.cover_image || null,
        profile_picture: userInfo.profile_picture || null,
      });
  }, []);
 */
  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    setCover(file);
    setTempCover(URL.createObjectURL(file));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setTempImage(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      ...data,
      profile_picture: image,
      cover_image: cover,
    };
  };

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 py-2 px-4">
        <EditMovieProfileHeader header="Monetization" />
        <div className="flex flex-col items-center w-full mt-[70px]">
          <div className="lg:w-[60%] w-[80%]">
            <img src={workers} alt="" className="w-full h-full" />
          </div>
          <h4 className="font-semibold text-[20px] text-neutral-800 mt-12"> Coming Soon!</h4>

          <div>
            <p className="text-[18px] text-neutral-800 mt-4">
              Be rest assured we have your interest in mind
            </p>
          </div>
        </div>
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-4 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default TvMonetization;
