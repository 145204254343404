import { useContext, useState } from "react";
import Description from "components/newCommerce/typography/txtDescription";
import SellHeader from "components/newCommerce/shared/sellHeader";
import { ModalContext } from "Context/ModalContext";
import Planspromote from "./contents/plans";
import Previewpromote from "./contents/previewpromote";
import Proceedpay from "./contents/proceedpay";
const Promoteitem = () => {
  const [steps, setSteps] = useState(1);
  const { closeModal, isOpen, promotedDetails } = useContext(ModalContext);
  // state-tohandle-current-clicked-plan
  const [currplan, setcurrplan] = useState({});

  function SetTitle(count) {
    let title;
    if (count === 1) {
      title = "Promote sale";
    } else if (count === 2) {
      title = "Preview";
    } else {
      title = "Preview";
    }
    return title;
  }
  const reverseSteps = () => {
    if (steps === 2) {
      setSteps(steps - 1);
    } else if (steps === 3) {
      setSteps(steps - 1);
    } else {
      //close-modal
      isOpen && closeModal();
    }
  };

  const slideNext = () => {
    setSteps(steps + 1);
    steps === 3 && setSteps(1);
    console.log(steps);
  };
  return (
    <div className="w-full">
      <SellHeader
        title={SetTitle(steps)}
        hide={false}
        titleCol={"#4f0da3"}
        clickBack={() => reverseSteps()}
        dots={"promote"}
      />
      <div className="w-full">
        {steps === 1 ? (
          <Planspromote
            click={slideNext}
            planDetail={(parsed) => setcurrplan(parsed)}
          />
        ) : steps === 2 ? (
          <Previewpromote
            click={slideNext}
            plannedchose={currplan}
            itemPromote={promotedDetails}
          />
        ) : steps === 3 ? (
          <Proceedpay plannedchose={currplan} itemPromote={promotedDetails} />
        ) : null}
      </div>
      <Description
        title={titleText}
        sx={{
          textAlign: "left !important",
          visibility: "hidden !important",
        }}
      />
    </div>
  );
};

export default Promoteitem;

let titleText =
  "Exceptional product! Impressive quality, versatile features, and user-friendly design. A must-have for anyone seeking top-notch performance. Highly recommend this outstanding product for Home and State Use purposes really well accomodative.";
