import { useGetAllAccounts } from "Hooks/profile/useGetAllAccounts";
import { useGetPublicProfile } from "Hooks/profile/useGetPublicProfile";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { TbDotsVertical } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { noUser } from "utils/noUser";
import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";
import InnerChat from "./InnerChat";
import { CiFaceSmile } from "react-icons/ci";
import { BsSend } from "react-icons/bs";
import { useProfile } from "Hooks/profile/useProfile";
import useSendDirectMessage from "Hooks/chat/useSendDirectMessage";
import { useGetConversation } from "Hooks/chat/useGetConversation";
import PromoteBroadcastModal from "./PromoteBroadcastModal";

const NewBroadcast = () => {
  const { id } = useParams();
  const [text, setText] = useState("");
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  const { allAccounts, isAllAccountsLoading, allAccountsError } =
    useGetAllAccounts();

  const { profileData, isProfileDataLoading, profileDataError } = useProfile();

  const { conversation, isConversationLoading } = useGetConversation();

  const { sendDirectMessage, sendDirectMessageStatus } = useSendDirectMessage();

  const user = allAccounts?.data?.data?.find(
    // eslint-disable-next-line eqeqeq
    (item) => item?.user?.id == id
  );

  const myID = profileData?.data?.data?.user?.id;
  //const myInfo2 = profileData?.data?.data?.id

  const messages = [];

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ bottom: 0 });
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault()
    const formData = { recipient_id: id, text, message_type: "public_messages" };
    sendDirectMessage(formData);
    setText(""); 
    //setIsPromoteModalOpen(true)
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const isActive = false
  const navigate = useNavigate();
  return (
    <div className="bg-neutral-100 h-[calc(100vh - 100px)] px-3 py-3">
      {isPromoteModalOpen && (
        <PromoteBroadcastModal
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
        />
      )}
      <div
        className=" flex items-center gap-3 p-2 shadow-sm bg-white h-[60px]"
        ref={pageRef}
      >
        <FaArrowLeft
          className="text-[16px] ml-2 cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <div className=" flex items-center justify-between mt-2 w-full">
          <div>
            <h3 className="text-[14px]">New broadcast</h3>
          </div>
          <div className="">
            <TbDotsVertical className="text-[16px]" />
          </div>
        </div>
      </div>

      {messages?.length ? (
        <InnerChat userID={myID} ID={id} data={conversation?.data} />
      ) : (
        <div
          style={{ border: "1px solid #d1d5db" }}
          className="p-4 bg-[#f3ebf0] rounded flex flex-col mx-auto mt-[80px] mb-[80px] text-center items-center w-[280px] h-[240px] shadow-sm"
        >
          <div>
            <img src="../images/nom.png" className="w-[140px]" alt="chathead" />
          </div>
          <div className="mt-2">
            <h3 className="text-center lg:text-[13px] text-[12px] font-semibold">
              Send a message to everyone on 2geda
            </h3>
            <h3 className="text-center lg:text-[12px] text-[12px] font-normal">
              Reach all app users directly with a broadcast message. Start
              typing now
            </h3>
          </div>
        </div>
      )}
      <div className=" row-span-1  w-[100%] bottom-0 border shadow-sm z-20">
        <form className="w-full border-none bg-neutral-100 rounded flex items-center py-2 px-4" onSubmit={handleSendMessage}>
          <CiFaceSmile className="text-black text-[24px]" />
          <input
            type="text"
            className="text-[14px] border-none outline-none"
            placeholder="Send a broadcast message"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button className="bg-rose-500 h-12 w-12 p-2 rounded-full flex justify-center items-center" type="submit">
            <BsSend
              className="text-[18px] text-white"
              
            />
          </button>
        </form>
      </div>
      <div className="hidden mt-2 lg:block">
        <img src={ads} alt="Ads" className="w-full h-full object-contain" />
      </div>
    </div>
  );
};

export default NewBroadcast;

/* 
<div
        style={{ border: "1px solid #d1d5db" }}
        className="p-4 bg-[#f3ebf0] rounded flex flex-col mx-auto mt-[100px] text-center items-center w-[280px] h-[240px] shadow-sm"
      >
        <div>
          <img src="../images/nom.png" className="w-[140px]" alt="chathead" />
        </div>
        <div className="mt-2">
          <h3 className="text-center lg:text-[13px] text-[12px] font-semibold">
            Send a message to everyone on 2geda
          </h3>
          <h3 className="text-center lg:text-[12px] text-[12px] font-normal">
            Reach all app users directly with a broadcast message. Start typing
            now
          </h3>
        </div>
        </div>
*/
