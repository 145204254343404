import { MdOutlineAnalytics } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiWarningCircleBold } from "react-icons/pi";
import ReportSummary from "./ReportSummary";
import useGetEventDetails from "Hooks/useGetEventDetails";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const trimTitle = (str) => {
  if (str?.length > 28) {
    return str?.slice(0, 28) + "...";
  }
  return str;
};

const demoImage =
  "https://img.freepik.com/free-photo/restaurant-hall-with-tables-decorated-with-tall-vases-with-roses_1304-4823.jpg";

const UpActiveTick = ({ data, isPast, rmv }) => {
  const { handleEditTicketOpen, handleSummaryOpen, isSummaryOpen } =
    useGetEventDetails();

  const navigate = useNavigate();

  const getCurrentDate = (arg) => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = moment(arg).format("DD MMM, YYYY");

    const day = moment(arg).weekday();

    return `${weekDays[day]} ${currentDate}`;
  };

  const openSummary = (item) => {
    handleSummaryOpen(item);
  };

  const handleEdit = (payload) => {
    handleEditTicketOpen(payload);
    navigate("/ticket/sell-ticket/create-event-one");
  };

  const handleTicketReport = (payload) => {
    navigate(`/ticket/sell-ticket/ticket-report/${payload.id}`);
    //handle setting ticketreport id to payload
  };

  return (
    <>
      {isSummaryOpen && (
        <div className="modal-full-container">
          <ReportSummary />
        </div>
      )}
      {data?.map((item, index) => (
        <div key={index} className="tick-full-card-container">
          <div className="tic-image-detail-bx">
            <div className="img-tick-cont cg-bd">
              <img src={item?.event_image ? item.event_image : demoImage} alt="" />
            </div>
            <div className="event-details">
              <div className="event-name nm-evn">{item?.title}</div>
              <div className="event-date dt-rd">
                {getCurrentDate(item?.start_date)}
              </div>
              <div className="location-box">
                {/* <MdLocationOn /> */}
                <div className="loca-txt cl loc-rd">{item?.venue_name} </div>
              </div>
              <div className="event-intr">{trimTitle(item?.description)}</div>
            </div>
          </div>
          <div className="claim-visit-page-btn">
            {!isPast && (
              <button
                className={`claim-visit-btn flex cent visi-pae rd ${rmv}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  fontSize: "12px",
                }}
                onClick={() => handleEdit(item)}
              >
                <FiEdit />
                <span>Edit</span>
              </button>
            )}
            <button
              className="claim-visit-btn flex cent fir-bt rd"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                fontSize: "12px",
              }}
              onClick={() => openSummary(item)}
            >
              <PiWarningCircleBold />
              <span>Summary</span>
            </button>
            <button
              className="claim-visit-btn flex cent fir-bt rd"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                fontSize: "12px",
              }}
              onClick={() => handleTicketReport(item)}
            >
              <MdOutlineAnalytics />
              <span>Report</span>
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default UpActiveTick;
