import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postCommentFn } from "api/services/tv";

const usePostComment = () => {
  const queryClient = useQueryClient();

  const {
    status: postCommentStatus,
    mutate: postComment,
    error: postCommentError,
  } = useMutation({
    mutationFn: (formData) => postCommentFn(formData),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["comments"],
      });
    },
  });

  return {
    postComment,
    postCommentStatus,
    postCommentError,
  };
};

export default usePostComment;
